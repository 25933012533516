import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguages } from '../AppClasses';
import ger from '@flutaro/package/src/assets/i18n/ger.json';
import en from '@flutaro/package/src/assets/i18n/en.json';
import pol from '@flutaro/package/src/assets/i18n/pol.json';
import rus from '@flutaro/package/src/assets/i18n/rus.json';
import rom from '@flutaro/package/src/assets/i18n/rom.json';

@Injectable({
	providedIn: 'root',
})
export class AppInternationalizationService {
	constructor(public translate: TranslateService) {
		this.onInit();
	}

	onInit() {
		this.translate.setTranslation('ger', ger);
		this.translate.setTranslation('en', en);
		this.translate.setTranslation('pol', pol);
		this.translate.setTranslation('rus', rus);
		this.translate.setTranslation('rom', rom);
		this.setDefaultLanguage();
	}

	setDefaultLanguage() {
		this.changeAppLanguage(AppLanguages.GERMAN);
	}

	/**
	 *
	 * @param language: valid values are: 'en', 'ger', 'pol', 'rus', 'ron'
	 */
	changeAppLanguage(language: AppLanguages) {
		if (!language) {
			console.error(`ERROR in changeAppLanguage: No AppLanguage provided.`);
			return;
		}
		console.log(`Setting App language to: ${language}`);
		this.translate.setDefaultLang(language);
	}
}
