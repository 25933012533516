<ion-item [color]="'color64666EProfile'" lines="none" [class.ion-hide]="pictureOnlyMode && !photo">
    <ion-grid style="padding-left:0px">
        <ion-row>
            <ion-col class="ion-float-left" [class.ion-hide]="pictureOnlyMode" size="auto" lines="none"
                     style="padding-left:0px">
                <ion-icon name="camera-outline"></ion-icon>
                <ion-label>{{title ? title : 'Bild'}}</ion-label>
            </ion-col>
        </ion-row>
        <ion-row>
          <ion-col [class.ion-float-right]="!pictureOnlyMode">
              <ion-img (click)="openPhotoPicker()"
                       [class.groupPicture]="pictureType === 'GROUP-IMAGE'"
                       [class.ion-justify-content-center]="pictureOnlyMode"
                       [class.pictureOnlyMode]="pictureOnlyMode"
                       [class.profile-pic]="!pictureOnlyMode && pictureType !== 'GROUP-IMAGE'"
                       [src]="photo" #imageSrc>
              </ion-img>
          </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>

<input type="file" class="ion-hide" #profilePicture accept="image/x-png,image/jpeg" (change)="uploadPicture()">
