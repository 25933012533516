import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppAuthService } from '../../auth/app-auth.service';
import { to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

@Component({
	selector: 'app-signup-page',
	templateUrl: './signup-page.component.html',
	styleUrls: ['./signup-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class SignupPageComponent {
	signupError: string;
	form: FormGroup;
	validationMessages = {
		email: [
			{ type: 'required', message: 'Email is required.' },
			{ type: 'email', message: 'It should be valid email address' },
		],
		password: [
			{ type: 'required', message: 'Password is required.' },
			{
				type: 'minlength',
				message: 'Password length should be minimum 6 digits.',
			},
		],
		displayName: [{ type: 'required', message: 'Display Name is required.' }],
	};

	constructor(fb: FormBuilder, private auth: AppAuthService) {
		this.form = fb.group({
			email: ['', Validators.compose([Validators.required, Validators.email])],
			password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
			displayName: ['', Validators.compose([Validators.required])],
		});
	}

	async signup() {
		const data = this.form.value;
		if (!data.email || !data.displayName) {
			return;
		}

		const credentials = {
			email: data.email,
			password: data.password,
		};
		const [authError, user] = await to(this.auth.signUp(credentials));
		if (authError) {
			console.error(authError);
			this.signupError = authError.message;
			return;
		}
		await user.user.updateProfile({ displayName: data.displayName });
		await user.user.sendEmailVerification();
		console.log('signup finished');
	}
}
