import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PostProvider } from '../post/post.provider';
import { UserProfileProvider } from '../../user/user.profile.provider';
import { TimelineService } from '../timeline/timeline.service';
import { ActivitiesService } from '../activities/activities.service';
import { ProfilePublicService } from '../profile/profile.public.service';
import { AvatarService } from '../avatar/avatar.service';
import { Timeline_Segment } from './FeedClasses';
import { AppDeviceService } from '../../app-device.service';
import { UserProfilePublic } from '@flutaro/package/lib/model/UserProfile';

@Component({
	selector: 'app-feed',
	templateUrl: './feed.page.html',
	styleUrls: ['./feed.page.scss'],
})
export class FeedPage implements OnInit {
	isFlutaroCommonOnDefault: boolean = true;
	selectedTimeLineSegment: string = Timeline_Segment.COMMON_TIMELINE;
	searchQuery: string = '';
	isSearch: boolean = false;
	userSearchList: UserProfilePublic[] = [];

	constructor(
		public feedProvider: PostProvider,
		public userProvider: UserProfileProvider,
		public timelineProvider: TimelineService,
		public activitiesProvider: ActivitiesService,
		private profileProvider: ProfilePublicService,
		private changeDetection: ChangeDetectorRef,
		public avatarProvider: AvatarService,
		public deviceService: AppDeviceService,
	) {}

	ngOnInit() {
		if (!this.deviceService.$isOnline.getValue()) {
			console.log(`Device Offline! Aborting Timeline Data Reqquest`);
			return;
		}
		this.loadData();
	}

	refreshFeed(event) {
		if (!this.deviceService.$isOnline.getValue()) return;
		this.timelineProvider.isLoading.subscribe((isLoading) => {
			if (!isLoading) event.target.complete();
		});
		this.loadData();
	}

	loadData() {
		this.timelineProvider.getTimeline(this.userProvider.$userProfile.getValue().uid, this.isFlutaroCommonOnDefault);
	}

	activateSearch() {
		this.isSearch = true;
	}

	timeLineSegmentChanged(segment: CustomEvent) {
		if (segment.detail.value === Timeline_Segment.TIMELINE) {
			this.timelineProvider.getTimeline(this.userProvider.$userProfile.getValue().uid, false);
			//this.selectedTimeLineSegment = Timeline_Segment.TIMELINE;
		} else {
			this.timelineProvider.getTimeline(this.userProvider.$userProfile.getValue().uid, true);
			//this.selectedTimeLineSegment = Timeline_Segment.COMMON_TIMELINE;
		}
	}

	async searchUsers(keyword) {
		this.userSearchList = await this.profileProvider.searchUsers(keyword);
		this.changeDetection.detectChanges();
	}

	userFromSearchClicked(profile: UserProfilePublic) {
		this.avatarProvider.avatarClicked(this.userProvider.$userProfile.getValue(), profile.uid, profile);
		this.cancelSearch();
	}

	clearSearch() {
		this.userSearchList = [];
		this.changeDetection.detectChanges();
	}

	cancelSearch() {
		this.searchQuery = '';
		this.isSearch = false;
		this.userSearchList = [];
		this.changeDetection.detectChanges();
	}
}
