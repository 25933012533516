import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SignupPageComponent } from './signup-page.component';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
	{
		path: 'as-page-signup',
		component: SignupPageComponent,
	},
];

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, IonicModule, RouterModule.forChild(routes), TranslateModule],
	declarations: [SignupPageComponent],
	exports: [SignupPageComponent],
})
export class SignupPageModule {}
