import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EmojiPickerModule } from '../../components/emoji-picker/emoji-picker.module';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { AppChatPageComponent } from './app-chat-page.component';
import { ContactPageModule } from '../contact/contact.module';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from '../../share.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		EmojiPickerModule,
		PipesModule,
		ContactPageModule,
		TranslateModule,
		ShareModule,
	],
	declarations: [AppChatPageComponent, LoadingSpinnerComponent],
	exports: [AppChatPageComponent, LoadingSpinnerComponent],
})
export class ChatPageModule {}
