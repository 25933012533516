import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebPagePreview } from '../../../model/WebPagePreview';
import { environment } from '../../../../environments/environment';
import { Browser } from '@capacitor/browser';

@Component({
	selector: 'app-webpage-preview',
	templateUrl: './web-page-preview.component.html',
	styleUrls: ['./web-page-preview.component.scss'],
})
export class WebPagePreviewComponent implements OnChanges {
	@Input() webPagePreview: WebPagePreview;
	@Output() webPagePreviewChange: EventEmitter<WebPagePreview> = new EventEmitter(true);
	@Input() showWebPagePreviewEdit: boolean;
	@Output() showWebPagePreviewEditChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	previewPageUrl: string = 'https://';
	urlError: boolean;

	constructor(private http: HttpClient) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['showWebPagePreviewEdit']) {
			if (this.showWebPagePreviewEdit && this.webPagePreview) {
				this.previewPageUrl = this.webPagePreview.url;
			}
		}
	}

	handleDetectedURL() {
		if (!this.previewPageUrl) {
			this.webPagePreviewChange.emit(null);
			this.showWebPagePreviewEditChange.emit(false);
			return;
		} else if (!this.validURL()) {
			this.urlError = true;
			return;
		}
		this.urlError = false;

		this.http.post(environment.websitePreviewURL, { url: this.previewPageUrl }).subscribe((res) => {
			this.webPagePreviewChange.emit(<WebPagePreview>res);
			this.showWebPagePreviewEditChange.emit(false);
		});
	}

	async openWebPage() {
		if (!this.webPagePreview.url) return;
		await Browser.open({ url: this.webPagePreview.url });
	}

	private validURL() {
		const pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i',
		); // fragment locator
		return (
			!!pattern.test(this.previewPageUrl) &&
			(this.previewPageUrl.startsWith('https://') || this.previewPageUrl.startsWith('http://'))
		);
	}
}
