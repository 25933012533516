import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterRatings',
})
export class FilterLocationRatingsDataPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items) return [];
		if (!searchText) return items;
		searchText = searchText.toLowerCase();
		return items.filter((rating) => {
			return rating.gLocation.description.toLowerCase().includes(searchText);
		});
	}
}
