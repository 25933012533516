<ion-content class="loginScreen">
  <div class="logDiv">
    <img class="logLogo" src="assets/imgs/Flutaro_Logo_white.svg" />
  </div>
  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <ion-list inset>
      <ion-item class="formItem">
        <ion-input color="flutaroMain" formControlName="email" placeholder="Email" type="email"></ion-input>
      </ion-item>
      <div class="validation-errors">
        <ng-container *ngFor="let validation of validationMessages.email">
          <div
            class="error-message"
            *ngIf="loginForm.get('email').hasError(validation.type) && (loginForm.get('email').dirty || loginForm.get('email').touched)"
          >
            {{ validation.message }}
          </div>
        </ng-container>
      </div>

      <ion-item class="formItem">
        <ion-input type="password" placeholder="Passwort" formControlName="password"></ion-input>
      </ion-item>
      <div class="validation-errors">
        <ng-container *ngFor="let validation of validationMessages.password">
          <div
            class="error-message"
            *ngIf="loginForm.get('password').hasError(validation.type) && (loginForm.get('password').dirty || loginForm.get('password').touched)"
          >
            {{ validation.message }}
          </div>
        </ng-container>
      </div>
    </ion-list>

    <div class="ion-padding-horizontal">
      <div class="form-error">{{loginError}}</div>

      <button
        ion-button
        icon-end
        full
        color="flutaroMain"
        type="submit"
        class="disable-hover button button-md button-default button-default-md button-full button-full-md button-md-flutaroMain"
      >
        {{"LOGIN" | translate}}
        <ion-icon name="log-in"></ion-icon>
      </button>
      <div class="login-footer">
        <p class="loginText">
          <a (click)="forgotPW()" translate>FORGOT_PASSWORD_SEND_MAIL</a>
        </p>

        <p *ngIf="pwWasResettedSuccessfully" translate>PASSWORD_RESET_SUCCESS</p>
      </div>
    </div>
  </form>
</ion-content>

<ion-footer class="toolbar-footer">
  <ion-toolbar>
    <p class="registerText" (click)="signup()" type="button">
      {{"NO_USER_REGISTER_REQUEST" | translate}}
      <ion-icon name="person-add"></ion-icon>
    </p>
  </ion-toolbar>
</ion-footer>
