<ion-toolbar>
	<ion-buttons slot="start">
		<ion-back-button defaultHref="contact"></ion-back-button>
	</ion-buttons>
	<ion-title class="ion-text-center">{{ channel.id === userProfile.uid ? 'Dispo-Chat' : channel.name }}</ion-title>
</ion-toolbar>

<ion-content #content (ionScroll)="setOnTop()" [scrollEvents]="true" id="chatContent">
	<loading-spinner *ngIf="fbDataPaginationService.loading | async"></loading-spinner>
	<div class="message-wrap" id="message_wrap">
		<div
			*ngFor="let msg of messages; let i = index"
			[class.left]="msg.senderId !== userProfile.uid"
			[class.right]="msg.senderId === userProfile.uid"
			class="message"
			id="{{ 'messDiv' + i }}"
		>
			<img *ngIf="msg.senderId === userProfile.uid" class="user-img" src="assets/user.jpg" />
			<img *ngIf="msg.senderId !== userProfile.uid" class="user-img" src="assets/imgs/Flutaro_Logo_only.svg" />
			<div class="msg-detail">
				<div class="msg-info">
					<p>
						<span *ngIf="msg.senderId !== userProfile.uid">{{ msg.senderName }}</span>
						<span> {{ msg.sendTime | relativeTime }}</span>
					</p>
				</div>
				<div *ngIf="msg.firstReceiverName && msg.isLastUserMessage" class="msg-info">
					<p>{{ 'CHAT_PAGE_READFIRST' | translate }} {{ msg.firstReceiverName }}</p>
				</div>
				<div *ngIf="msg.messageType === JobRequestType">
					<p class="chatRequestMessageText" translate>
						<i class="material-icons chatRequestMessageTextIcon">help_outline</i>
						CHAT_PAGE_QUESTION_TO_JOB
					</p>
					<p class="chatRequestMessageJobIdentifier">
						<i class="material-icons chatRequestMessageJobIcon">description</i>{{ msg.messageJobIdentifier }}
					</p>
				</div>
				<div class="msg-content">
					<span class="triangle"></span>
					<p class="line-breaker" [innerHTML]="msg.message | appLinkify"></p>
				</div>
			</div>
		</div>
	</div>

	<ion-button
		(click)="scrollToBottomOnClick()"
		*ngIf="
			chatService.newMessagesInOpenChannelCounter > 0 && chatService.onTop && channel.id === chatService.updateChannelId
		"
		class="mainToolbarButton"
	>
		<i class="material-icons mainToolbarIcon">message</i>
		<div class="jobsNewButtonMenuRow" fxLayout="row">
			<span
				*ngIf="chatService.newMessagesInOpenChannelCounter > 0"
				[class.showBadgeAnimation]="chatService.newMessagesInOpenChannelCounter"
				class="notifications-badge"
				>{{ chatService.newMessagesInOpenChannelCounter }}</span
			>
		</div>
	</ion-button>
</ion-content>

<ion-footer [style.height]="showEmojiPicker ? '255px' : '55px'" class="toolbar-footer" no-border>
	<ion-grid class="input-wrap">
		<ion-row *ngIf="type === JobRequestType" class="ion-align-items-center chatReqeustRow">
			<i class="material-icons chatRequestMessageTextIcon">help_outline</i>
			{{ 'CHAT_PAGE_QUESTION_TO_JOB' | translate }}
			<p class="chatRequestMessageJobIdentifier">
				<i class="material-icons chatRequestMessageJobIcon">description</i>{{ job.job.identifier }}
			</p>
		</ion-row>
		<ion-row>
			<ion-col col-2>
				<ion-button (click)="switchEmojiPicker()" class="chatFooterButton" icon-only item-left>
					<ion-icon name="happy-outline"></ion-icon>
				</ion-button>
			</ion-col>
			<ion-col col-8>
				<ion-textarea
					#chat_input
					(ionFocus)="onFocus()"
					(keyup.enter)="sendMsg()"
					[(ngModel)]="editorMsg"
					[placeholder]="'ENTER_MESSAGE' | translate"
					class="chatTextarea"
				>
				</ion-textarea>
			</ion-col>
			<ion-col col-2>
				<ion-button
					(click)="sendMsg()"
					[class.chatFooterSendButtonActive]="editorMsg"
					[disabled]="(deviceProvider.$isOnline | async) === false"
					class="chatFooterButton"
					icon-only
					item-right
				>
					<ion-icon name="send-sharp"></ion-icon>
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
	<emoji-picker *ngIf="showEmojiPicker" [(ngModel)]="editorMsg"></emoji-picker>
</ion-footer>
