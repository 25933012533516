import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	Auth,
	User,
	UserCredential,
	authState,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
} from '@angular/fire/auth';

@Injectable({ providedIn: 'root' })
export class AppAuthService {
	$user: Observable<User> = new Observable<User>(null);
	$userIsEnterpriseUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	$userIsSocialAppOnlyUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(@Optional() private auth: Auth) {
		if (auth) {
			console.log(`AppAuthService, auth available in constructor - subscribing to user authState`);
			this.$user = authState(this.auth);
		}
	}

	getCurrentUser(): User {
		return this.auth.currentUser;
	}

	async getIdToken(forceRefresh?: boolean): Promise<string> {
		return this.getCurrentUser().getIdToken(forceRefresh);
	}

	async sendEmailVerification() {
		const user = this.getCurrentUser();
		if (!user || user.emailVerified) {
			return true;
		}
		return await sendEmailVerification(user);
	}

	setUserAuthToEnterprise() {
		console.log(`ENTERPRISE App-User`);
		this.$userIsEnterpriseUser.next(true);
		this.$userIsSocialAppOnlyUser.next(false);
	}

	setUserAuthToSocialAppUserOnly() {
		console.log(`SOCIAL-APP-ONLY User`);
		this.$userIsEnterpriseUser.next(false);
		this.$userIsSocialAppOnlyUser.next(true);
	}

	async signInWithEmail(credentials) {
		return signInWithEmailAndPassword(this.auth, credentials.email, credentials.password);
	}

	async signUp(credentials): Promise<UserCredential> {
		return await createUserWithEmailAndPassword(this.auth, credentials.email, credentials.password);
	}

	async signOutAndRedirectToHome() {
		await this.signOut();
		console.log(`signOutAndRedirectToHome, user is signed out`);
	}

	async resetPassword(email: string): Promise<any> {
		return await sendPasswordResetEmail(this.auth, email);
	}

	private async signOut(): Promise<void> {
		this.$userIsEnterpriseUser.next(false);
		return await signOut(this.auth);
	}
}
