import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EmojiPickerComponent } from './emoji-picker.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
	declarations: [EmojiPickerComponent],
	imports: [CommonModule, IonicModule, RouterModule.forChild([{ path: '', component: EmojiPickerComponent }])],
	exports: [EmojiPickerComponent],
})
export class EmojiPickerModule {}
