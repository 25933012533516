import { JobFilterType } from './JobClasses';
import { HomeShiftType } from '../pages/home/HomeClasses';
import { addDays, addWeeks, endOfDay, endOfISOWeek, startOfDay, startOfISOWeek } from 'date-fns';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { filterJobsStartingInTimeRange } from '@flutaro/package/lib/functions/FlutaroJobFunctions';

export function filterJobsForReferenceDate(jobs: JobWrapper[], referenceDay: Date): JobWrapper[] {
	return filterJobsStartingInTimeRange(jobs, startOfDay(referenceDay), endOfDay(referenceDay));
}

export function filterForReferenceWeek(jobs: JobWrapper[], referenceWeek: Date) {
	return filterJobsStartingInTimeRange(jobs, startOfISOWeek(referenceWeek), endOfISOWeek(referenceWeek));
}

export function filterForTodaysJobs(jobs: JobWrapper[]) {
	const referenceDay = new Date();
	return filterJobsForReferenceDate(jobs, referenceDay);
}

export function filterForTomorrowJobs(jobs: JobWrapper[]) {
	const referenceDay = addDays(new Date(), 1);
	return filterJobsForReferenceDate(jobs, referenceDay);
}

export function filterForCurrentWeekJobs(jobs: JobWrapper[]) {
	return filterForReferenceWeek(jobs, new Date());
}

export function filterForNextWeekJobs(jobs: JobWrapper[]) {
	return filterForReferenceWeek(jobs, addWeeks(new Date(), 1));
}

export function filterJobsForFilterType(jobs: JobWrapper[], filterType) {
	if (!filterType) {
		return jobs;
	}
	return jobs.filter((job) => {
		if (filterType === JobFilterType.new) {
			return job.job.status === JobStatus.RECEIVED;
		}
		if (filterType === JobFilterType.planned) {
			return job.job.status === JobStatus.ACCEPTED;
		}
	});
}

export function filterJobsForDivisionType(jobs: JobWrapper[], divisionType) {
	switch (divisionType) {
		case HomeShiftType.today:
			return filterForTodaysJobs(jobs);
		case HomeShiftType.tomorrow:
			return filterForTomorrowJobs(jobs);
		case HomeShiftType.currentWeek:
			return filterForCurrentWeekJobs(jobs);
		case HomeShiftType.nextWeek:
			return filterForNextWeekJobs(jobs);
		default:
			return filterForTodaysJobs(jobs);
	}
}
