<div class="webPagePreviewContent" *ngIf="webPagePreview" [class.ion-hide]="!webPagePreview">
    <ion-item class="pagePreviewTitle" [color]="'colorTransparent'" lines="none">
        <ion-row>
            <ion-col *ngIf="webPagePreview.favicon">
                <ion-img [src]="webPagePreview.favicon"></ion-img>
            </ion-col>
            <ion-col size="10">
                <h4>{{webPagePreview.title}}</h4>
            </ion-col>
        </ion-row>
    </ion-item>

    <ion-item class="pagePreviewImage" [color]="'colorPagePreview'" lines="none">
        <ion-img *ngIf="webPagePreview.image"
                 [src]="webPagePreview.image"
                 (click)="openWebPage()">
        </ion-img>
    </ion-item>

    <ion-item class="pagePreviewDescription" [color]="'colorTransparent'" lines="none">
        <ion-col>
            <ion-row>
                <p>{{webPagePreview.description}}</p>
            </ion-row>

            <ion-row>
                <a (click)="openWebPage()">{{webPagePreview.url}}</a>
            </ion-row>
        </ion-col>
    </ion-item>
</div>

<ion-item *ngIf="showWebPagePreviewEdit" color="colorFeedTextarea" lines="none">
    <ion-col>
        <ion-row>
            <ion-input [(ngModel)]="previewPageUrl" placeholder="{{'ENTER_WEBSITE_URL' | translate}}'"
                       type="url"
                       clearInput=true
                       autofocus=true>
            </ion-input>
        </ion-row>
        <ion-row>
            <ion-button (click)="handleDetectedURL()" color="medium" translate>ADD_LINK</ion-button>
        </ion-row>
        <ion-row *ngIf="urlError">
            <p translate>INVALID_URL</p>
        </ion-row>
    </ion-col>
</ion-item>
