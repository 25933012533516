import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TruckersVehicleInformation } from '@flutaro/package/lib/model/UserProfile';

@Component({
	selector: 'app-user-vehicle',
	templateUrl: './user-vehicle.component.html',
	styleUrls: ['./user-vehicle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserVehicleComponent implements OnInit {
	@Input() vehicleInformation: TruckersVehicleInformation;
	@Input() readonly: boolean;
	@Input() userUid: string;
	@Output() vehicleInformationChange: EventEmitter<TruckersVehicleInformation> =
		new EventEmitter<TruckersVehicleInformation>();

	constructor() {}

	ngOnInit() {}

	setNewVehicleDate(newDate: string) {
		this.vehicleInformation.vehicleUserSinceDate = newDate;
		this.vehicleInformationChange.emit(this.vehicleInformation);
		console.log(`Setting newDate ${newDate} for vehicle Information`);
	}
}
