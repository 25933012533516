import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { JobsFilterService } from '../jobs/jobs-filter.service';
import { HomeShiftType, JobDivisionCategory } from './HomeClasses';
import { addDays, addWeeks } from 'date-fns';

@Component({
	selector: 'home-shift',
	templateUrl: 'home.shift.component.html',
	styleUrls: ['./home.page.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HomeShiftComponent implements OnChanges {
	@Input() shiftDivision: JobDivisionCategory;
	@Input() shiftType: string; //HomeShiftType
	shiftTypes = HomeShiftType;
	shiftDate: Date = new Date();

	constructor(public jobsFilter: JobsFilterService) {}

	ngOnChanges(changes) {
		if (!this.shiftType) return;
		this.initShiftDate();
	}

	private initShiftDate() {
		switch (this.shiftType) {
			case HomeShiftType.tomorrow:
				this.shiftDate = addDays(new Date(), 1);
				break;
			case HomeShiftType.currentWeek:
				this.shiftDate = new Date();
				break;
			case HomeShiftType.nextWeek:
				this.shiftDate = addWeeks(new Date(), 1);
				break;
			default:
				this.shiftDate = new Date();
				break;
		}
	}
}
