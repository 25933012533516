import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PostBasics, PostChange, PostComment, PostTypes } from '../post/post';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { CommentService } from './comment.service';

@Component({
	selector: 'post-comment',
	templateUrl: './post-comment.component.html',
	styleUrls: ['./post-comment.component.scss'],
})
export class PostCommentComponent {
	@Input() commentDepth: number;
	@Input() user: FbStoreUserProfile;
	@Input() comment: PostComment;
	@Input() isNew: boolean;
	@Input() post: PostBasics | PostComment;
	@Input() parentPost: PostBasics;
	@Output() likeChange: EventEmitter<PostBasics> = new EventEmitter<PostBasics>();
	@Output() commentChange: EventEmitter<PostChange> = new EventEmitter<PostChange>();
	@Output() level3CommentChange: EventEmitter<PostChange> = new EventEmitter<PostChange>();

	constructor(private commentService: CommentService) {}

	async addNewComment(lvl3ActionComment?: PostComment) {
		if (lvl3ActionComment) {
			this.level3CommentChange.emit({ before: null, after: lvl3ActionComment });
			return;
		}
		if (this.commentDepth === 3) {
			console.log(`Adjusting level3 comment to level2-Comment ${this.parentPost.id}`);
			// We allow a maximum comment depth of 2. All comments for comments for a comment get pushed to the original comment
			this.comment.content = `@${this.comment.authorName}: ` + this.comment.content;
			this.comment.postId = this.parentPost.id;
		}
		if (
			this.post.type === PostTypes.GROUP_POST ||
			this.post['postType'] === PostTypes.GROUP_POST ||
			this.post['postType'] === PostTypes.GROUP_COMMENT
		) {
			this.comment.type = PostTypes.GROUP_COMMENT;
			this.comment.postType = this.post.type === PostTypes.GROUP_POST ? PostTypes.GROUP_POST : PostTypes.GROUP_COMMENT;
			this.comment.groupId = this.post.groupId;
		}
		const addedComment = await this.commentService.addComment(this.comment, this.post);
		console.log('Comment added in PostCommentComponent');
		this.commentChange.emit({ before: null, after: addedComment });
		this.comment = new PostComment(
			this.post.id,
			this.user.uid,
			this.user.displayName,
			this.user.photoURL,
			this.post.type,
		);
	}

	deleteComment(deletedComment: PostComment) {
		this.commentChange.emit({ before: deletedComment, after: null });
	}
}
