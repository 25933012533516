import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { AppAuthService } from './app-auth.service';

@Injectable({
	providedIn: 'root',
})
export class AppAuthInterceptorService implements HttpInterceptor {
	constructor(private auth: AppAuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handleAccess(request, next));
	}

	private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		const token = await this.auth.getIdToken();
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json; charset=utf-8').set('Authorization', 'Bearer ' + token);
		request = request.clone({ headers: headers });
		return await lastValueFrom(next.handle(request));
	}
}
