import { JobWrapper, JobDestination } from '@flutaro/package/lib/model/Job';
import {
	getJobsFirstPickupDate,
	getJobsLastDeliveryDate,
	getLastDestination,
	getFirstPickUp,
	getDestinationsMinDate,
} from '@flutaro/package/lib/functions/DestinationFunctions';
import { Pipe, PipeTransform } from '@angular/core';
import {
	sortJobsByFirstPickupDateAsc,
	filterJobsForStatus,
	filterJobsForStati,
} from '@flutaro/package/lib/functions/FlutaroJobFunctions';
import { isJobValid, isDestinationValid } from '@flutaro/package/lib/functions/JobValidationFunctions';

@Pipe({
	name: 'jobsStartDate',
})
export class GetJobsStartDate implements PipeTransform {
	transform(job: JobWrapper): Date {
		return getJobsFirstPickupDate(job);
	}
}

@Pipe({
	name: 'jobsEndDate',
})
export class GetJobsEndDate implements PipeTransform {
	transform(job: JobWrapper): Date {
		return getJobsLastDeliveryDate(job);
	}
}

@Pipe({
	name: 'sortJobsByStartDate',
})
export class SortJobsByStartDate implements PipeTransform {
	transform(jobs: JobWrapper[], ascending: boolean = true): JobWrapper[] {
		return ascending ? sortJobsByFirstPickupDateAsc(jobs) : jobs;
	}
}

@Pipe({
	name: 'jobsFirstPickUp',
})
export class GetJobsFirstPickUpPipe implements PipeTransform {
	transform(job: JobWrapper): JobDestination {
		return getFirstPickUp(job);
	}
}

@Pipe({
	name: 'jobsLastDelivery',
})
export class GetJobsLastDeliveryPipe implements PipeTransform {
	transform(job: JobWrapper): JobDestination {
		return getLastDestination(job);
	}
}

@Pipe({
	name: 'filterForStatus',
})
export class FilterJobsForStatusPipe implements PipeTransform {
	transform(jobs: JobWrapper[], status: number, status2?: number): JobWrapper[] {
		return status2 ? filterJobsForStati(jobs, [status, status2]) : filterJobsForStatus(jobs, status);
	}
}

@Pipe({
	name: 'filterForNotStatus',
})
export class FilterJobsForNotStatusPipe implements PipeTransform {
	transform(jobs: JobWrapper[], status: number, status2?: number): JobWrapper[] {
		return status2 ? filterJobsForStati(jobs, [status, status2], true) : filterJobsForStatus(jobs, status, true);
	}
}

@Pipe({
	name: 'printDestinationPosition',
})
export class PrintDestinationPositionPipe implements PipeTransform {
	transform(destination: JobDestination): string {
		let destinationLabel: string =
			!destination.position.distributionCenter && destination.name
				? destination.name + ': ' + destination.position.toString()
				: destination.position.toString();
		const availableChars = 58;
		if (destinationLabel.length > availableChars) {
			return destination.position.toString();
		} else {
			return destinationLabel;
		}
	}
}

@Pipe({
	name: 'isValidJob',
})
export class IsValidJobPipe implements PipeTransform {
	transform(job: JobWrapper, triggerCd?: any): boolean {
		return isJobValid(job);
	}
}

@Pipe({
	name: 'isValidDestination',
})
export class IsValidDestinationPipe implements PipeTransform {
	transform(destination: JobDestination, destinationIndex: number, destinations: JobDestination[]): boolean {
		return isDestinationValid(destination, destinationIndex, destinations);
	}
}

@Pipe({
	name: 'getDestinationsMinDate',
})
export class GetDestinationsMinDatePipe implements PipeTransform {
	transform(destination: JobDestination, destinationIndex: number, destinations: JobDestination[]): Date {
		return getDestinationsMinDate(destination, destinationIndex, destinations);
	}
}

@Pipe({
	name: 'metersInKm',
})
export class MetersInKmPipe implements PipeTransform {
	transform(value: number): string {
		return value ? (value / 1000).toFixed(2).toString().replace('.', ',') : '0';
	}
}
