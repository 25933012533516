import { Component, OnInit } from '@angular/core';
import { LocationService } from './location.service';
import { UserProfileProvider } from '../../user/user.profile.provider';
import { ActivitiesService } from '../activities/activities.service';
import { PostTypes } from '../post/post';
import { AppDeviceService } from '../../app-device.service';

@Component({
	selector: 'app-location',
	templateUrl: './location.page.html',
	styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit {
	searchText: string = '';

	constructor(
		public userProvider: UserProfileProvider,
		public activitiesProvider: ActivitiesService,
		public locationRatingsProvider: LocationService,
		public deviceService: AppDeviceService,
	) {}

	ngOnInit() {
		this.locationRatingsProvider.getLatestLocationRatings();
	}

	addLocationsItem() {
		this.locationRatingsProvider.openLocationRatingDialog(PostTypes.RATING);
	}
}
