import { Component } from '@angular/core';
import { ActivitiesService } from '../activities.service';
import { ActivityFeedEntry, ActivityTypes } from '../ActivitiesFeed';
import { UserProfileProvider } from '../../../user/user.profile.provider';
import { AvatarService } from '../../avatar/avatar.service';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { PostPage } from '../../post/post.page';
import { ModalController } from '@ionic/angular';
import { PostProvider } from '../../post/post.provider';
import { PostTypes } from '../../post/post';
import { LocationService } from '../../location/location.service';
import { GroupsService } from '../../groups/groups.service';

@Component({
	selector: 'app-activities-list',
	templateUrl: './activities-list.component.html',
	styleUrls: ['./activities-list.component.scss'],
})
export class ActivitiesListComponent {
	constructor(
		public activitiesProvider: ActivitiesService,
		public userProfileProvider: UserProfileProvider,
		private avatarService: AvatarService,
		private modalCtrl: ModalController,
		private postProvider: PostProvider,
		private locationRatingProvider: LocationService,
		private groupService: GroupsService,
	) {}

	async openActivity(activity: ActivityFeedEntry) {
		if (!activity.isRead) this.activitiesProvider.setActivityAsRead(activity);
		const user = this.userProfileProvider.$userProfile.getValue();
		switch (activity.actionType) {
			case ActivityTypes.FOLLOW: {
				await this.avatarService.avatarClicked(user, activity.authorId);
				break;
			}
			case ActivityTypes.INVITE: {
				if (activity.postType === PostTypes.GROUP_POST) await this.groupService.openGroupByID(activity.postId);
				break;
			}
			default: {
				await this.openPost(activity.postId, activity.postType, user, activity.authorId);
			}
		}
	}

	async openPost(postId: string, postType: string, user: FbStoreUserProfile, authorId: string) {
		const post =
			postType === PostTypes.POST
				? await this.postProvider.getPostById(postId)
				: await this.locationRatingProvider.getLocationRatingById(postId);
		if (!post) {
			console.log(`Error: Couldnt find ${postType} ${postId}`);
			return;
		}
		let modal = await this.modalCtrl.create({
			component: PostPage,
			componentProps: {
				post: post,
				user: user,
				readonlyUid: authorId,
				commentDepth: 1,
				isModal: true,
			},
		});
		modal.present();
	}
}
