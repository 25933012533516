<ion-content>
	<div
		(click)="jobFilter.showJobsPageWithFilterActive('new', null)"
		*ngIf="(jobProvider.$data | async | filterForStatus:4).length"
		[routerLink]="'/page-job'"
		class="dashboardWidgetMain info-box newJobs"
	>
		<ion-row class="ion-align-items-center">
			<ion-col col-2>
				<div class="dashboardIconNewJobsBackground"></div>
			</ion-col>
			<ion-col col-8>
				<div class="title" translate>NEW_JOBS</div>
			</ion-col>
			<ion-col col-2>
				<div class="count">{{(jobProvider.$data | async | filterForStatus:4).length}}</div>
			</ion-col>
		</ion-row>
	</div>

	<div
		*ngIf="((chatProvider.$channels | async) | filterKeyForValue:'newMessageForAppUser':true).length"
		[routerLink]="'/page-contact'"
		class="dashboardWidgetMain info-box newMessages"
	>
		<ion-row class="ion-align-items-center">
			<ion-col col-2>
				<div class="dashboardIconNewMessagesBackground"></div>
			</ion-col>
			<ion-col col-8>
				<div class="title" translate>NEW_MESSAGES</div>
			</ion-col>
			<ion-col col-2>
				<div class="count">
					{{(chatProvider.$channels | async | filterKeyForValue:'newMessageForAppUser':true).length}}
				</div>
			</ion-col>
		</ion-row>
	</div>

	<app-jobs-list
		[currentStartedJob]="jobProvider.currentStartedJob | async"
		[isDashboard]="true"
		[isOnline]="deviceProvider.$isOnline | async"
		[jobsList]="jobProvider.currentStartedJob | async | transformObjectIntoArray"
		[userProfile]="userProvider.$userProfile | async"
	>
	</app-jobs-list>

	<home-shift [shiftDivision]="jobProvider.jobDivision.today" [shiftType]="shiftTypes.today" class="odd"> </home-shift>

	<home-shift [shiftDivision]="jobProvider.jobDivision.tomorrow" [shiftType]="shiftTypes.tomorrow" class="even">
	</home-shift>

	<home-shift [shiftDivision]="jobProvider.jobDivision.currentWeek" [shiftType]="shiftTypes.currentWeek" class="odd">
	</home-shift>

	<home-shift [shiftDivision]="jobProvider.jobDivision.nextWeek" [shiftType]="shiftTypes.nextWeek" class="even">
	</home-shift>
</ion-content>
