import { Pipe, PipeTransform, Inject } from '@angular/core';
import { differenceInCalendarISOWeeks, format, isSameDay, differenceInCalendarDays } from 'date-fns';
import { AbstractTranslationService } from '../FlutaroLibClasses';
import { isValidDate } from '@flutaro/package/lib/functions/DataDateFunctions';

@Pipe({
	name: 'relativeTime',
})
export class FlutaroRelativeTime implements PipeTransform {
	constructor(@Inject('TranslateService') private translate: AbstractTranslationService) {}

	transform(date: Date, ...args: any[]): string {
		if (!isValidDate(date)) {
			return this.translate.instant('NO_DATE_AVAILABLE');
		}
		const today = new Date();
		const diffInDaysToToday = differenceInCalendarDays(date, today);
		const diffInWeeksToToday = differenceInCalendarISOWeeks(date, today);
		const weekDay = this.translate.instant(format(date, `eeee`).toUpperCase());

		if (isSameDay(date, today)) {
			const today = this.translate.instant('TODAY');
			return format(date, `'${today}' HH:mm`);
		} else if (diffInDaysToToday === -1) {
			const yesterday = this.translate.instant('YESTERDAY');
			return format(date, `'${yesterday}' HH:mm`);
		} else if (diffInDaysToToday === 1) {
			const tomorrow = this.translate.instant('TOMORROW');
			return format(date, `'${tomorrow}' HH:mm`);
		} else if (diffInWeeksToToday === 0) {
			return format(date, `'${weekDay}' HH:mm`);
		} else if (diffInWeeksToToday === -1) {
			const lastWeek = this.translate.instant('LAST_WEEK');
			return format(date, `'${lastWeek}' '${weekDay}' HH:mm`);
		} else if (diffInWeeksToToday === 1) {
			const NEXT_WEEK = this.translate.instant('NEXT_WEEK');
			return format(date, `'${NEXT_WEEK}' '${weekDay}' HH:mm`);
		} else {
			return format(date, 'dd.LL.yyyy HH:mm');
		}
	}
}

@Pipe({
	name: 'filterKeyForValue',
})
export class FilterArrayByKeyForValue implements PipeTransform {
	transform(objects: any[], key: string, value: any): any[] {
		return objects.filter((obj) => obj[key] === value);
	}
}

@Pipe({
	name: 'appFormatDate',
})
export class FlutaroParseDatePipe implements PipeTransform {
	constructor(@Inject('TranslateService') private translate: AbstractTranslationService) {}

	transform(
		date: Date,
		formatType:
			| 'daymonth'
			| 'date'
			| 'time'
			| 'datetime'
			| 'weekday-short'
			| 'weekday-long'
			| 'weekday-date-short'
			| 'weekday-date'
			| 'week-of-year',
	): string {
		const appDateFormat = 'dd.MM.yyyy';
		switch (formatType) {
			case 'date':
				return format(date, appDateFormat);
			case 'daymonth':
				return format(date, 'dd.MM.');
			case 'time':
				return format(date, 'HH:mm');
			case 'datetime':
				return format(date, 'dd.MM.yyyy HH:mm');
			case 'week-of-year':
				return format(date, 'II');
			case 'weekday-date':
				const weekDay = this.translate.instant(format(date, `eeee`));
				return `${weekDay}, ${format(date, appDateFormat)}`;
			case 'weekday-short':
				return this.translate.instant(format(date, `eeee`).toUpperCase()).slice(0, 2) + '.';
			case 'weekday-long':
				return this.translate.instant(format(date, `EEEE`));
			case 'weekday-date-short':
				const weekdayShort = this.translate.instant(format(date, `eeee`).toUpperCase()).slice(0, 2) + '.';
				const dateShort = format(date, 'dd.MM.');
				return `${weekdayShort} ${dateShort}`;
			default:
				return 'Unsupported format';
		}
	}
}
