<!-- Generated template for the EmojiPickerComponent component -->
<div class="emoji-picker">
  <div class="emoji-items">
    <ion-slides pager>

      <ion-slide *ngFor="let items of emojiArr">
        <div>
        <span class="emoji-item" (click)="setValue(item)" *ngFor="let item of items">
          {{item}}
        </span>
      </div>
      </ion-slide>

    </ion-slides>
  </div>
</div>