<div *ngIf="!jobsList.length" class="noJobAvailable">
	<p translate>{{ isDashboard ? 'NO_STARTED_JOB' : 'NO_EXISTING_JOB' }}</p>
</div>
<ion-item-sliding #slidingItem *ngFor="let job of jobsList | sortJobsByStartDate; let jobIndex = index">
	<ion-item
		[class.jobListAccept]="job.job.status === jobStatus.ACCEPTED"
		[class.jobListDeclined]="job.job.status === jobStatus.DECLINED"
		[class.jobListNew]="job.job.status === jobStatus.RECEIVED"
		[class.jobListStarted]="job.job.status === jobStatus.STARTED || job.job.status === jobStatus.DONE"
		class="jobListItems"
		id="jobIonItem"
	>
		<div
			[class.jobStatusAcceptDiv]="job.job.status === jobStatus.ACCEPTED"
			[class.jobStatusDeclinedDiv]="job.job.status === jobStatus.DECLINED"
			[class.jobStatusNewDiv]="job.job.status === jobStatus.RECEIVED"
			[class.jobStatusStartedDiv]="job.job.status === jobStatus.STARTED || job.job.status === jobStatus.DONE"
			class="jobStatusIconDiv"
		>
			<div
				[class.jobStatusAcceptIcon]="job.job.status === jobStatus.ACCEPTED"
				[class.jobStatusDeclineIcon]="job.job.status === jobStatus.DECLINED"
				[class.jobStatusNewIcon]="job.job.status === jobStatus.RECEIVED"
				[class.jobStatusStartedIcon]="job.job.status === jobStatus.STARTED || job.job.status === jobStatus.DONE"
			></div>
		</div>
		<span *ngIf="isDashboard" class="jobListJobIdentifier">
			{{ 'CURRENT_ACTIVE_JOB' | translate }}: {{ job.toString() }}
		</span>
		<span *ngIf="!isDashboard" class="jobListJobIdentifier">
			{{ job.toString() }}
		</span>
		<ion-grid class="jobListItem" ion-item no-lines>
			<button (click)="openJobModal(job)" class="jobListButtonItem itemsBackground" ion-item no-lines>
				<ion-row>
					<ion-col col-4>
						<h3 id="jobListTimeItemStart">
							{{ job | jobsStartDate | appFormatDate: 'time' }}
							<p class="plannedDate">{{ job | jobsStartDate | appFormatDate: 'daymonth' }}</p>
						</h3>
						<div class="jobListLine">
							<div *ngIf="job.destinations.length > 2" class="jobMoreDestinationIcon"></div>
							<span *ngIf="job.destinations.length > 2" class="jobMoreDestinationText">
								+ {{ job.destinations.length - 2 }}</span
							>
						</div>
						<h3 id="jobListTimeItemEnd">
							{{ job | jobsEndDate | appFormatDate: 'time' }}
							<p class="plannedDate">{{ job | jobsEndDate | appFormatDate: 'daymonth' }}</p>
						</h3>
					</ion-col>
					<ion-col col-8>
						<ion-row>
							<h2 class="addressElement">
								<ion-icon class="jobListStartIcon" name="pin"></ion-icon>
								{{
									(job | jobsFirstPickUp).position.city
										? (job | jobsFirstPickUp).position.city
										: (job | jobsFirstPickUp).position.toString()
								}}
							</h2>
						</ion-row>
						<ion-row [ngClass]="job.destinations && job.destinations.length > 2 ? 'cargoJobRow' : null">
							<ion-col col-12>
								<p class="jobListDistance">
									{{ job.totalDistance | distanceAsKm }} ~ {{ job.totalTimeInMinutes | minutesToDrivingTime }}
								</p>
							</ion-col>
						</ion-row>
						<ion-row>
							<h2 class="addressElement">
								<ion-icon class="jobListEndIcon" name="pin"></ion-icon>
								{{
									(job | jobsLastDelivery).position.city
										? (job | jobsLastDelivery).position.city
										: (job | jobsLastDelivery).position.toString()
								}}
							</h2>
						</ion-row>
					</ion-col>
				</ion-row>
			</button>
		</ion-grid>

		<ion-button
			(click)="startJob(job)"
			*ngIf="jobIndex === 0 && job.job.status === jobStatus.ACCEPTED"
			[disabled]="!isOnline || currentStartedJob"
			color="flutaroAccentButton"
			id="jobListStartButton"
			shape="round"
			translate
		>
			<ion-icon class="jobListButtonIcon jobListStartButtonIcon" name="play-outline"></ion-icon>
			START
		</ion-button>
		<ion-button
			(click)="endJob(job)"
			*ngIf="job.job.status === jobStatus.STARTED"
			[disabled]="!isOnline"
			color="flutaroGreenButton"
			id="jobListEndButton"
			shape="round"
			translate
		>
			<ion-icon class="jobListButtonIcon" name="checkmark"></ion-icon>
			DONE
		</ion-button>
	</ion-item>
	<ion-item-options *ngIf="isOnline">
		<button (click)="sendMessageRequestToDriverChannel(job, slidingItem)" color="light" icon-start ion-button>
			<ion-icon name="chatbubble-outline"></ion-icon>
			<span translate>MESSAGE</span>
		</button>
		<button
			(click)="declineOrder(job, slidingItem)"
			*ngIf="job.job.status === jobStatus.RECEIVED"
			color="declined"
			icon-start
			ion-button
		>
			<ion-icon class="customIconBlock" class="flutaro-block"></ion-icon>
			<span class="customIconBlockText" translate>DECLINE</span>
		</button>
		<button
			(click)="acceptOrder(job, slidingItem)"
			*ngIf="job.job.status === jobStatus.RECEIVED"
			color="accepted"
			icon-start
			ion-button
		>
			<ion-icon name="checkmark-circle-outline"></ion-icon>
			<span translate>ACCEPT</span>
		</button>
	</ion-item-options>
</ion-item-sliding>
