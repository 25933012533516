import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiesService } from './activities.service';
import { ActivitiesListComponent } from './activities-list/activities-list.component';
import { FeedPageModule } from '../feed/feed.module';
import { IonicModule } from '@ionic/angular';
import { ShareModule } from '../../share.module';
import { AvatarModule } from '../avatar/avatar.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [ActivitiesListComponent],
	imports: [CommonModule, IonicModule, ShareModule, FeedPageModule, AvatarModule, TranslateModule],
	providers: [ActivitiesService],
})
export class ActivitiesModule {}
