import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';

export function translateProviderState(state): string {
	switch (state) {
		case BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS:
			return 'AUTHORIZATION_STATUS_ALWAYS';
		case BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE:
			return 'AUTHORIZATION_STATUS_WHEN_IN_USE';
		case BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED:
			return 'AUTHORIZATION_STATUS_DENIED';
		case BackgroundGeolocation.AUTHORIZATION_STATUS_NOT_DETERMINED:
			return 'AUTHORIZATION_STATUS_NOT_DETERMINED';
	}
}
