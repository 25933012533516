import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutPage } from './about.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [AboutPage],
	imports: [CommonModule, IonicModule, TranslateModule],
})
export class AboutModule {}
