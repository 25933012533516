import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppAuthService } from '../auth/app-auth.service';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { UserProfileProvider } from '../user/user.profile.provider';
import { ProfilePublicService } from '../social/profile/profile.public.service';
import { TranslateService } from '@ngx-translate/core';
import { AppInternationalizationService } from '../services/app.internationalization.service';
import { flutaroCloneObject } from '../AppFunctions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AppLanguages } from '@flutaro/package/lib/model/AppClasses';
import { generateKeywordsForName } from '@flutaro/package/lib/functions/db/UserProfileFunctions';
import BackgroundGeolocation, { DeviceSettingsRequest } from '@transistorsoft/capacitor-background-geolocation';
import { NotificationsService } from '../services/notifications/notifications.service';
import { AppDeviceService } from '../app-device.service';
import { App } from '@capacitor/app';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-user-profile-edit-component',
	templateUrl: './user-profile-edit.component.html',
	styleUrls: ['./user-profile-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileEditComponent implements OnDestroy {
	userProfile: FbStoreUserProfile;
	password: string;
	newPassword: string;
	changePassword: boolean;
	$loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	deviceIsActivatedBatteryOptimization: boolean = false;
	deviceLocationServicesEnabled: boolean;

	constructor(
		private http: HttpClient,
		private afs: AngularFirestore,
		private router: Router,
		private alertController: AlertController,
		public auth: AppAuthService,
		private userProfileProvider: UserProfileProvider,
		private profile: ProfilePublicService,
		private translate: TranslateService,
		private languageService: AppInternationalizationService,
		private notifications: NotificationsService,
		public deviceProvider: AppDeviceService,
	) {
		this.userProfile = flutaroCloneObject(this.userProfileProvider.$userProfile.getValue());
		this.setupDeviceSettings();
	}

	async ngOnDestroy() {
		await App.removeAllListeners();
		console.log(`ngOnDestroy, removeAllListeners finished`);
	}

	async setupDeviceSettings() {
		if (!this.deviceProvider.isNativeApp) return;
		this.deviceIsActivatedBatteryOptimization =
			!(await BackgroundGeolocation.deviceSettings.isIgnoringBatteryOptimizations());
		this.deviceLocationServicesEnabled = (await BackgroundGeolocation.getProviderState()).enabled;
		console.log(`setupDeviceSettings called:
		deviceIsActivatedBatteryOptimization - ${this.deviceIsActivatedBatteryOptimization}`);
	}

	async openAndroidBatteryOptimizationsSettings() {
		BackgroundGeolocation.deviceSettings
			.showIgnoreBatteryOptimizations()
			.then((request: DeviceSettingsRequest) => {
				console.log(`- Screen seen? ${request.seen} ${request.lastSeenAt}`);
				console.log(`- Device: ${request.manufacturer} ${request.model} ${request.version}`);

				this.notifications
					.showInfoAlertWithOkAction(
						this.translate.instant('GPS_DEVICE_OPEN_BATTERY_OPTIMIZATION_SETTINGS_TITLE'),
						this.translate.instant('GPS_DEVICE_OPEN_BATTERY_OPTIMIZATION_SETTINGS_QUESTION'),
						true,
					)
					.then((confirmed) => {
						console.log(`openAndroidBatteryOptimizationsSettings, user confirmed to show settings: ${confirmed}`);
						if (confirmed!) return;
						// User clicked [Confirm] button.  Execute the redirect to settings screen:
						App.addListener('appStateChange', ({ isActive }) => {
							console.log('App state changed. Is active?', isActive);
							if (isActive) this.setupDeviceSettings();
						});
						BackgroundGeolocation.deviceSettings.show(request);
					});
			})
			.catch((error) => {
				// Depending on Manufacturer/Model/OS Version, a Device may not implement
				// a particular Settings screen.
				console.warn(error);
			});
	}

	storePasswordChange() {}

	changeAppLanguage(languageCode: AppLanguages) {
		console.log(`Changing App-Language to languageCode: ${languageCode}`);
		this.languageService.changeAppLanguage(<AppLanguages>languageCode);
	}

	async presentAlert(title: string, content: string) {
		const alert = await this.alertController.create({
			header: title,
			message: content,
			buttons: ['OK'],
		});

		await alert.present();
	}

	async updateDetails() {
		this.$loading.next(true);

		if (this.userProfile.displayName !== this.userProfileProvider.$userProfile.getValue().displayName) {
			this.userProfile.keywords = generateKeywordsForName(this.userProfile.displayName);
		}

		await this.userProfileProvider.editUserInFBDB(this.userProfile);
		await this.profile.updatePublicProfile(this.userProfile);

		this.password = '';
		this.newPassword = '';
		this.$loading.next(false);

		await this.presentAlert(
			this.translate.instant('PROFILE_UPDATE_SAVED'),
			this.translate.instant('PROFILE_UPDATED_SUCCESS_MESSAGE'),
		);
	}
}
