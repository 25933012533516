import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { FeedPage } from './feed.page';
import { ShareModule } from '../../share.module';
import { PostPageModule } from '../post/post.module';
import { ProfileModule } from '../profile/profile.module';
import { AvatarModule } from '../avatar/avatar.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		PostPageModule,
		ShareModule,
		ProfileModule,
		AvatarModule,
		TranslateModule,
		ComponentsModule,
	],
	declarations: [FeedPage],
	exports: [],
})
export class FeedPageModule {}
