import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import BackgroundGeolocation, {
	Config,
	LocationAuthorizationAlert,
	Notification,
	PermissionRationale,
} from '@transistorsoft/capacitor-background-geolocation';
import { FlutaroGPSPosition, JobPairing } from '@flutaro/package/lib/model/Positiondata';

export function createDefaultBackgroundTrackingConfiguration(
	isProduction: boolean,
	backgroundPermissionRationale: PermissionRationale,
	notification: Notification,
	locationAuthorizationAlert: LocationAuthorizationAlert,
): Config {
	let config: Config = {
		// Logging / Debug config
		debug: true, //  enable this hear sounds for background-geolocation life-cycle.
		logLevel: BackgroundGeolocation.LOG_LEVEL_DEBUG,
		// Geolocation Tracking config
		triggerActivities: 'in_vehicle, on_bicycle',
		desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH, // Desired accuracy in meters.
		elasticityMultiplier: 30,
		// Plugin Settings
		startOnBoot: true,
		enableTimestampMeta: true,
		disableLocationAuthorizationAlert: false,
		locationAuthorizationRequest: 'Always',
		// Android
		notification: notification,
		backgroundPermissionRationale: backgroundPermissionRationale,
		foregroundService: true,
		// iOs
		activityType: BackgroundGeolocation.ACTIVITY_TYPE_AUTOMOTIVE_NAVIGATION,
		locationAuthorizationAlert: locationAuthorizationAlert,
	};
	if (isProduction) {
		config.debug = false;
		config.logLevel = BackgroundGeolocation.LOG_LEVEL_OFF;
		config.triggerActivities = 'in_vehicle';
	}
	return config;
}

export function createBackgroundTrackingConfigurationForJob(
	position: FlutaroGPSPosition,
	userProfile: FbStoreUserProfile,
	isProduction: boolean,
	apiKey: string,
	backgroundPermissionRationale: PermissionRationale,
	notification: Notification,
	locationAuthorizationAlert: LocationAuthorizationAlert,
): Config {
	let defaultConfig: Config = createDefaultBackgroundTrackingConfiguration(
		isProduction,
		backgroundPermissionRationale,
		notification,
		locationAuthorizationAlert,
	);
	defaultConfig.extras = {
		apiKeyFbBGApp: apiKey,
		pairing: JobPairing.ON,
	};
	defaultConfig.params = {
		jobId: position.jobId,
		jobIdentifier: position.jobIdentifier,
		vehicleId: position.vehicleId,
		licensePlate: position.licensePlate,
		driverUID: userProfile.uid,
		company: userProfile.company,
		sentToCustomerAPI: false,
		jobsDeliveryPlannedDate: position.jobsDeliveryPlannedDate.toISOString(),
		jobsPickupPlannedDate: position.jobsPickupPlannedDate.toISOString(),
	};
	// Add more param details only if present, most set via API
	if (position.jobCustomerId) defaultConfig.params['jobCustomerId'] = position.jobCustomerId;
	if (position.customerId) defaultConfig.params['jobCustomerId'] = position.customerId;
	if (position.customerPickupLocationId)
		defaultConfig.params['customerPickupLocationId'] = position.customerPickupLocationId;
	if (position.customerDeliveryLocationId)
		defaultConfig.params['customerDeliveryLocationId'] = position.customerDeliveryLocationId;
	return defaultConfig;
}
