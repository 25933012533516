import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

export interface RetryParams {
	maxAttempts: number;
	scalingDuration: number;
	shouldRetry: (status: number) => boolean;
}

const defaultParams: RetryParams = {
	maxAttempts: 3,
	scalingDuration: 1000,
	shouldRetry: (status: number) => status >= 400 || status === 0
};

@Injectable()
export class AppHttpRetryInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			retryWhen((error) =>
				error.pipe(
					mergeMap(async (error, index) => {
						if (index < defaultParams.maxAttempts && defaultParams.shouldRetry(error.status)) {
							return of(error).pipe(delay(defaultParams.scalingDuration));
						}
						throw error;
					})
				)
			)
		);
	}
}
