import { Injectable } from '@angular/core';
import { FlutaroLike, PostBasics, PostTypes } from '../post/post';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivitiesService } from '../activities/activities.service';
import { UserProfileProvider } from '../../user/user.profile.provider';
import { groupsDataRef, locationRatingsDataRef, postsFirestorePath } from '../model/FirestoreModel';
import { ActivityFeedEntry, ActivityTypes } from '../activities/ActivitiesFeed';
import { AnalyticsService } from '../../services/Analytics/analytics.service';
import { isSocialObjectLiked } from './LikeFunctions';
import { LikesModalComponent } from './likes-modal/likes-modal.component';
import { ModalController } from '@ionic/angular';
import { GroupMember } from '../groups/FlutaroGroup';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import firebase from 'firebase/compat/app';
import { SOCIAL_EVENT } from '@flutaro/package/lib/model/AppAnalyticsEvents';
import { FirestoreCollection } from '@flutaro/package/lib/model/db/FirestoreClasses';
import { cloneClassIntoPlainObject } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
	providedIn: 'root',
})
export class LikeService {
	constructor(
		private fbStore: AngularFirestore,
		private activitiesFeedService: ActivitiesService,
		public userProvider: UserProfileProvider,
		private analytics: AnalyticsService,
		public modalController: ModalController,
	) {}

	updateLiked(post: PostBasics, isLiked: boolean, userLike: FlutaroLike) {
		this.analytics.logEvent(isLiked ? SOCIAL_EVENT.LIKE : SOCIAL_EVENT.UNLIKE);
		userLike = cloneClassIntoPlainObject(userLike);
		let objectPath;
		switch (post.type) {
			case PostTypes.POST:
				objectPath = postsFirestorePath;
				break;
			case PostTypes.COMMENT:
				objectPath = FirestoreCollection.COMMENTS;
				break;
			case PostTypes.RATING:
				objectPath = locationRatingsDataRef;
				break;
			case PostTypes.GROUP_POST:
				objectPath = `${groupsDataRef}/${post.groupId}/${postsFirestorePath}`;
				break;
			case PostTypes.GROUP_COMMENT:
				objectPath = `${groupsDataRef}/${post.groupId}/${FirestoreCollection.COMMENTS}`;
				break;
		}

		let updateMap = {
			lastModified: new Date(),
			likes: isLiked ? FieldValue.arrayUnion(userLike) : FieldValue.arrayRemove(userLike),
		};
		this.fbStore
			.doc(`${objectPath}/${post.id}`)
			.update(updateMap)
			.then((added) => {
				console.log('Updated Likes in Post!');
			});
		this.addLikeToTimelineFeed(post);
	}

	addLikeToTimelineFeed(feed: PostBasics) {
		const user = this.userProvider.$userProfile.getValue();
		if (!isSocialObjectLiked(feed, user.uid) || user.uid === feed.authorId) return;

		let likeTimelineEntry = new ActivityFeedEntry(
			ActivityTypes.LIKE,
			feed.authorId,
			user.uid,
			user.photoURL,
			user.displayName,
			feed.id,
			feed.content,
			feed.type,
		);
		this.activitiesFeedService.postActivityForUser(likeTimelineEntry);
	}

	async showLikes(likes: FlutaroLike[] | GroupMember[], user: FbStoreUserProfile, title: string) {
		const modal = await this.modalController.create({
			component: LikesModalComponent,
			componentProps: {
				likes: likes,
				userProfile: user,
				title: title,
			},
		});
		modal.present().then((currentModal) => {});
	}
}
