import { Component, ViewEncapsulation } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { AppChatChannel } from '../../model/chat.model';
import { Router } from '@angular/router';
import { UserProfileProvider } from '../../user/user.profile.provider';

@Component({
	selector: 'app-contact-page',
	templateUrl: './app-contact-page.component.html',
	styleUrls: ['./app-contact-page.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppContactPageComponent {
	constructor(
		public chatProvider: ChatService,
		public router: Router,
		public userProfileProvider: UserProfileProvider,
	) {}

	setChannel(channel: AppChatChannel) {
		if (channel.newMessageForAppUser) {
			channel.newMessageForAppUser = false;
			this.chatProvider.updateNewMessageForAppUserAttribute(channel.id, channel.newMessageForAppUser);
		}
		this.chatProvider.channel = channel;
		this.router.navigate(['/contact/page-chat']);
	}
}
