<ion-toolbar>
    <ion-buttons slot="start">
        <ion-button (click)="dismiss()" color="light">
            <ion-icon name="arrow-back"></ion-icon>
        </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" translate>{{title}}
    </ion-title>
</ion-toolbar>

<ion-content class="ion-padding">
    <app-list-icon-title (resultChange)="showUser($event)"
                         [elementsNameIdentifier]="'nickname'"
                         [isSearch]="true"
                         [searchResultsList]="likes"
                         [type]="'LIKES_VIEW'"
                         [userProfile]="userProfile">
    </app-list-icon-title>
</ion-content>
