import { Component, OnInit } from '@angular/core';
import { AppDeviceService } from '../../../app-device.service';
import { GroupsService } from '../groups.service';
import { UserProfileProvider } from '../../../user/user.profile.provider';
import { FlutaroGroup } from '../FlutaroGroup';

@Component({
	selector: 'app-groups-page',
	templateUrl: './groups-page.component.html',
	styleUrls: ['./groups-page.component.scss'],
})
export class GroupsPageComponent implements OnInit {
	searchText: string;
	groupSearchList: FlutaroGroup[];

	constructor(
		public deviceService: AppDeviceService,
		public groupsProvider: GroupsService,
		public userProvider: UserProfileProvider,
	) {}

	ngOnInit() {
		this.groupsProvider.getUsersGroups();
	}

	addGroup() {
		this.groupsProvider.openGroupsDialog();
	}

	async searchUsers(keyword) {
		this.groupSearchList = await this.groupsProvider.searchGroups(keyword);
		console.log(this.groupSearchList);
	}

	groupFromSearchClicked(group: FlutaroGroup) {
		console.log(`Group clicked.`);
		this.openGroup(group);
	}

	openGroup(group: FlutaroGroup) {
		this.groupsProvider.openGroupsDialog(group);
	}
}
