import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DestinationType } from '@flutaro/package/lib/model/Job';
import { Cargo } from '@flutaro/package/lib/model/CargoClasses';

@Component({
	selector: 'app-cargo',
	templateUrl: './cargo.component.html',
	styleUrls: ['./cargo.component.scss', '../job-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoComponent {
	@Input() cargos: Cargo[];
	@Input() destinationType: DestinationType;
	DESTINATION_TYPE = DestinationType;
}
