<ion-toolbar>
	<ion-buttons slot="start">
		<ion-back-button (click)="dismiss()" defaultHref="page-job"></ion-back-button>
	</ion-buttons>
	<ion-title class="ion-text-center">
		{{ jobWrapper.toString() }}
	</ion-title>
</ion-toolbar>

<ion-content>
	<ion-list class="jobModalList">
		<ion-row class="jobModalHeaderRow">
			<ion-col col-12>
				<p class="jobModalHeaderText" translate>
					<i class="material-icons jobModalMainIcon">assignment</i>JOB_PAGE_GENERALLY
				</p>
			</ion-col>
		</ion-row>

		<app-job-status-item [job]="jobWrapper"></app-job-status-item>

		<ion-item class="modalListItem">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<ion-icon name="finger-print-outline" color="white"></ion-icon>
				<ion-label>Shipment-ID</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note class="ion-text-end width100">{{ jobWrapper.attributes?.shipmentId }}</ion-note>
			</div>
		</ion-item>
		<ion-item class="modalListItem">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<ion-icon name="time" color="white"></ion-icon>
				<ion-label translate>DRIVING_TIME</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note>{{ jobWrapper.totalTimeInMinutes | minutesToDrivingTime }}</ion-note>
			</div>
		</ion-item>
		<ion-item class="modalListItem">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">settings_ethernet</i>
				<ion-label translate>DISTANCE</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note>{{ jobWrapper.totalDistance | distanceAsKm }}</ion-note>
			</div>
		</ion-item>
		<ion-item class="modalListItem">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">business</i>
				<ion-label translate>CLIENT</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note class="ion-text-end width100">{{ jobWrapper.job.clientsName }}</ion-note>
			</div>
		</ion-item>
		<ion-item class="modalListItem">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">assignment_turned_in</i>
				<ion-label translate>REQUIREMENTS</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note class="ion-text-end width100">{{ jobWrapper.job.requirements }}</ion-note>
			</div>
		</ion-item>
	</ion-list>

	<ion-list
		*ngFor="let dest of jobWrapper.destinations"
		[ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListPickup' : 'jobModalListDelivery'"
	>
		<ion-row class="jobModalHeaderRowPickUpOrDelivery">
			<ion-col col-12>
				<p class="jobModalHeaderText">
					<span [ngClass]="dest.locationType === 'PICKUP' ? 'pickupImage' : 'deliveryImage'" class="material-icons">
					</span>
					{{ (dest.locationType === 'PICKUP' ? 'PICKUP' : 'DELIVERY') | translate }}
				</p>
			</ion-col>
		</ion-row>

		<ion-item [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<ion-icon name="link-outline" color="white"></ion-icon>
				<ion-label translate>REFERENCES</ion-label>
			</div>

			<div fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="20px" class="width100 marginBottom20">
				<ion-note class="ion-text-end width100" *ngIf="dest.ingoingReferences?.length">
					{{ ('JOB_CARGO_REFERENCES_INGOING' | translate) + ': ' + dest.ingoingReferences }}
				</ion-note>
				<ion-note class="ion-text-end width100" *ngIf="dest.outgoingReferences?.length"
					>{{ ('JOB_CARGO_REFERENCES_OUTGOING' | translate) + ': ' + dest.outgoingReferences }}
				</ion-note>
			</div>
		</ion-item>

		<ion-item
			[class.marginTop20]="dest.name || dest.description"
			[ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'"
		>
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<ion-icon name="home-outline" color="white"></ion-icon>
				<ion-label translate>ADDRESS</ion-label>
			</div>

			<div fxLayout="column" fxLayoutAlign="center end" class="width100" fxLayoutGap="20px">
				<ion-note class="ion-text-end width100">{{ dest.position.toString() }}</ion-note>
				<ion-note class="ion-text-end width100 marginBottom20" *ngIf="dest.name || dest.description"
					>{{ dest.name ? dest.name + ' - ' : '' }} {{ dest.description ? dest.description : '' }}
				</ion-note>
			</div>
		</ion-item>
		<ion-item [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">history_toggle_off</i>
				<ion-label translate>JOB_PAGE_PLANNEDDATE</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note>{{ dest.plannedDate | appFormatDate: 'datetime' }}</ion-note>
			</div>
		</ion-item>
		<ion-item [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">access_time</i>
				<ion-label translate>EARLIESTDATE</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note>{{ dest.earliestDate | appFormatDate: 'datetime' }}</ion-note>
			</div>
		</ion-item>
		<ion-item [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">alarm</i>
				<ion-label translate>LATESTDATE</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note>{{ dest.latestDate | appFormatDate: 'datetime' }}</ion-note>
			</div>
		</ion-item>
		<ion-item
			*ngIf="dest.position.regulatingTimeInMinutes"
			[ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'"
		>
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">timer</i>
				<ion-label translate>REGULATINGTIMEINMINUTES</ion-label>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note>{{ dest.position.regulatingTimeInMinutes }} min</ion-note>
			</div>
		</ion-item>
		<ion-item [ngClass]="dest.locationType === 'PICKUP' ? 'jobModalListItemPickup' : 'jobModalListItemDelivery'">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<ion-icon name="chatbox-ellipses-outline" color="white"></ion-icon>
				<ion-label translate>NOTES</ion-label>
			</div>

			<div fxLayout="column" fxLayoutAlign="center end" class="width100">
				<ion-note class="ion-text-end width100">{{ dest.position.additionalInfo }}</ion-note>
			</div>
		</ion-item>
		<app-cargo [cargos]="dest.cargo"></app-cargo>
	</ion-list>
</ion-content>
