import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlutaroPost, PostBasics, PostComment, PostTypes } from '../../post/post';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { PostProvider } from '../../post/post.provider';
import { CommentService } from '../../comment/comment.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from '../../location/location.service';
import { FlutaroLocationRating } from '../../location/Location';
import { FlutaroGroup } from '../../groups/FlutaroGroup';

@Component({
	selector: 'app-feed-entry-header',
	templateUrl: './feed-entry-header.component.html',
	styleUrls: ['./feed-entry-header.component.scss'],
})
export class FeedEntryHeaderComponent implements OnInit {
	@Input() post: PostBasics;
	@Input() group: FlutaroGroup;
	@Input() isComment: boolean;
	@Input() user: FbStoreUserProfile;
	@Input() isReadOnly: boolean;
	@Output() deleteComment: EventEmitter<PostComment> = new EventEmitter<PostComment>();
	@Output() groupPostEditChange: EventEmitter<PostBasics> = new EventEmitter<PostBasics>();
	@Output() groupPostDeleteChange: EventEmitter<PostBasics> = new EventEmitter<PostBasics>();
	actionSheet: any;

	constructor(
		public feedProvider: PostProvider,
		private ratings: LocationService,
		public actionSheetController: ActionSheetController,
		public alertController: AlertController,
		private commentService: CommentService,
		private translate: TranslateService,
	) {}

	ngOnInit() {}

	presentActionSheet() {
		this.actionSheet = this.actionSheetController
			.create({
				header: 'Post Aktionen',
				buttons: [
					{
						text: this.translate.instant('EDIT'),
						icon: 'create-outline',
						handler: () => {
							console.log('Edit clicked');
							if (this.post.type === 'GROUP') {
								this.groupPostEditChange.emit(this.post);
								return;
							}
							this.feedProvider.addEditFeedItem(this.isComment ? PostTypes.COMMENT : this.post.type, this.post);
						},
					},
					{
						text: this.translate.instant('DELETE'),
						role: 'destructive',
						icon: 'trash-outline',
						handler: () => {
							console.log('Delete clicked');
							if (this.post.type === 'GROUP') {
								this.groupPostDeleteChange.emit(this.post);
								return;
							}
							this.presentDeleteAlertConfirm();
						},
					},
					{
						text: this.translate.instant('CANCEL'),
						icon: 'close-circle-outline',
						role: 'cancel',
						handler: () => {
							console.log('Cancel clicked');
						},
					},
				],
			})
			.then((actionsheet) => {
				actionsheet.present();
			});
	}

	async presentDeleteAlertConfirm() {
		let headerText;
		let messageText;
		switch (this.post.type) {
			case PostTypes.POST:
				headerText = 'DELETE_POST';
				messageText = 'DELETE_POST_CONFIRM';
				break;
			case PostTypes.RATING:
				headerText = 'DELETE_LOCATION_RATING';
				messageText = 'DELETE_LOCATION_RATING_CONFIRM';
				break;
			case PostTypes.COMMENT:
				headerText = 'DELETE_COMMENT';
				messageText = 'DELETE_COMMENT_CONFIRM';
				break;
		}

		const alert = await this.alertController.create({
			header: `${this.translate.instant(headerText)}?`,
			message: `${this.translate.instant(messageText)}`,
			buttons: [
				{
					text: this.translate.instant('CANCEL'),
					role: 'cancel',
					cssClass: 'secondary',
				},
				{
					text: this.translate.instant('DELETE'),
					handler: () => {
						console.log('Confirm Okay');
						if (this.isComment) {
							const comment = <PostComment>this.post;
							this.commentService.deleteComment(comment);
							this.deleteComment.emit(comment);
						} else if (this.post.type === PostTypes.POST) {
							this.feedProvider.deleteFeedInFirestore(<FlutaroPost>this.post);
						} else if (this.post.type === PostTypes.RATING) {
							this.ratings.deleteLocationRating(<FlutaroLocationRating>this.post);
						} else if (this.post.type === PostTypes.GROUP_POST) {
							this.groupPostDeleteChange.emit(this.post);
							//this.ratings.deleteLocationRating(<FlutaroLocationRating>this.post);
						}
					},
				},
			],
		});

		await alert.present();
	}
}
