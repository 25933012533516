import { FlutaroLike, SocialElementBasics } from '../post/post';

export class FlutaroGroup extends SocialElementBasics {
	members: GroupMember[] = [];
	memberIds: string[] = [];
	title: string;
	photoURL: string;
	isPublic: boolean = true;
	isPublicSearchable: boolean = true;
	keywords: string[] = [];
}

export class GroupMember extends FlutaroLike {}
