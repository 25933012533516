import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { JobStatusComponent } from './job-status/job-status.component';
import { PictureComponent } from './picture/picture.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FormsModule } from '@angular/forms';
import { AvatarModule } from '../social/avatar/avatar.module';
import { AppListIconTitleComponent } from './user-list/app-list-icon-title.component';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
	declarations: [
		JobStatusComponent,
		PictureComponent,
		StarRatingComponent,
		AutocompleteComponent,
		AppListIconTitleComponent,
	],
	imports: [CommonModule, FormsModule, IonicModule, MatExpansionModule, TranslateModule, AvatarModule, FlexModule],
	exports: [
		JobStatusComponent,
		PictureComponent,
		MatExpansionModule,
		StarRatingComponent,
		AutocompleteComponent,
		AppListIconTitleComponent,
	],
	providers: [],
})
export class ComponentsModule {}
