<div fxlayoutalign="row" *ngIf="!readOnly">
	<flutaro-autocomplete
		(activateSearchQuery)="activateSearchQuery($event)"
		(resultChange)="setGLocation($event)"
		[placeholder]="'CHOOSE_LOCATION' | translate"
		[searchIdentifierAttribute]="'description'"
		[searchResultsList]="locationsSearchList"
		[type]="'GOOGLE_ADDRESS_SEARCH'"
	>
	</flutaro-autocomplete>

	<ion-button
		[color]="'flutaroMain'"
		fill="solid"
		expand="block"
		color="success"
		(click)="loadCurrentPosition()"
		*ngIf="!isRating"
		ion-align-items-center
		translate
	>
		CURRENT_POSITION
		<ion-icon slot="start" name="locate"></ion-icon>
	</ion-button>
</div>

<div class="map-wrapper" *ngIf="!readOnly || (location && location.lat)">
	<div class="noLocationAvailable">
		<p translate>NO_LOCATION_AVAILABLE</p>
	</div>
	<div #map id="map"></div>
</div>
