import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';

@Component({
	selector: 'app-job-status-item',
	templateUrl: './job-status.component.html',
	styleUrls: ['./job-status.component.scss', '../../pages/jobs/job-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobStatusComponent {
	status = JobStatus;
	@Input() job: JobWrapper;
}
