import { Injectable } from '@angular/core';
import BackgroundGeolocation, {
	AuthorizationStatus,
	Config,
	LocationAuthorizationAlert,
	Notification,
	PermissionRationale,
} from '@transistorsoft/capacitor-background-geolocation';
import { TranslateService } from '@ngx-translate/core';
import { AppDeviceService } from '../../app-device.service';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { BackgroundGeolocationFirebase } from '@transistorsoft/capacitor-background-geolocation-firebase';
import { createDefaultBackgroundTrackingConfiguration } from './BackgroundGeoConfiguration';
import { environment } from '../../../environments/environment';
import { to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

@Injectable({
	providedIn: 'root',
})
export class GpsConfigurationService {
	constructor(private translate: TranslateService, private deviceService: AppDeviceService) {}

	/**
	 * Initial Setup for Background-Tracking, only one time at start
	 * @param userProfile
	 */
	async initAndConfigureBackgroundTracking(userProfile: FbStoreUserProfile) {
		console.log(
			`initAndConfigureBackgroundTracking, configuring BackgroundGeolocationFirebase & BackgroundGeolocation`,
		);
		await BackgroundGeolocationFirebase.configure({
			locationsCollection: `/companyData/${userProfile.company}/drivers/${userProfile.uid}/positions`,
			geofencesCollection: `/companyData/${userProfile.company}/drivers/${userProfile.uid}/geofences`,
		});
		const currentLocationPermissionState = await this.getCurrentPluginAuthState();
		const hasWhenInUseAuthorization =
			currentLocationPermissionState === BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE;
		const hasLocationPermissionsBellowWhenInUse =
			currentLocationPermissionState < BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE;
		const isIOsInitCase = this.deviceService.isIos && hasLocationPermissionsBellowWhenInUse;
		console.log(`initAndConfigureBackgroundTracking, config at start:
    hasLocationPermissionsBellowWhenInUse - ${hasLocationPermissionsBellowWhenInUse},
    hasWhenInUseAuthorization - ${hasWhenInUseAuthorization},
    isIOsInitCase - ${isIOsInitCase}`);
		await this.initBackgroundPlugin(isIOsInitCase);
		if (hasWhenInUseAuthorization) {
			console.log(
				`initAndConfigureBackgroundTracking, hasWhenInUseAuthorization on init - calling requestLocationPermission`,
			);
			await this.requestLocationPermission();
		}
		console.debug(`initAndConfigureBackgroundTracking, plugin init is now finished`);
		return true;
	}

	async requestLocationPermission() {
		console.log(`requestLocationPermission, called at ${new Date().toISOString()}`);
		const [permissionError, currentState] = await to(BackgroundGeolocation.requestPermission());
		if (permissionError) {
			console.log(`requestLocationPermission, error when requesting permissions. Error: ${permissionError}`);
		}
		console.log(`requestLocationPermission, new state: ${currentState} - received at ${new Date().toISOString()}`);
		return currentState;
	}

	async initBackgroundPlugin(isIosInit: boolean) {
		console.log(`initBackgroundPlugin, starting initial configuration on app start with isIosInit: ${isIosInit}`);
		const defaultConfig = createDefaultBackgroundTrackingConfiguration(
			environment.production,
			this.createBackgroundGPSPermissionRationale(),
			this.createBackgroundGPSNotificationText(),
			this.createBackgroundGPSLocationAuthorizationAlertForIOs(),
		);
		if (isIosInit) defaultConfig.locationAuthorizationRequest = 'WhenInUse';
		await this.initBackgroundPluginByConfig(defaultConfig);
		if (isIosInit) {
			defaultConfig.locationAuthorizationRequest = 'Always';
			await this.updateBackgroundPluginByConfig(defaultConfig);
		}
		return true;
	}

	async initBackgroundPluginByConfig(config: Config) {
		console.log(`initBackgroundPluginByConfig, init plugin on app start by calling ready`);
		await BackgroundGeolocation.ready(config);
		return true;
	}

	async updateBackgroundPluginByConfig(updateConfig: Config) {
		console.log(`updateBackgroundPluginByConfig, updating Background-Plugin with following updated parameters: `);
		console.log(updateConfig);
		return await BackgroundGeolocation.setConfig(updateConfig);
	}

	async getCurrentPluginAuthState(): Promise<AuthorizationStatus> {
		const currentStatus = (await BackgroundGeolocation.getProviderState()).status;
		console.log(`getCurrentPluginAuthState, currentStatus: ${currentStatus}`);
		return currentStatus;
	}

	async isAlwaysTrackingEnabled(): Promise<boolean> {
		const isAlwaysAuthorization =
			(await this.getCurrentPluginAuthState()) === BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS;
		console.log(`isAlwaysTrackingEnabled, currentStatus: ${isAlwaysAuthorization}`);
		return isAlwaysAuthorization;
	}

	public createBackgroundGPSPermissionRationale(): PermissionRationale {
		return {
			title: this.deviceService.isIos
				? this.translate.instant('GPS_BACKGROUND_AUTHORIZATION_REQUEST_HEADER_IOS')
				: this.translate.instant('GPS_BACKGROUND_AUTHORIZATION_REQUEST_HEADER_ANDROID'),
			message: this.deviceService.isIos
				? this.translate.instant('GPS_BACKGROUND_AUTHORIZATION_REQUEST_MESSAGE_IOS')
				: this.translate.instant('GPS_BACKGROUND_AUTHORIZATION_REQUEST_MESSAGE_ANDROID'),
			positiveAction: this.translate.instant('TO_SETTINGS'),
			negativeAction: this.translate.instant('CANCEL'),
		};
	}

	public createBackgroundGPSLocationAuthorizationAlertForIOs(): LocationAuthorizationAlert {
		const backgroundGPSPermissionRationale = this.createBackgroundGPSPermissionRationale();
		return {
			titleWhenNotEnabled: backgroundGPSPermissionRationale.title,
			titleWhenOff: backgroundGPSPermissionRationale.title,
			instructions: backgroundGPSPermissionRationale.message,
			cancelButton: backgroundGPSPermissionRationale.negativeAction,
			settingsButton: backgroundGPSPermissionRationale.positiveAction,
		};
	}

	public createBackgroundGPSNotificationText(): Notification {
		return {
			title: this.translate.instant('GPS_ACTIVATED_TITLE'),
			text: this.translate.instant('GPS_ACTIVATED_TEXT'),
		};
	}
}
