import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { prepareProfileBeforeFirebaseStorage } from '../../services/FirestoreFunctions';
import { BehaviorSubject } from 'rxjs';
import { UserProfilePublic } from '@flutaro/package/lib/model/UserProfile';
import { FirestoreCollection } from '@flutaro/package/lib/model/db/FirestoreClasses';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';

@Injectable({
	providedIn: 'root',
})
export class ProfilePublicService {
	$profile: BehaviorSubject<FbStoreUserProfile> = new BehaviorSubject<FbStoreUserProfile>(null);

	constructor(private db: AngularFirestore) {}

	getProfileById(authorUid: string): Promise<UserProfilePublic> {
		return this.db
			.collection<UserProfilePublic>(FirestoreCollection.PROFILES)
			.doc(authorUid)
			.get()
			.pipe(map((doc) => doc.data()))
			.toPromise();
	}

	updatePublicProfile(publicProfile: FbStoreUserProfile) {
		return this.db
			.collection(FirestoreCollection.PROFILES)
			.doc(publicProfile.uid)
			.update(prepareProfileBeforeFirebaseStorage(publicProfile))
			.then(() => {
				console.log('updatePublicProfile, update performed for User: ' + publicProfile.uid);
				this.$profile.next(publicProfile);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	searchUsers(keyword: string): Promise<UserProfilePublic[]> {
		return this.db
			.collection<UserProfilePublic>(FirestoreCollection.PROFILES, (ref) =>
				ref.where('keywords', 'array-contains', keyword.toLowerCase()).orderBy('nickname', 'asc').limit(20),
			)
			.get()
			.pipe(map((querySnapshot) => querySnapshot.docs.map((post) => post.data())))
			.toPromise();
	}
}
