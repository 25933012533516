import { NgModule } from '@angular/core';
import { FlutaroRelativeTime, FilterArrayByKeyForValue, FlutaroParseDatePipe } from './flutaro.date.pipes';
import { TranslateTenantIdIntoNamePipe } from './translation.pipes';
import {
	FilterForActivatedVehiclesPipe,
	GetVehiclesDriverNamePipe,
	IsVehicleActivatedPipe,
	IsVehicleMatchingJobRequirementsPipe,
} from './vehicle.pipes';
import {
	GetJobsStartDate,
	GetJobsEndDate,
	SortJobsByStartDate,
	GetJobsFirstPickUpPipe,
	GetJobsLastDeliveryPipe,
	FilterJobsForStatusPipe,
	FilterJobsForNotStatusPipe,
	PrintDestinationPositionPipe,
	IsValidJobPipe,
	GetDestinationsMinDatePipe,
	IsValidDestinationPipe,
	MetersInKmPipe,
} from './flutaro.job.pipes';
import {
	TransformObjectIntoArray,
	FilterElementsByPipe,
	FlutaroOrderByPipe,
	ObjectKeysValPipe,
} from './flutaro.data.pipes';
import { MinutesToDrivingTimePipe, DistanceAsKmDisplayedPipe } from './flutaro.format.pipes';
import { UserHasFullAphroditeAccessPipe, UserHasReadonlyAphroditeAccessPipe } from './flutaro.auth.pipes';
import { GetDriversNameByIdPipe, IsDriverActivatedPipe } from './driver.pipes';
import { AppLinkifyPipe } from './flutaro.chat.pipes';

@NgModule({
	declarations: [
		FlutaroRelativeTime,
		TranslateTenantIdIntoNamePipe,
		IsValidJobPipe,
		IsValidDestinationPipe,
		GetJobsStartDate,
		GetJobsEndDate,
		SortJobsByStartDate,
		GetJobsFirstPickUpPipe,
		GetJobsLastDeliveryPipe,
		GetVehiclesDriverNamePipe,
		GetDestinationsMinDatePipe,
		MetersInKmPipe,
		PrintDestinationPositionPipe,
		IsVehicleActivatedPipe,
		FilterForActivatedVehiclesPipe,
		IsVehicleMatchingJobRequirementsPipe,
		IsDriverActivatedPipe,
		GetDriversNameByIdPipe,
		TransformObjectIntoArray,
		FilterJobsForStatusPipe,
		FilterJobsForNotStatusPipe,
		MinutesToDrivingTimePipe,
		DistanceAsKmDisplayedPipe,
		FilterArrayByKeyForValue,
		UserHasFullAphroditeAccessPipe,
		UserHasReadonlyAphroditeAccessPipe,
		FilterElementsByPipe,
		FlutaroOrderByPipe,
		FlutaroParseDatePipe,
		ObjectKeysValPipe,
		AppLinkifyPipe,
	],
	imports: [],
	exports: [
		FlutaroRelativeTime,
		TranslateTenantIdIntoNamePipe,
		IsValidJobPipe,
		IsValidDestinationPipe,
		GetJobsStartDate,
		GetJobsEndDate,
		SortJobsByStartDate,
		GetJobsFirstPickUpPipe,
		GetJobsLastDeliveryPipe,
		GetVehiclesDriverNamePipe,
		GetDestinationsMinDatePipe,
		MetersInKmPipe,
		PrintDestinationPositionPipe,
		IsVehicleActivatedPipe,
		FilterForActivatedVehiclesPipe,
		IsVehicleMatchingJobRequirementsPipe,
		IsDriverActivatedPipe,
		GetDriversNameByIdPipe,
		TransformObjectIntoArray,
		FilterJobsForStatusPipe,
		FilterJobsForNotStatusPipe,
		MinutesToDrivingTimePipe,
		DistanceAsKmDisplayedPipe,
		FilterArrayByKeyForValue,
		UserHasFullAphroditeAccessPipe,
		UserHasReadonlyAphroditeAccessPipe,
		FilterElementsByPipe,
		FlutaroOrderByPipe,
		FlutaroParseDatePipe,
		ObjectKeysValPipe,
		AppLinkifyPipe,
	],
})
export class FlutaroPipesModule {}
