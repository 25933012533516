export const firebaseConfig = {
	apiKey: 'AIzaSyChfC5VWCaa-58Jq45Rw5RWf-dhCKh4hQ8',
	authDomain: 'routingappapi-testing.firebaseapp.com',
	databaseURL: 'https://routingappapi-testing.firebaseio.com',
	projectId: 'routingappapi-testing',
	storageBucket: 'routingappapi-testing.appspot.com',
	messagingSenderId: '803124367577',
	appId: '1:803124367577:web:f2c9f6f2ca57a67e506ec8',
	measurementId: 'G-V7N7TVSG57',
};

export const environment = {
	environment: 'testing',
	production: false,
	apiUrl: 'https://routingappapi-testing.ey.r.appspot.com',
	firebaseConfig: firebaseConfig,
	apiKeyFbBGApp: 'me73498Nerk93nBerk3)Dlemnhs§)34?mer',
	websitePreviewURL: 'https://europe-west3-routingappapi-testing.cloudfunctions.net/getURLPreview',
};
