<ion-searchbar (ionCancel)="cancelSearch()" (ionClear)="clearSearch()"
               (ionFocus)="activateSearch()"
               [(ngModel)]="searchQuery"
               (ngModelChange)="searchUsers()"
               [autocorrect]="'off'"
               [placeholder]="placeholder"
               [showCancelButton]="'focus'"
               class="flutaro-app-searchbar"
               color="colorSearchbar">
</ion-searchbar>

<app-list-icon-title (resultChange)="emitSearchSelection($event)"
                     [elementsNameIdentifier]="searchIdentifierAttribute"
                     [isSearch]="isSearch"
                     [searchResultsList]="searchResultsList"
                     [type]="type"
                     [userProfile]="userProfile">
</app-list-icon-title>

