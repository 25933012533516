import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppAuthService } from '../../auth/app-auth.service';
import { PushMessagesService } from '../../services/push/push-messages.service';
import { AppDeviceService } from '../../app-device.service';
import { Router } from '@angular/router';
import { to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

@Component({
	selector: 'app-login-page',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppLoginPageComponent implements OnInit {
	loginForm: FormGroup;
	loginError: string;
	pwWasResettedSuccessfully: boolean = false;

	validationMessages = {
		email: [
			{ type: 'required', message: 'Email is required.' },
			{ type: 'email', message: 'It should be valid email address' },
		],
		password: [
			{ type: 'required', message: 'Password is required.' },
			{
				type: 'minlength',
				message: 'Password length should be minimum of 6 digits.',
			},
		],
	};

	constructor(
		private auth: AppAuthService,
		public pushProvider: PushMessagesService,
		public deviceProvider: AppDeviceService,
		private fb: FormBuilder,
		public router: Router,
	) {}

	ngOnInit() {
		this.loginForm = this.fb.group({
			email: ['', Validators.compose([Validators.required, Validators.email])],
			password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
		});
		console.log(`Login Form Init.`);
		this.pwWasResettedSuccessfully = false;
	}

	async login() {
		let data = this.loginForm.value;
		console.log(`login, starting login for user ${data.email}`);
		if (!data.email || !data.password) {
			console.log(`login, no email or password - aborting`);
			return;
		}
		let credentials = {
			email: data.email,
			password: data.password,
		};
		const [authError, userCredential] = await to(this.auth.signInWithEmail(credentials));
		if (authError) {
			this.loginError = authError.message;
			console.log(`login, error: ${authError.message}`);
			return;
		}
		console.log(`login, user ${userCredential.user.email} successfully signed in`);
	}

	signup() {
		this.router.navigate(['./as-page-signup']);
	}

	forgotPW() {
		const email = this.loginForm.value.email;
		this.auth.resetPassword(email).then(() => {
			this.pwWasResettedSuccessfully = true;
		});
	}
}
