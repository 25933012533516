import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js'; // Included with Angular CLI
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { setPrototypeToStringFunctions } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import { deactivateConsoleLogging } from '@flutaro/package/lib/functions/AppApplicationFunctions';

if (environment.production) {
	enableProdMode();
	deactivateConsoleLogging();
}
setPrototypeToStringFunctions();

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
