export const ConnectionStatus = {
	Online: 'ONLINE',
	Offline: 'OFFLINE',
};

export enum AppLanguages {
	GERMAN = 'ger',
	ENGLISH = 'en',
	POLISH = 'pol',
	RUSSIAN = 'rus',
	ROMANIAN = 'rom',
}
