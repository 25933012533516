import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppAuthService } from './app-auth.service';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { isBefore, subMinutes } from 'date-fns';

@Component({
	selector: 'app-login-error-page',
	templateUrl: './auth-error.page.html',
	styleUrls: ['./auth-error.page.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLoginErrorComponent implements OnInit {
	appUserRole = FlutaroUserProfile;
	isEmailVerificationResentEnabled: boolean = false;
	isEmailVerificationResent: boolean = false;

	constructor(public auth: AppAuthService) {}

	ngOnInit() {
		const user = this.auth.getCurrentUser();
		if (!user) {
			return;
		}
		const creationDate = new Date(user.metadata.creationTime);
		// Dont show resend verification email if user account was just created
		this.isEmailVerificationResentEnabled = isBefore(creationDate, subMinutes(new Date(), 2)) && !user.emailVerified;
	}

	async resendEmailVerificationEmail() {
		this.isEmailVerificationResent = true;
		await this.auth.sendEmailVerification();
		console.debug('resendEmailVerificationEmail, Email verification successfully sent.');
	}
}
