import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { setDatesOnPostingCreate, setDatesOnPostingEdit } from '../feed/FeedFunctions';
import { ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { FlutaroPost, PostTypes } from './post';
import { AppAuthService } from '../../auth/app-auth.service';
import {
	preparePostBeforeFirebaseStorage,
	setDocRefAfterDocAddedInFirestore,
	transformFeedForApp,
} from '../../services/FirestoreFunctions';
import { PostAddModal } from './post/add/post.add.modal';
import { flutaroCloneObject } from '../../AppFunctions';
import { postsFirestorePath } from '../model/FirestoreModel';
import { CommentService } from '../comment/comment.service';
import { ActivitiesService } from '../activities/activities.service';
import { ActivityFeedEntry, ActivityTypes } from '../activities/ActivitiesFeed';
import { UserProfileProvider } from '../../user/user.profile.provider';
import { TimelineService } from '../timeline/timeline.service';
import { AnalyticsService } from '../../services/Analytics/analytics.service';
import { LocationService } from '../location/location.service';
import { setPostsUserAttributes } from './PostFunctions';
import firebase from 'firebase/compat/app';
import { Subject } from 'rxjs';
import { SOCIAL_EVENT } from '@flutaro/package/lib/model/AppAnalyticsEvents';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
	providedIn: 'root',
})
export class PostProvider {
	isLoading: Subject<boolean> = new Subject<boolean>();

	constructor(
		private fbStore: AngularFirestore,
		private auth: AppAuthService,
		public modalController: ModalController,
		private commentService: CommentService,
		private activityProvider: ActivitiesService,
		private userProvider: UserProfileProvider,
		private timeline: TimelineService,
		private analytics: AnalyticsService,
		private ratings: LocationService,
	) {}

	getPostById(postId: string) {
		return this.fbStore
			.doc(`${postsFirestorePath}/${postId}`)
			.get()
			.pipe(map((docSnapshot) => <FlutaroPost>transformFeedForApp(docSnapshot)))
			.toPromise();
	}

	getLatestUserPosts(authorUid: string) {
		return this.fbStore
			.collection(postsFirestorePath, (ref) => ref.where('authorId', '==', authorUid).limit(3))
			.get()
			.pipe(map((querySnapshot) => <FlutaroPost[]>querySnapshot.docs.map((post) => transformFeedForApp(post))));
	}

	getNumberOfUserPosts(authorUid: string) {
		return this.fbStore
			.collection(postsFirestorePath, (ref) => ref.where('authorId', '==', authorUid))
			.get()
			.pipe(map((querySnapshot) => querySnapshot.size))
			.toPromise();
	}

	addFeed(feed: FlutaroPost) {
		this.analytics.logEvent(SOCIAL_EVENT.POST_CREATED);
		const user = this.userProvider.$userProfile.getValue();
		setPostsUserAttributes(feed, user);
		setDatesOnPostingCreate(feed);
		this.storeFeedInFirestore(feed);
	}

	editFeed(feed: FlutaroPost) {
		this.analytics.logEvent(SOCIAL_EVENT.POST_UPDATED);
		setDatesOnPostingEdit(feed);
		this.updateFeedFromAuthor(feed);
	}

	deleteFeedInFirestore(post: FlutaroPost) {
		this.analytics.logEvent(SOCIAL_EVENT.POST_DELETED);
		this.deleteFeedFromAuthor(post);
	}

	async addEditFeedItem(type: string, updateData?) {
		const modal = await this.modalController.create({
			component: PostAddModal,
			componentProps: {
				data: updateData ? flutaroCloneObject(updateData) : null,
				user: this.userProvider.$userProfile.getValue(),
			},
		});

		modal.onDidDismiss().then((data) => {
			if (!data) return;
			const post: FlutaroPost = data['data'];
			console.log(`Received Post from Modal: ${JSON.stringify(post)}`);
			if (!post) return;

			switch (post.type) {
				case PostTypes.POST: {
					if (!updateData) {
						console.log(`Adding Post from Modal.`);
						this.addFeed(post);
					} else {
						this.editFeed(post);
						const oldTaggedFriendsArray: any[] = updateData.taggedFriends.map((friend) => friend.uid);
						const newTaggedFriends = post.taggedFriends.filter(
							(friend) => oldTaggedFriendsArray.indexOf(friend.uid) === -1,
						);
						if (newTaggedFriends.length === 0) return;
						newTaggedFriends.forEach((friend) => {
							const taggedActivity = new ActivityFeedEntry(
								ActivityTypes.TAGGED,
								friend.uid,
								post.authorId,
								post.authorPhoto,
								post.authorName,
								post.id,
								post.content,
								post.type,
							);
							this.activityProvider.postActivityForUser(taggedActivity);
						});
					}
					break;
				}
				case PostTypes.COMMENT: {
					this.commentService.editComment(<any>post);
					break;
				}
				case PostTypes.RATING: {
					if (!updateData) {
						this.ratings.addLocationRating(<any>post);
					} else {
						this.ratings.editLocationRating(<any>post);
					}
					break;
				}
				default:
					console.error(`Couldnt detect PostType for Post-Type: ${post.type}`);
					break;
			}
		});

		modal.present().then((currentModal) => {});
	}

	storeFeedInFirestore(feed: FlutaroPost) {
		console.log(`Storing Feed in Firestore: ${JSON.stringify(feed)}
		and into collection: ${postsFirestorePath}`);
		this.fbStore
			.collection(postsFirestorePath)
			.add(preparePostBeforeFirebaseStorage(feed))
			//this.fbStore.collection(postsFirestorePath).add({ "test2": "Test erfolgreich." })
			.then((docRef) => {
				setDocRefAfterDocAddedInFirestore(feed, docRef);
				if (feed.taggedFriends.length > 0) {
					feed.taggedFriends.forEach((friend) => {
						const taggedActivity = new ActivityFeedEntry(
							ActivityTypes.TAGGED,
							friend.uid,
							feed.authorId,
							feed.authorPhoto,
							feed.authorName,
							feed.id,
							feed.content,
							feed.type,
						);
						this.activityProvider.postActivityForUser(taggedActivity);
					});
				}
				console.log('Added Post!');
				this.timeline.addFeedInternally(feed);
			})
			.catch((error) => {
				console.error(`FLUTARO-ERROR-FIREBASE: Couldnt create Post. Error: ${error}`);
			});
	}

	updateFeedFromAuthor(feed: FlutaroPost) {
		const preparedFeed = <any>preparePostBeforeFirebaseStorage(feed);
		let updateMap = {
			lastModified: preparedFeed.lastModified,
			content: preparedFeed.content,
			photo: preparedFeed.photo ? preparedFeed.photo : FieldValue.delete(),
			pagePreview: preparedFeed.pagePreview ? preparedFeed.pagePreview : FieldValue.delete(),
			taggedFriends: preparedFeed.taggedFriends,
		};
		this.fbStore
			.doc(`${postsFirestorePath}/${feed.id}`)
			.update(updateMap)
			.then((added) => {
				this.timeline.updateFeedInternally(feed);
				console.log('updateFeedFromAuthor!');
			})
			.catch((error) => {
				console.error(error);
			});
	}

	private deleteFeedFromAuthor(post: FlutaroPost) {
		this.fbStore
			.doc(`${postsFirestorePath}/${post.id}`)
			.delete()
			.then((deleted) => {
				console.log('Feed deleted!!');
				this.timeline.deleteFeedInternally(post);
			})
			.catch((error) => {
				console.error(error);
			});
	}
}
