import { Component } from '@angular/core';
import { FlutaroGroup, GroupMember } from '../FlutaroGroup';
import { ActionSheetController, AlertController, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LikeService } from '../../like/like.service';
import { ActivitiesService } from '../../activities/activities.service';
import { ActivityFeedEntry, ActivityTypes } from '../../activities/ActivitiesFeed';
import { FlutaroPost, PostTypes } from '../../post/post';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { generateKeywordsForName } from '@flutaro/package/lib/functions/db/UserProfileFunctions';

@Component({
	selector: 'app-groups-modal',
	templateUrl: './groups-modal.component.html',
	styleUrls: ['./groups-modal.component.scss'],
})
export class GroupsModalComponent {
	isEdit: boolean = false;
	isOwner: boolean = false;
	group: FlutaroGroup = new FlutaroGroup();
	user: FlutaroUserProfile;
	userIsGroupMember: boolean = false;
	changedMembersSubject: Subject<FlutaroGroup>;
	addPostForGroupSubject: Subject<any>;
	editedPostSubject: Subject<any>;
	deletedPostSubject: Subject<any>;
	deleteGroupSubject: Subject<any>;
	changedPostsSubject: Subject<any>;
	invitedMembersForNewGroupSubject: BehaviorSubject<GroupMember[]>;
	showAddMembers: boolean = false;
	invitedMembers: GroupMember[] = [];
	groupPosts: FlutaroPost[] = [];
	isGroupEdit: boolean;
	actionSheet: any;

	constructor(
		public modalController: ModalController,
		private navParams: NavParams,
		private alertController: AlertController,
		private translate: TranslateService,
		private likeService: LikeService,
		private activities: ActivitiesService,
		public actionSheetController: ActionSheetController,
	) {
		this.user = navParams.get('user');
		this.changedMembersSubject = navParams.get('changedMembersSubject');
		this.editedPostSubject = navParams.get('editedPostSubject');
		this.deletedPostSubject = navParams.get('deletePostForGroup');
		this.deleteGroupSubject = navParams.get('deleteGroupSubject');
		this.addPostForGroupSubject = navParams.get('addPostForGroupSubject');
		this.invitedMembersForNewGroupSubject = navParams.get('invitedMembersForNewGroupSubject');
		this.changedPostsSubject = navParams.get('changedPostsSubject');

		this.changedPostsSubject.subscribe((addedPost) => {
			const isExisting =
				this.groupPosts.length > 0 && this.groupPosts.filter((post) => post.id === addedPost.id).length === 1;
			if (isExisting) {
				const newGroupPosts = this.groupPosts.filter((post) => post.id === addedPost.id);
				newGroupPosts.push(addedPost);
				this.groupPosts = newGroupPosts;
			} else {
				this.groupPosts.push(addedPost);
			}
		});

		this.groupPosts = navParams.get('groupsPosts');
		if (navParams.get('data')) {
			this.group = navParams.get('data');
			if (this.user.uid === this.group.authorId) {
				this.isEdit = true;
				this.isOwner = true;
			}
			console.log('Edit of Group! :)');
		} else {
			this.isOwner = true;
			this.group.authorId = this.user.uid;
			this.isGroupEdit = true;
		}
		this.userIsGroupMember = !!this.group.members.find((member) => member.uid === this.user.uid);
	}

	triggerGroupEdit() {
		this.isGroupEdit = true;
	}

	async presentActionSheet() {
		let buttons = [];
		if (this.isOwner) {
			buttons = buttons.concat([
				{
					text: this.translate.instant('EDIT'),
					icon: 'create-outline',
					handler: () => {
						console.log('Edit clicked');
						this.triggerGroupEdit();
					},
				},
				{
					text: this.translate.instant('DELETE'),
					role: 'destructive',
					icon: 'trash-outline',
					handler: () => {
						console.log('Delete clicked');
						this.presentDeleteAlertConfirm();
					},
				},
			]);
		} else {
			buttons.push({
				text: this.translate.instant('GROUP_LEAVE'),
				icon: 'trash-outline',
				role: 'destructive',
				handler: () => {
					console.log('Leave group clicked');
					this.changeUserIsGroupMember();
				},
			});
		}

		buttons.push({
			text: this.translate.instant('CANCEL'),
			icon: 'close-circle-outline',
			role: 'cancel',
			handler: () => {
				console.log('Cancel clicked');
			},
		});

		this.actionSheet = this.actionSheetController
			.create({
				header: this.translate.instant('GROUP_ACTIONS'),
				buttons: buttons,
			})
			.then((actionsheet) => {
				actionsheet.present();
			});
	}

	async presentDeleteAlertConfirm() {
		let headerText = 'DELETE_GROUP';
		let messageText = 'DELETE_GROUP_CONFIRM';

		const alert = await this.alertController.create({
			header: `${this.translate.instant(headerText)}?`,
			message: `${this.translate.instant(messageText)}`,
			buttons: [
				{
					text: this.translate.instant('CANCEL'),
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {},
				},
				{
					text: this.translate.instant('DELETE'),
					handler: () => {
						this.deleteGroupSubject.next(this.group);
						this.dismiss();
					},
				},
			],
		});

		await alert.present();
	}

	editGroupPost(post) {
		console.log(post);
		this.editedPostSubject.next({ groupId: this.group.id, post: post });
		this.groupPosts = this.groupPosts.filter((groupPost) => groupPost.id !== post.id);
		this.groupPosts.push(post);
	}

	deleteGroupPost(post) {
		console.log(post);
		this.deletedPostSubject.next({ groupId: this.group.id, post: post });
		this.groupPosts = this.groupPosts.filter((groupPost) => groupPost.id !== post.id);
	}

	addNewPostForGroup() {
		this.addPostForGroupSubject.next(this.group);
	}

	save() {
		if (!this.checkGroupValid()) {
			console.log(`Error: Group invalid.`);
			return;
		}
		if (this.invitedMembers.length > 0) {
			console.log(`Sending invite to ${this.invitedMembers.length} new group members.`);
			if (this.isEdit) {
				this.invitedMembers.forEach((newMember) => {
					let groupInviteTimelineEntry = new ActivityFeedEntry(
						ActivityTypes.INVITE,
						newMember.uid,
						this.group.authorId,
						this.group.authorPhoto,
						this.group.authorName,
						this.group.id,
						this.group.title,
						PostTypes.GROUP_POST,
					);
					this.activities.postActivityForUser(groupInviteTimelineEntry);
				});
			} else {
				this.invitedMembersForNewGroupSubject.next(this.invitedMembers);
			}

			this.group.members = this.group.members.concat(this.invitedMembers);
			this.group.memberIds = this.group.memberIds.concat(this.invitedMembers.map((member) => member.uid));
		}
		this.group.keywords = generateKeywordsForName(this.group.title);
		this.modalController.dismiss(this.group);
	}

	dismiss() {
		this.modalController.dismiss(null);
	}

	toogleShowAddMembers() {
		this.showAddMembers = !this.showAddMembers;
	}

	changeUserIsGroupMember() {
		this.userIsGroupMember = !this.userIsGroupMember;
		if (this.userIsGroupMember) {
			this.group.members.push(new GroupMember(this.user.uid, this.user.displayName, this.user.photoURL));
		} else {
			this.group.members = this.group.members.filter((member) => member.uid !== this.user.uid);
			this.dismiss();
		}
		this.changedMembersSubject.next(this.group);
	}

	showGroupMembers() {
		this.likeService.showLikes(this.group.members, this.user, 'GROUP_MEMBERS');
	}

	changeGroupPublic() {
		if (this.group.isPublic) {
			this.group.isPublicSearchable = true;
		}
	}

	async presentAlert(title: string, content: string) {
		const alert = await this.alertController.create({
			header: title,
			message: content,
			buttons: ['OK'],
		});

		await alert.present();
	}

	private checkGroupValid() {
		return !!this.group.content && !!this.group.title;
	}
}
