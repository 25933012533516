import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { scan, tap } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

// Options to reproduce firestore queries consistently
export interface QueryConfig {
	path: string;
	field: string;
	limit: number;
	reverse: boolean;
	prepend: boolean;
}

@Injectable()
export class FirebasePaginationService {
	msgSlotArraySize: number = 0;
	snapshotChangesCount: number;
	previousMsgArraySize: number;
	sizeOfMsgArray: number;
	fbSub: Subscription;
	msgSlotCount: number = 0;
	public query: QueryConfig;
	// Observable data
	data: Observable<any>;
	// Source data
	private _loading = new BehaviorSubject(false);
	loading: Observable<boolean> = this._loading.asObservable();
	private _data = new BehaviorSubject([]);

	constructor(private afs: AngularFirestore) {}

	// Initial query sets options and defines the Observable
	// passing opts will override the defaults
	init(path: string, field: string, opts?: any) {
		this.query = {
			path,
			field,
			limit: 20,
			reverse: false,
			prepend: false,
			...opts,
		};
		const first = this.afs.collection(this.query.path, (ref) => {
			return ref.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc').limit(this.query.limit);
		});
		this.mapAndUpdate(first, 'forward');
		this.reset();
		// Create the observable array for consumption in components
		this.data = this._data.asObservable().pipe(
			scan((acc, val) => {
				this.msgSlotArraySize = val.length;
				this.msgSlotCount++;
				if (acc.length > 0 && val.length > 1) {
					if (acc[0].doc.id === val[1].doc.id || acc[0].doc.id === val[0].doc.id) {
						return val;
					} else {
						if (this.snapshotChangesCount === 1) {
							this.previousMsgArraySize = this.sizeOfMsgArray;
						} else if (this.snapshotChangesCount === 2) {
							acc = acc.slice(0, acc.length - this.previousMsgArraySize);
						}
						return this.query.prepend ? val.concat(acc) : acc.concat(val);
					}
				} else {
					return this.query.prepend ? val.concat(acc) : acc.concat(val);
				}
			}),
		);
	}

	// paginates through firstore data
	paginate(direction: string) {
		const cursor = this.getCursor(direction);
		const firestoreMessageCollection = this.afs.collection(this.query.path, (ref) => {
			if (direction === 'forward') {
				return ref
					.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
					.startAfter(cursor)
					.limit(this.query.limit);
			} else if (direction === 'backward') {
				return ref
					.orderBy(this.query.field, this.query.reverse ? 'asc' : 'desc')
					.startAfter(cursor)
					.limit(this.query.limit);
			}
		});
		this.mapAndUpdate(firestoreMessageCollection, direction);
	}

	reset() {
		this._data.next([]);
	}

	// Determines the doc snapshot to paginate query
	private getCursor(direction: string) {
		const current = this._data.value;
		if (current.length) {
			return direction === 'backward' ? current[0].doc : current[current.length - 1].doc;
		}
		return null;
	}

	// Maps the snapshot to usable format the updates source
	private mapAndUpdate(col: AngularFirestoreCollection<any>, direction: string) {
		this.snapshotChangesCount = 0;
		if (this._loading.value) {
			return;
		}
		// loading
		this._loading.next(true);
		// Map snapshot with doc ref (needed for cursor)
		return (this.fbSub = col
			.snapshotChanges()
			.pipe(
				tap((arr) => {
					this.snapshotChangesCount++;
					let values = arr.map((snap) => {
						const data = snap.payload.doc.data();
						const doc = snap.payload.doc;
						return { ...data, doc };
					});
					// If prepending, reverse the batch order
					this.sizeOfMsgArray = values.length;
					values = direction === 'backward' ? values.reverse() : values;
					// update source with new values, done loading
					if (!!values.length) {
						this._data.next(values);
					} else {
					}
					this._loading.next(false);
				}),
			)
			.subscribe());
	}
}
