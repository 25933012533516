import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { JobWrapper } from '@flutaro/package/lib/model/Job';

@Component({
	selector: 'app-job',
	templateUrl: './job-page.component.html',
	styleUrls: ['./job-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobPageComponent {
	jobWrapper: JobWrapper;

	constructor(public platform: Platform, public params: NavParams, public viewCtrl: ModalController) {
		this.jobWrapper = this.params.get('jobWrapper');
	}

	dismiss() {
		this.viewCtrl.dismiss();
	}
}
