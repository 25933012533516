<div class="splashScreenBackground">
  <div class="centeredDiv">
    <img class="splashScreenLogo" src="assets/imgs/Flutaro_Logo_white.svg">
    <div class="errorPageErrorBackground">
      <p class="errorPageErrorIcon">!</p>
    </div>
    <span class="splashScreenText">
          Achtung: Deine E-Mail-Adresse ist noch nicht bestätigt<br>
          <br>
          Wir haben dir eine E-Mail zur Bestätigung deiner E-Mail-Adresse zugesendet.<br>
          Bitte schaue in dein Postfach und bestätige deine E-Mail Adresse, indem du auf den Link in der E-Mail klickst. <br>
          Vielen Dank!
    </span>

    <button (click)="resendEmailVerificationEmail()"
            *ngIf="isEmailVerificationResentEnabled"
            [disabled]="isEmailVerificationResent"
            class="disable-hover button button-md button-default button-default-md button-full button-full-md button-md-flutaroMain"
            color="flutaroMain" full icon-end
            ion-button>
      Bestätigungsemail erneut versenden
      <ion-icon name="log-in-outline"></ion-icon>
    </button>

    <button (click)="auth.signOutAndRedirectToHome()"
            class="disable-hover button button-md button-default button-default-md button-full button-full-md button-md-flutaroMain"
            color="flutaroMain" full icon-end
            ion-button>
      Ausloggen und zum Login
      <ion-icon name="log-in-outline"></ion-icon>
    </button>
  </div>
</div>
