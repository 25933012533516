import { Component, Input, OnInit } from '@angular/core';
import { AvatarService } from './avatar.service';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';

@Component({
	selector: 'app-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
	@Input() authorId: string;
	@Input() authorPhoto: string;
	@Input() user: FlutaroUserProfile;

	constructor(public avatarService: AvatarService) {}

	ngOnInit() {}
}
