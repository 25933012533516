import { NgModule } from '@angular/core';
import { GroupsPageComponent } from './groups-page/groups-page.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { ShowGroupComponent } from './show-group/show-group.component';
import { GroupsModalComponent } from './groups-modal/groups-modal.component';
import { ShareModule } from '../../share.module';
import { PostPageModule } from '../post/post.module';

@NgModule({
	declarations: [GroupsPageComponent, CreateGroupComponent, ShowGroupComponent, GroupsModalComponent],
	imports: [ShareModule, PostPageModule],
})
export class GroupsModule {}
