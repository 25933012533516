<ion-header center>
    <ion-toolbar color="color596163">
        <ion-buttons slot="start">
            <ion-back-button defaultHref="page-job" (click)="abbort()"></ion-back-button>
        </ion-buttons>
        <ion-title class="ion-text-center" translate>
            <ion-icon class="jobStatusDeclinedIconDeclinedReason"></ion-icon>
            <span translate>JOB_DECLINE_PAGE_DECLINE</span>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-textarea class="jobDeclineReasonTextarea" [placeholder]="'ENTER_DECLINE_REASON' | translate"
                  [(ngModel)]="jobDeclineReason">
    </ion-textarea>
</ion-content>

<ion-button slot="end" class="jobDeclinedReasonSendButton ion-float-end" (click)="declineJob()"
                [disabled]="!jobDeclineReason || jobDeclineReason.length === 0"
                translate>
                DECLINE
    <ion-icon slot="end" name="send-outline" ios="send-outline" md="send-outline"></ion-icon>
    </ion-button>
    <ion-button slot="end" class="jobDeclinedReasonCancelButton ion-float-end" (click)="abbort()" translate>
        CANCEL
  </ion-button>
