import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FollowerService } from './follower.service';

@NgModule({
	providers: [FollowerService],
	imports: [CommonModule],
	exports: [],
})
export class FollowerModule {}
