import { DestinationType, JobDestination, JobWrapper } from '@flutaro/package/lib/model/Job';
import { getJobsFirstPickupDate, getJobsLastDeliveryDate } from '@flutaro/package/lib/functions/DestinationFunctions';
import { FlutaroGPSPosition } from '@flutaro/package/lib/model/Positiondata';

export function setPositionsDestinationInformation(position: FlutaroGPSPosition, job: JobWrapper) {
	position.customerPickupLocationId = getCustomerPickupLocationId(job);
	position.customerDeliveryLocationId = getCustomerDeliveryLocationId(job);
	position.jobsDeliveryPlannedDate = getJobsLastDeliveryDate(job);
	position.jobsPickupPlannedDate = getJobsFirstPickupDate(job);
}

export function getCustomerPickupLocationId(job: JobWrapper) {
	if (!job.destinations) {
		return 'No_Destinations';
	}
	let descriptionAsCustomerId = job.destinations.find(
		(dest) => dest.locationType === DestinationType.PICKUP,
	).description;
	return descriptionAsCustomerId ? descriptionAsCustomerId : 'No customer Id';
}

export function getCustomerDeliveryLocationId(job: JobWrapper) {
	let descriptionAsCustomerIdDelivery = findLastMatchingObjectInArray(
		job.destinations,
		DestinationType.DELIVERY,
	).description;
	return descriptionAsCustomerIdDelivery ? descriptionAsCustomerIdDelivery : 'No customer Id';
}

export function findLastMatchingObjectInArray(destinations: JobDestination[], type: string) {
	return destinations
		.slice()
		.reverse()
		.find((dest) => dest.locationType === type);
}

export function createPositionDataFromJobAndPosition(currentStartedJob: JobWrapper): FlutaroGPSPosition {
	let flutPosition = new FlutaroGPSPosition();
	flutPosition.vehicleId = currentStartedJob.vehicleId;
	flutPosition.licensePlate = currentStartedJob.vehicleLicensePlate;
	flutPosition.jobId = currentStartedJob.backendId;
	flutPosition.jobIdentifier = currentStartedJob.toString();
	if (currentStartedJob.attributes['shipmentId']) {
		flutPosition.jobCustomerId = currentStartedJob.attributes['shipmentId'];
	}
	if (currentStartedJob.attributes['customerId']) {
		flutPosition.customerId = currentStartedJob.attributes['customerId'];
	}
	setPositionsDestinationInformation(flutPosition, currentStartedJob);
	return flutPosition;
}
