import { Injectable } from '@angular/core';
import { activitiesRef } from '../model/FirestoreModel';
import { map } from 'rxjs/operators';
import { prepareTimelineEntryForFiretore, transformTimelineData } from '../../services/FirestoreFunctions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivityFeedEntry } from './ActivitiesFeed';
import { BehaviorSubject } from 'rxjs';
import { FirestoreCollection } from '@flutaro/package/lib/model/db/FirestoreClasses';

@Injectable({
	providedIn: 'root',
})
export class ActivitiesService {
	userActivityPath: string;
	$activities: BehaviorSubject<ActivityFeedEntry[]> = new BehaviorSubject<ActivityFeedEntry[]>([]);
	$isLoading: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
	unreadActivities: number = 0;

	constructor(private fbStore: AngularFirestore) {}

	activateActivityService(userUid: string) {
		if (!userUid) {
			console.error(`ERROR: no UserUid provided. Aborting Activity-Service.`);
			return;
		}
		this.userActivityPath = `${FirestoreCollection.SOCIAL}/${userUid}/${activitiesRef}`;
		this.getActivities();
	}

	getActivities() {
		this.$isLoading.next(true);
		this.fbStore
			.collection<ActivityFeedEntry>(this.userActivityPath, (ref) => ref.orderBy('timestamp', 'desc').limit(10))
			.snapshotChanges()
			.pipe(
				map((timeline) =>
					timeline.map((docChangeAction) => {
						const doc = docChangeAction.payload.doc;
						let activity: ActivityFeedEntry = doc.data();
						activity.ref = doc.ref;
						return activity;
					}),
				),
			)
			.subscribe((timelineData) => {
				this.$activities.next(transformTimelineData(<any>timelineData));
				this.$isLoading.next(false);
				this.setUnreadActivities();
			});
	}

	/**
	 * Users are allowed to create Activities as long they belong to their uid, handled in Firestore Rules
	 * @param {ActivityFeedEntry} entry
	 */
	postActivityForUser(entry: ActivityFeedEntry) {
		this.fbStore
			.collection(`${FirestoreCollection.SOCIAL}/${entry.ownerId}/${activitiesRef}`)
			.add(prepareTimelineEntryForFiretore(entry))
			.then((success) => {
				console.log(`Activity posted for User ${entry.ownerId} with Author: ${entry.authorId}/${entry.authorName}.`);
			})
			.catch((error) => {
				console.log(
					`ERROR in Activity post for User ${entry.ownerId} with Author: ${entry.authorId}/${entry.authorName}.`,
				);
				console.error(error);
			});
	}

	setActivityAsRead(activity: ActivityFeedEntry) {
		let updateMap = {
			isRead: true,
		};
		this.fbStore
			.doc(activity.ref)
			.update(updateMap)
			.then((isRead) => {
				console.log('Updated isRead in Activity!');
				activity.isRead = true;
			})
			.catch((error) => {
				console.error(error);
			});
	}

	private setUnreadActivities() {
		this.unreadActivities = this.$activities.getValue().filter((activity) => !activity.isRead).length;
	}
}
