import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-events-page',
	templateUrl: './events-page.component.html',
	styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
