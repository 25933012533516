import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HomePage } from './home.page';
import { HomeShiftComponent } from './home.shift.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { ChatPageModule } from '../chat/chat.module';
import { JobPageModule } from '../jobs/job.module';
import { JobComponentModule } from 'src/app/jobs/job.component.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from '../../share.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		JobPageModule,
		ComponentsModule,
		ChatPageModule,
		JobComponentModule,
		PipesModule,
		RouterModule,
		TranslateModule,
		ShareModule,
	],
	declarations: [HomePage, HomeShiftComponent],
})
export class HomePageModule {}
