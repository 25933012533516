import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { LoadingComponent } from './loading/loading.component';
import { PipesModule } from './pipes/pipes.module';
import { ComponentsModule } from './components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlutaroPipesModule } from 'flutaro-lib-angular/lib/pipes/flutaro.pipes.module';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, PipesModule, ComponentsModule, TranslateModule, FlutaroPipesModule],
	declarations: [LoadingComponent],
	exports: [
		CommonModule,
		FormsModule,
		IonicModule,
		LoadingComponent,
		PipesModule,
		ComponentsModule,
		TranslateModule,
		FlutaroPipesModule,
	],
})
export class ShareModule {}
