import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { JobPageComponent } from './job-page.component';
import { JobsFilterService } from './jobs-filter.service';
import { TranslateModule } from '@ngx-translate/core';
import { CargoComponent } from './cargo/cargo.component';
import { MatIconModule } from '@angular/material/icon';
import { ShareModule } from '../../share.module';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ComponentsModule,
		PipesModule,
		TranslateModule,
		MatIconModule,
		ShareModule,
		FlexModule,
	],
	declarations: [JobPageComponent, CargoComponent],
	providers: [JobsFilterService],
})
export class JobPageModule {}
