import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AnalyticsService } from '../../services/Analytics/analytics.service';
import { groupsDataRef } from '../model/FirestoreModel';
import { map } from 'rxjs/operators';
import { basicPostFirestoreHandler, setPostsUserAttributes } from '../post/PostFunctions';
import { FlutaroGroup, GroupMember } from './FlutaroGroup';
import { UserProfileProvider } from '../../user/user.profile.provider';
import { cloneFeedObject, flutaroCloneObject } from '../../AppFunctions';
import {
	prepareGroupBeforeFirebaseStorage,
	prepareGroupMembersBeforeFirebaseStorage,
	preparePostBeforeFirebaseStorage,
	setDocRefAfterDocAddedInFirestore,
} from '../../services/FirestoreFunctions';
import { ModalController } from '@ionic/angular';
import { GroupsModalComponent } from './groups-modal/groups-modal.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { FlutaroPost, PostTypes } from '../post/post';
import { PostAddModal } from '../post/post/add/post.add.modal';
import { ActivityFeedEntry, ActivityTypes } from '../activities/ActivitiesFeed';
import { setDatesOnPostingCreate, setDatesOnPostingEdit } from '../feed/FeedFunctions';
import { ActivitiesService } from '../activities/activities.service';
import firebase from 'firebase/compat/app';
import { SOCIAL_EVENT } from '@flutaro/package/lib/model/AppAnalyticsEvents';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
	providedIn: 'root',
})
export class GroupsService {
	$groups: BehaviorSubject<FlutaroGroup[]> = new BehaviorSubject([]);
	$addPostForGroup: Subject<FlutaroGroup> = new Subject<FlutaroGroup>(); // Listen to changes from Modal - circular dependency prevention
	$editPostForGroup: Subject<any> = new Subject<any>(); // Listen to changes from Modal - circular dependency prevention
	$deletePostForGroup: Subject<any> = new Subject<any>(); // Listen to changes from Modal - circular dependency prevention
	$deleteGroupSubject: Subject<FlutaroGroup> = new Subject<FlutaroGroup>(); // Listen to changes from Modal - circular dependency prevention
	$changedPostsSubject: Subject<any> = new Subject<any>(); // Listen to changes from Modal - circular dependency prevention

	changedMembersSubject: Subject<FlutaroGroup> = new Subject<FlutaroGroup>(); // Listen to changes from Modal - circular dependency prevention
	$invitedMembersForNewGroupSubject: BehaviorSubject<GroupMember[]> = new BehaviorSubject<GroupMember[]>(null);

	constructor(
		private fbStore: AngularFirestore,
		private analytics: AnalyticsService,
		private userProvider: UserProfileProvider,
		private modalController: ModalController,
		private activities: ActivitiesService,
	) {
		this.$addPostForGroup.subscribe((group) => {
			this.addEditPostForGroup(group.id);
			//this.changeGroupMembers(group, !!group.members.find(member => member.uid === this.userProvider.$userProfile.getValue().uid));
		});
		this.$editPostForGroup.subscribe((postChange) => {
			this.addEditPostForGroup(postChange.groupId, postChange.post);
			//this.changeGroupMembers(group, !!group.members.find(member => member.uid === this.userProvider.$userProfile.getValue().uid));
		});
		this.$deletePostForGroup.subscribe((postChange) => {
			this.deleteGroupPost(postChange.groupId, postChange.post);
			//this.changeGroupMembers(group, !!group.members.find(member => member.uid === this.userProvider.$userProfile.getValue().uid));
		});
		this.$deleteGroupSubject.subscribe((group) => {
			this.deleteGroup(group);
			//this.changeGroupMembers(group, !!group.members.find(member => member.uid === this.userProvider.$userProfile.getValue().uid));
		});
		this.changedMembersSubject.subscribe((group) => {
			this.changeGroupMembers(
				group,
				!!group.members.find((member) => member.uid === this.userProvider.$userProfile.getValue().uid),
			);
		});
	}

	getUsersGroups() {
		this.fbStore
			.collection<FlutaroGroup[]>(groupsDataRef, (ref) =>
				ref.where('memberIds', 'array-contains', this.userProvider.$userProfile.getValue().uid),
			)
			.get()
			.pipe(map((querySnapshot) => querySnapshot.docs.map((docRef) => <FlutaroGroup>basicPostFirestoreHandler(docRef))))
			.toPromise()
			.then((groups) => {
				this.$groups.next(groups);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	searchGroups(keyword: string) {
		return this.fbStore
			.collection<FlutaroGroup[]>(groupsDataRef, (ref) =>
				ref
					.where('isPublicSearchable', '==', true)
					.where('keywords', 'array-contains', keyword.toLowerCase())
					.orderBy('title', 'asc')
					.limit(20),
			)
			.get()
			.pipe(map((querySnapshot) => querySnapshot.docs.map((docRef) => <FlutaroGroup>basicPostFirestoreHandler(docRef))))
			.toPromise();
	}

	async openGroupByID(groupId: string) {
		const group = await this.fbStore
			.doc(`${groupsDataRef}/${groupId}`)
			.get()
			.pipe(map((docSnapshot) => <FlutaroGroup>basicPostFirestoreHandler(docSnapshot)))
			.toPromise();
		if (!group) {
			console.error(`ERROR: Couldnt find Group ${groupId}`);
			return;
		}
		this.openGroupsDialog(group);
	}

	getGroupsPosts(groupId: string) {
		return this.fbStore
			.collection<FlutaroPost[]>(`${groupsDataRef}/${groupId}/posts`, (ref) => ref.limit(20))
			.get()
			.pipe(
				map((querySnapshot) => {
					return querySnapshot.docs.map((docRef) => <FlutaroPost>basicPostFirestoreHandler(docRef));
				}),
			)
			.toPromise();
	}

	async addEditPostForGroup(groupId: string, groupPost?: FlutaroPost) {
		const modal = await this.modalController.create({
			component: PostAddModal,
			componentProps: {
				data: groupPost ? flutaroCloneObject(groupPost) : null,
				user: this.userProvider.$userProfile.getValue(),
				type: PostTypes.GROUP_POST,
				groupId: groupId,
			},
		});

		modal.onDidDismiss().then((data) => {
			if (!data) return;
			const post: FlutaroPost = data['data'];
			if (!post) return;
			if (!groupPost) {
				console.log(`Adding Group-Post from Modal.`);
				this.addFeed(post, groupId);
			} else {
				this.editFeed(post, groupId);
				const oldTaggedFriendsArray: any[] = groupPost.taggedFriends.map((friend) => friend.uid);
				const newTaggedFriends = post.taggedFriends.filter(
					(friend) => oldTaggedFriendsArray.indexOf(friend.uid) === -1,
				);
				if (newTaggedFriends.length === 0) return;
				newTaggedFriends.forEach((friend) => {
					const taggedActivity = new ActivityFeedEntry(
						ActivityTypes.TAGGED,
						friend.uid,
						post.authorId,
						post.authorPhoto,
						post.authorName,
						post.id,
						post.content,
						post.type,
					);
					//this.activityProvider.postActivityForUser(taggedActivity);
				});
				/**
         *  case PostTypes.COMMENT: {
						this.commentService.editComment(<any>post);
						break;
					}
         */
			}
		});
		modal.present().then((currentModal) => {});
	}

	addFeed(groupPost: FlutaroPost, groupId: string) {
		this.analytics.logEvent(SOCIAL_EVENT.GROUP_POST_CREATED);
		const user = this.userProvider.$userProfile.getValue();
		setPostsUserAttributes(groupPost, user);
		setDatesOnPostingCreate(groupPost);
		this.storeFeedInFirestore(groupPost, groupId);
	}

	editFeed(feed: FlutaroPost, groupId) {
		this.analytics.logEvent(SOCIAL_EVENT.GROUP_POST_UPDATED);
		setDatesOnPostingEdit(feed);
		this.updateFeedFromAuthor(feed, groupId);
	}

	updateFeedFromAuthor(post: FlutaroPost, groupId) {
		const preparedFeed = <any>preparePostBeforeFirebaseStorage(post);
		let updateMap = {
			lastModified: preparedFeed.lastModified,
			content: preparedFeed.content,
			photo: preparedFeed.photo ? preparedFeed.photo : FieldValue.delete(),
			pagePreview: preparedFeed.pagePreview ? preparedFeed.pagePreview : FieldValue.delete(),
			taggedFriends: preparedFeed.taggedFriends,
		};

		this.fbStore
			.doc(`${groupsDataRef}/${groupId}/posts/${post.id}`)
			.update(updateMap)
			.then((added) => {
				//this.timeline.updateFeedInternally(feed);
				this.$changedPostsSubject.next(post); // TODO: this is faked add feed internally, improve
				console.log('Updated groups post!');
			})
			.catch((error) => {
				console.error(error);
			});
	}

	storeFeedInFirestore(post: FlutaroPost, groupId: string) {
		this.fbStore
			.collection(`${groupsDataRef}/${groupId}/posts`)
			.add(preparePostBeforeFirebaseStorage(post))
			.then((docRef) => {
				setDocRefAfterDocAddedInFirestore(post, docRef);
				if (post.taggedFriends.length > 0) {
					post.taggedFriends.forEach((friend) => {
						const taggedActivity = new ActivityFeedEntry(
							ActivityTypes.TAGGED,
							friend.uid,
							post.authorId,
							post.authorPhoto,
							post.authorName,
							post.id,
							post.content,
							post.type,
						);
						//this.activityProvider.postActivityForUser(taggedActivity);
					});
				}
				this.$changedPostsSubject.next(post); // TODO: this is faked add feed internally, improve
				console.log('Added Post!');
				//this.timeline.addFeedInternally(feed);
			})
			.catch((error) => {
				console.error(`FLUTARO-ERROR-FIREBASE: Couldnt create Post. Error: ${error}`);
			});
	}

	async openGroupsDialog(group?: FlutaroGroup) {
		let groupsPosts;
		if (group) {
			groupsPosts = await this.getGroupsPosts(group.id);
		}
		const modal = await this.modalController.create({
			component: GroupsModalComponent,
			componentProps: {
				data: group ? flutaroCloneObject(group) : null,
				user: this.userProvider.$userProfile.getValue(),
				changedMembersSubject: this.changedMembersSubject,
				addPostForGroupSubject: this.$addPostForGroup,
				editedPostSubject: this.$editPostForGroup,
				deletePostForGroup: this.$deletePostForGroup,
				deleteGroupSubject: this.$deleteGroupSubject,
				invitedMembersForNewGroupSubject: this.$invitedMembersForNewGroupSubject,
				groupsPosts: groupsPosts,
				changedPostsSubject: this.$changedPostsSubject,
			},
		});

		modal.onDidDismiss().then((data) => {
			if (!data) return;
			const editedGroup: FlutaroGroup = data['data'];
			if (!editedGroup) return;

			if (!group) {
				this.addGroup(editedGroup);
			} else {
				this.editGroup(editedGroup);
			}
		});

		modal.present().then((currentModal) => {});
	}

	changeGroupMembers(group: FlutaroGroup, isMember: boolean) {
		this.analytics.logEvent(isMember ? SOCIAL_EVENT.GROUP_MEMBER_JOIN : SOCIAL_EVENT.GROUP_MEMBER_LEAVE);
		let updateMap = {
			members: prepareGroupMembersBeforeFirebaseStorage(group).members,
			memberIds: isMember
				? FieldValue.arrayUnion(this.userProvider.$userProfile.getValue().uid)
				: FieldValue.arrayRemove(this.userProvider.$userProfile.getValue().uid),
		};

		this.fbStore
			.doc(`${groupsDataRef}/${group.id}`)
			.update(updateMap)
			.then((added) => {
				console.log('Group members updated!');
			})
			.catch((error) => {
				console.error(error);
			});
	}

	editGroup(group: FlutaroGroup) {
		this.analytics.logEvent(SOCIAL_EVENT.GROUP_UPDATED);
		this.updateGroup(group);
	}

	deleteGroup(group: FlutaroGroup) {
		this.analytics.logEvent(SOCIAL_EVENT.GROUP_DELETED);
		this.fbStore
			.doc(`${groupsDataRef}/${group.id}`)
			.delete()
			.then((deleted) => {
				console.log('Group deleted from Database!');
				this.deleteGroupInternally(group);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	deleteGroupPost(groupId: string, post) {
		this.analytics.logEvent(SOCIAL_EVENT.GROUP_POST_DELETED);
		this.fbStore
			.doc(`${groupsDataRef}/${groupId}/posts/${post.id}`)
			.delete()
			.then((deleted) => {
				console.log('Group Post deleted from Database!');
			})
			.catch((error) => {
				console.error(error);
			});
	}

	private addGroup(group: FlutaroGroup) {
		this.analytics.logEvent(SOCIAL_EVENT.GROUP_CREATED);
		const user = this.userProvider.$userProfile.getValue();
		group.authorId = user.uid;
		group.authorName = user.displayName;
		group.authorPhoto = user.photoURL;
		group.memberIds.push(group.authorId);
		group.members.push(new GroupMember(group.authorId, group.authorName, group.authorPhoto)); // So Creators can see their own groups
		this.fbStore
			.collection(groupsDataRef)
			.add(prepareGroupBeforeFirebaseStorage(group))
			.then((docRef) => {
				setDocRefAfterDocAddedInFirestore(group, docRef);
				console.log('Added Group!');
				this.addGroupInternally(group);
				if (this.$invitedMembersForNewGroupSubject.getValue()) {
					console.log(`New invited Members on new created group.`);
					this.$invitedMembersForNewGroupSubject.getValue().forEach((newMember) => {
						let groupInviteTimelineEntry = new ActivityFeedEntry(
							ActivityTypes.INVITE,
							newMember.uid,
							group.authorId,
							group.authorPhoto,
							group.authorName,
							group.id,
							group.title,
							PostTypes.GROUP_POST,
						);
						this.activities.postActivityForUser(groupInviteTimelineEntry);
					});
					this.$invitedMembersForNewGroupSubject.next(null);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	private updateGroup(group: FlutaroGroup) {
		const preparedGroup = prepareGroupBeforeFirebaseStorage(group);
		let updateMap = {
			title: preparedGroup.title,
			content: preparedGroup.content,
			photo: preparedGroup.photo ? preparedGroup.photo : FieldValue.delete(),
			members: preparedGroup.members,
			isPublic: preparedGroup.isPublic,
			isPublicSearchable: preparedGroup.isPublicSearchable,
			keywords: preparedGroup.keywords,
		};

		this.fbStore
			.doc(`${groupsDataRef}/${group.id}`)
			.update(updateMap)
			.then((added) => {
				this.updateGroupInternally(group);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	private addGroupInternally(group: FlutaroGroup) {
		const dataBeforePost = this.$groups.getValue().slice();
		dataBeforePost.push(group);
		this.$groups.next(dataBeforePost);
	}

	private updateGroupInternally(group: FlutaroGroup) {
		const newRefGroup: FlutaroGroup = <FlutaroGroup>cloneFeedObject(group);
		let oldFeeds = this.$groups.getValue().slice();
		oldFeeds = oldFeeds.filter((feed) => feed.id !== newRefGroup.id);
		oldFeeds.push(newRefGroup);
		this.$groups.next(oldFeeds);
	}

	private deleteGroupInternally(group: FlutaroGroup) {
		let oldGroups = this.$groups.getValue().slice();
		oldGroups = oldGroups.filter((feed) => feed.id !== group.id);
		this.$groups.next(oldGroups);
	}
}
