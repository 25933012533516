<ion-item class="modalListItem">
	<div
		slot="start"
		fxLayout="row"
		fxLayoutAlign="start center"
		fxLayoutGap="10px"
		class="job-page-start-item-content-width"
	>
		<i class="material-icons">traffic</i>
		<ion-label translate>STATUS</ion-label>
	</div>
	<div fxLayout="row" fxLayoutAlign="end center" class="width100">
		<ion-note *ngIf="job.job.status === 4" translate>JOB_STATUS_RECEIVED</ion-note>
		<ion-note *ngIf="job.job.status === 5" translate>JOB_STATUS_ACCEPTED</ion-note>
		<ion-note *ngIf="job.job.status === 6" translate>JOB_STATUS_DECLINED</ion-note>
		<ion-note *ngIf="job.job.status === 7" translate>JOB_STATUS_STARTED</ion-note>
		<ion-note *ngIf="job.job.status === 8" translate>JOB_STATUS_DONE</ion-note>
	</div>
</ion-item>
