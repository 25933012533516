import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { AutoCompleteComponentService } from '../AutoCompleteComponentService';
import { TranslateService } from '@ngx-translate/core';
import { FlutaroLocation } from '../../post/post';
import { getCurrentLocation } from '../../../services/PositionService';
import { flutaroWait } from '@flutaro/package/lib/functions/AppJsHelperFunctions';

export const MAP_ZOOM_LVL: number = 10;
export const MAP_OPTIONS = {
	zoom: MAP_ZOOM_LVL,
	mapTypeId: google.maps.MapTypeId.ROADMAP,
	disableDefaultUI: true,
};

@Component({
	selector: 'app-post-location',
	templateUrl: './post-location.component.html',
	styleUrls: ['./post-location.component.scss'],
})
export class PostLocationComponent implements OnChanges {
	@Input() location: FlutaroLocation;
	@Output() locationChange: EventEmitter<FlutaroLocation> = new EventEmitter<FlutaroLocation>();
	@Input() isRating: boolean;
	@Input() readOnly: boolean;

	@ViewChild('map', {}) mapElement: ElementRef;
	map: google.maps.Map;
	GooglePlaces: google.maps.places.PlacesService;
	locationsSearchList: google.maps.places.AutocompletePrediction[] = [];

	constructor(public autocompleteService: AutoCompleteComponentService, private translate: TranslateService) {}

	async ngOnChanges(changes) {
		if (this.location && this.location.lat) {
			//this.loadMapCalled = true;
			await flutaroWait(10);
			this.loadPosition();
		}
	}

	async activateSearchQuery(search: string) {
		this.locationsSearchList = await this.autocompleteService.getResults(search);
	}

	setGLocation(gLocation: google.maps.places.AutocompletePrediction) {
		this.location = new FlutaroLocation();
		this.location.description = gLocation.description;
		this.location.place_id = gLocation.place_id;
		this.map = new google.maps.Map(this.mapElement.nativeElement, MAP_OPTIONS);
		this.GooglePlaces = new google.maps.places.PlacesService(this.mapElement.nativeElement);
		this.GooglePlaces.getDetails({ placeId: gLocation.place_id, fields: ['geometry.location'] }, (result) => {
			let latLng = new google.maps.LatLng(result.geometry.location.lat(), result.geometry.location.lng());
			this.location.lat = latLng.lat();
			this.location.lon = latLng.lng();
			this.locationChange.emit(this.location);
			this.loadMap(latLng, false);
		});
	}

	loadMap(latLng, isCurrentPosition: boolean) {
		let mapOptions = {
			center: latLng,
			zoom: MAP_ZOOM_LVL,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			disableDefaultUI: true,
		};

		this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
		this.GooglePlaces = new google.maps.places.PlacesService(this.map);
		if (isCurrentPosition) {
			this.getAddressFromCoords(latLng, this.map);
		} else {
			const marker = new google.maps.Marker({
				position: latLng,
				title: this.location.description,
				animation: google.maps.Animation.DROP,
			});
			marker.setMap(this.map);
			const infowindow = new google.maps.InfoWindow();
			infowindow.setContent(this.location.description);
			infowindow.open(this.map, marker);
		}
	}

	loadPosition() {
		let latLng = new google.maps.LatLng(this.location.lat, this.location.lon);
		// Trigger ChangeDetection cycle for *ngIf evaluation: Put with setTimeout the loadPosition()-Call on top of JS execution Stack
		this.loadMap(latLng, false);
	}

	async loadCurrentPosition() {
		const position = await getCurrentLocation();
		let latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
		this.loadMap(latLng, true);
	}

	getAddressFromCoords(latLng, map) {
		const geocoder = new google.maps.Geocoder();
		const infowindow = new google.maps.InfoWindow();
		const marker = new google.maps.Marker({
			position: latLng,
			title: this.translate.instant('CURRENT_POSITION'),
			animation: google.maps.Animation.DROP,
		});

		marker.setMap(this.map);
		geocoder.geocode({ location: latLng }, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					this.location = new FlutaroLocation();
					this.location.description = results[0].formatted_address;
					this.location.lat = latLng.lat();
					this.location.lon = latLng.lng();
					infowindow.setContent(this.location.description);
					infowindow.open(map, marker);
				} else {
					console.log('getAddressFromCoords, No results found');
				}
			} else {
				console.error('getAddressFromCoords, Geocoder failed due to: ' + status);
			}
		});
	}
}
