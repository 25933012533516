<div [hidden]="!isSearch" class="my-overlay ion-padding">
    <ion-list class="flutaroList">
        <ion-item (click)="emitSearchSelection(resultEntry)" *ngFor="let resultEntry of searchResultsList"
                  class="flutaroListItem"
                  lines="none">
            <div *ngIf="type === 'GOOGLE_ADDRESS_SEARCH'">
                <h2 class="flutaroListItemChannelName">{{resultEntry.description}}</h2>
            </div>
            <div *ngIf="type !== 'GOOGLE_ADDRESS_SEARCH'" class="innerDiv">
                <app-avatar [authorId]="resultEntry.uid" [authorPhoto]="resultEntry['photoURL']"
                            [user]="userProfile">
                </app-avatar>
                <h2 class="flutaroListItemChannelName">{{resultEntry[elementsNameIdentifier]}}</h2>
                <ion-icon class="flutaroListItemOpenIcon" item-end name="arrow-forward" small></ion-icon>
            </div>
        </ion-item>
    </ion-list>
</div>
