<ion-row class="listHeaderRow ion-align-items-center">
  <ion-col col-12>
    <p class="listHeaderText" translate>
      <ion-icon class="ionIconListHeader" name="newspaper-outline"></ion-icon>
      FEED
    </p>
  </ion-col>
</ion-row>
<ion-fab horizontal="end" slot="fixed" vertical="top">
  <ion-fab-button (click)="feedProvider.addEditFeedItem(null)" color="flutaroAccentButton">
    <ion-icon name="add"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content *ngIf="!(deviceService.$isOnline | async)">
  <div class="deviceOffline">
    <div class="deviceOfflineInner">
      <span class="deviceOfflineIcon"></span> <!-- this is the base64 icon - is offline available-->
      <p translate>DEVICE_OFFLINE_INFO</p>
    </div>
  </div>
</ion-content>

<ion-content *ngIf="deviceService.$isOnline | async" class="feedContent">
  <flutaro-autocomplete
    (activateSearchQuery)="searchUsers($event)"
    (resultChange)="userFromSearchClicked($event)"
    [placeholder]="'SEARCH_FOR' | translate"
    [searchIdentifierAttribute]="'nickname'"
    [searchResultsList]="userSearchList"
    [type]="'USER_SEARCH'"
    [userProfile]="userProvider.$userProfile | async">
  </flutaro-autocomplete>

  <app-loading *ngIf="timelineProvider.isLoading | async"></app-loading>

  <ion-segment (ionChange)="timeLineSegmentChanged($event)" [(ngModel)]="selectedTimeLineSegment">
    <ion-segment-button value="timeline">
      <ion-label translate>TIMELINE</ion-label>
    </ion-segment-button>
    <ion-segment-button value="common_timeline">
      <ion-label translate>FLUTARO_COMMON_TIMELINE</ion-label>
    </ion-segment-button>
  </ion-segment>

  <div *ngFor="let post of timelineProvider.$timeline | async | appOrderBy: ['-lastModified']"
       [ngClass]="{
         'postFeed': post.type === 'POST',
         'locationRatingFeed': post.type === 'RATING',
         'webPagePreview': (post.type === 'POST' || post.type === 'COMMENT') && post.pagePreview
         }">
    <app-post [commentDepth]="1"
              [post]="post"
              [readonlyUid]="post.authorId"
              [user]="userProvider.$userProfile | async">
    </app-post>
  </div>

  <ion-refresher (ionRefresh)="refreshFeed($event)" slot="fixed">
    <ion-refresher-content
      [pullingIcon]="'arrow-down-circle-outline'"
      [pullingText]="'PULL_FOR_REFRESH' | translate"
      [refreshingSpinner]="'circles'"
      [refreshingText]="'LOADING_DATA' | translate">
    </ion-refresher-content>
  </ion-refresher>
</ion-content>
