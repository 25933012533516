<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ion-icon name="car-outline"></ion-icon>
            {{'MY_VEHICLE' | translate}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ion-item *ngIf="!readonly || vehicleInformation.type" [color]="'color64666EVehicle'" lines="none">
        <ion-icon name="bookmark-outline"></ion-icon>
        <ion-label translate>VEHICLE_IDENTIFIER</ion-label>
        <ion-input [(ngModel)]="vehicleInformation.type"></ion-input>
    </ion-item>
    <ion-item *ngIf="!readonly || vehicleInformation.vehicleUserSinceDate" [color]="'color64666EVehicle'" lines="none">
        <ion-icon name="calendar-outline"></ion-icon>
        <ion-label translate>DRIVER_SINCE_DATE</ion-label>
        <ion-datetime [(ngModel)]="vehicleInformation.vehicleUserSinceDate"></ion-datetime>
    </ion-item>
    <ion-item *ngIf="!readonly || vehicleInformation.description" [color]="'color64666EVehicle'" lines="none">
        <ion-icon name="thumbs-up-outline"></ion-icon>
        <ion-label translate>ABOUT_MY_CAR</ion-label>
    </ion-item>
    <ion-item *ngIf="!readonly || vehicleInformation.description" [color]="'color64666EVehicle'" lines="none">
        <ion-textarea class="vehicleInformationDescription" [(ngModel)]="vehicleInformation.description"
                      [autoGrow]="true">
        </ion-textarea>
    </ion-item>

    <app-picture [title]="'VEHICLE_PHOTO' | translate" [uid]="userUid"
                 [(photo)]="vehicleInformation.photo"
                 [pictureType]="'PROFILE'"
                 *ngIf="!readonly || vehicleInformation.photo">
    </app-picture>
</mat-expansion-panel>
