<ion-item [color]="'color64666E'" style="font-size: 0.8em;" lines="none">
    <app-avatar [user]="user" [authorId]="post.authorId" [authorPhoto]="post.authorPhoto"></app-avatar>
    <ion-label class="postAuthorAndTime">
        <h2>{{post.authorName}}</h2>
        <p>{{post.timestamp | relativeTime}}</p>
    </ion-label>

    <ion-button *ngIf="!isReadOnly" class="flutaroMoreEditComment" (click)="presentActionSheet()">
        <ion-icon name="ellipsis-horizontal"></ion-icon>
    </ion-button>
</ion-item>
