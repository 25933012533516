<ion-header>
  <ion-toolbar>
    <ion-title translate>REGISTRATION</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form (ngSubmit)="signup()" [formGroup]="form">
    <ion-list inset>
      <ion-item class="formItem">
        <ion-input formControlName="email" placeholder="Email" type="text"></ion-input>
      </ion-item>
      <div class="validation-errors">
        <ng-container *ngFor="let validation of validationMessages.email">
          <div
            *ngIf="form.get('email').hasError(validation.type) && (form.get('email').dirty || form.get('email').touched)"
            class="error-message">
            {{ validation.message }}
          </div>
        </ng-container>
      </div>

      <ion-item class="formItem">
        <ion-input formControlName="password" placeholder="Passwort" type="password"></ion-input>
      </ion-item>
      <div class="validation-errors">
        <ng-container *ngFor="let validation of validationMessages.password">
          <div
            *ngIf="form.get('password').hasError(validation.type) && (form.get('password').dirty || form.get('password').touched)"
            class="error-message">
            {{ validation.message }}
          </div>
        </ng-container>
      </div>

      <ion-item class="formItem">
        <ion-input [placeholder]="'PROFILE_NICKNAME' | translate" formControlName="displayName"
                   type="text">
        </ion-input>
      </ion-item>
      <div class="validation-errors">
        <ng-container *ngFor="let validation of validationMessages.displayName">
          <div
            *ngIf="form.get('displayName').hasError(validation.type) && (form.get('displayName').dirty || form.get('displayName').touched)"
            class="error-message">
            {{ validation.message }}
          </div>
        </ng-container>
      </div>

    </ion-list>

    <p class="acceptTerms">{{'WITH_REGISTRATION_ACCEPTED_DOCUMENTS' | translate}}
      <a href="https://app.flutaro.de/assets/terms-and-conditions/index.html"
         target="_">{{'TERMS_AND_CONDITIONS'| translate}}</a>
      {{'AND_THE' | translate}}
      <a href="https://www.flutaro.de/privacy/" target="_">{{'PRIVACY_POLICY' | translate}}</a>
      {{'ACCEPT_TO' | translate}}.</p>
    <div class="ion-padding-horizontal">
      <div class="form-error">{{signupError}}</div>

      <button [disabled]="!form.valid"
              class="disable-hover button button-md button-default button-default-md button-full button-full-md button-md-flutaroMain"
              color="flutaroMain" full icon-end ion-button
              type="submit">
        {{'REGISTER' | translate}}
        <ion-icon name="log-in"></ion-icon>
      </button>
    </div>
  </form>
</ion-content>
