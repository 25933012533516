import { Component, ViewEncapsulation } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { JobPageComponent } from '../pages/jobs/job-page.component';
import { JobService } from '../pages/jobs/job.service';
import { JobsFilterService } from '../pages/jobs/jobs-filter.service';
import { JobStatus, JobWrapper } from '@flutaro/package/lib/model/Job';
import { Router } from '@angular/router';
import { AppDeviceService } from '../app-device.service';
import { UserProfileProvider } from '../user/user.profile.provider';
import { filterJobsForStatus } from '@flutaro/package/lib/functions/FlutaroJobFunctions';

@Component({
	selector: 'page-job',
	templateUrl: './job.component.html',
	styleUrls: ['./job.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class JobComponent {
	todaysDate = new Date();
	jobStatus = JobStatus;
	showHiddenJobs = false;

	constructor(
		public plt: Platform,
		public jobProvider: JobService,
		public jobsFilter: JobsFilterService,
		public deviceProvider: AppDeviceService,
		public modalCtrl: ModalController,
		public router: Router,
		public userProvider: UserProfileProvider,
	) {
		this.updateOnChanges();
	}

	acceptOrder(jobWrapper: JobWrapper) {
		jobWrapper.job.status = JobStatus.ACCEPTED;
		this.jobProvider.updateJobStatus(jobWrapper);
		const newJobs = filterJobsForStatus(this.jobProvider.$data.getValue(), JobStatus.RECEIVED);
		if (this.jobsFilter.lastFilter && this.jobsFilter.lastFilter.filterType === 'new' && !newJobs.length) {
			this.disableJogPagePushView();
			this.jobsFilter.resetFilter(); // Dont trigger ionWillLeave twice
		}
	}

	updateOnChanges() {
		this.jobProvider.$data.subscribe((newData) => {
			this.jobsFilter.filterData();
		});
	}

	// Reset Filters on Leave - especially if activated from home dashboard and back-button is activated
	ionViewWillLeave() {
		if (this.jobsFilter.lastFilter.filterType) {
			this.disableJogPagePushView();
		}
		if (this.jobsFilter.lastFilter.divisionType) {
			this.jobsFilter.jobPagePushViewIsOpen = false;
		}
		this.jobsFilter.resetFilter();
	}

	async openJobModal(jobWrapper: JobWrapper) {
		let modal = await this.modalCtrl.create({
			component: JobPageComponent,
			componentProps: {
				jobWrapper: jobWrapper,
			},
		});
		modal.present();
	}

	private disableJogPagePushView() {
		this.jobsFilter.jobPagePushViewIsOpen = false;
	}
}
