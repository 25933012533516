import { SocialElementBasics } from '../post/post';

export function setDatesOnPostingCreate(post: SocialElementBasics) {
	post.timestamp = new Date();
	post.lastModified = new Date();
}

export function setDatesOnPostingEdit(post: SocialElementBasics) {
	post.lastModified = new Date();
}
