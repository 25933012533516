import { ChangeDetectorRef, Component } from '@angular/core';

import { LoadingController, MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { PushMessagesService } from './services/push/push-messages.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppDeviceService } from './app-device.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserProfileProvider } from './user/user.profile.provider';
import { ActivitiesService } from './social/activities/activities.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './services/Analytics/analytics.service';
import { GeodataBackgroundService } from './services/geodata/geodata-background.service';
import { JobService } from './pages/jobs/job.service';
import { AppInternationalizationService } from './services/app.internationalization.service';
import { ChatService } from './services/chat.service';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { isAuthorizedAppUser } from '@flutaro/package/lib/functions/auth/AppAuthFunctions';
import appVersion from '../app/app.version.json';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	userIsInitialized: boolean = false; // First time a user logs in we initialize the App for the user, which only must happen once. Currently we dont react in the App for User changes like custom claims
	previousRoute = [];
	activitiesIsOpen: boolean = false;
	activitiesOriginViewRef: string;

	constructor(
		private platform: Platform,
		public auth: AppAuthService,
		private push: PushMessagesService,
		public deviceProvider: AppDeviceService,
		public router: Router,
		public backgroundGeoProvider: GeodataBackgroundService,
		public userProfileProvider: UserProfileProvider,
		public menu: MenuController,
		public activities: ActivitiesService,
		public translate: TranslateService,
		private analytics: AnalyticsService,
		private jobProvider: JobService,
		private languageService: AppInternationalizationService,
		private chatService: ChatService,
		public loadingController: LoadingController,
		private cd: ChangeDetectorRef,
	) {
		this.initializeApp();
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.previousRoute.push(event.url);
			}
		});
		this.jobProvider.$isInRequestState.subscribe((isInRequestState) => {
			isInRequestState ? this.showIsInRequestingStateLoadingSpinner() : this.hideIsInRequestingStateLoadingSpinner();
		});
	}

	initializeApp() {
		this.setRootPageLogin();
		this.platform.ready().then(async () => {
			console.log(`Flutaro Communicator App ${appVersion.version} getting started to rock ... `);
			await this.deviceProvider.activateDeviceProvider();
			this.listenToUserChanges();
			this.platform.backButton.subscribeWithPriority(1, () => {
				if (this.router.url === '/home') {
					navigator['app'].exitApp();
				} else if (this.router.url === '/page-job') {
					this.router.navigate(['/home']);
				} else if (this.router.url === '/contact') {
					this.router.navigate(['/home']);
				} else {
					this.router.navigate([this.previousRoute[this.previousRoute.length - 2]]);
				}
			});
		});
	}

	logout() {
		this.menuClick();
		this.auth.signOutAndRedirectToHome();
		this.userProfileProvider.logoutUserProfile();
	}

	menuClick() {
		this.menu.close();
		this.closeActivities();
	}

	async showIsInRequestingStateLoadingSpinner() {
		const loading = await this.loadingController.create({
			spinner: undefined, // undefined = default for iOS / MD
			animated: true,
			message: 'Updating jobs status...',
			translucent: false,
			cssClass: 'flutaro-spinner',
			showBackdrop: true,
			backdropDismiss: false,
		});
		// additional check to not create already hidden overlay
		if (!this.jobProvider.$isInRequestState.getValue()) return;
		await loading.present();
	}

	async hideIsInRequestingStateLoadingSpinner() {
		const [noOverlay, currentOverlay] = await to(this.loadingController.getTop());
		if (noOverlay || !currentOverlay) return;
		return await this.loadingController.dismiss();
	}

	toggleActivities() {
		this.activitiesIsOpen = !this.activitiesIsOpen;
		if (this.activitiesIsOpen) {
			this.activitiesOriginViewRef = this.router.url;
			this.router.navigate(['/activities']);
		} else {
			this.router.navigate([this.activitiesOriginViewRef]);
			this.activitiesOriginViewRef = null;
		}
	}

	private async listenToUserChanges() {
		this.auth.$user.subscribe(async (user) => {
			console.log(`listenToUserChanges, received user: ${user?.email}`);
			if (!user) {
				this.router.navigate(['/page-login']);
				return;
			}
			if (!user.emailVerified) {
				this.router.navigate(['/page-login-error']);
				return;
			}
			if (this.userIsInitialized) {
				console.log(`listenToUserChanges, already true userIsInitialized - stopping`);
				return;
			}
			this.userIsInitialized = true;
			this.analytics.activateAnalyticsForUser(user);
			const userProfile = await this.userProfileProvider.getAndSetUserProfile(user);
			this.initAppForUser(userProfile);
		});
	}

	private async initAppForUser(profile: FbStoreUserProfile) {
		console.log(`initAppForUser starting`);
		this.activateSocialFeatures(profile);
		await this.activateDeviceAppFeatures(profile);
		if (!isAuthorizedAppUser(profile)) {
			this.auth.setUserAuthToSocialAppUserOnly();
			this.router.navigate(['/feed']);
		} else {
			this.auth.setUserAuthToEnterprise();
			this.activateAppDispatchingFeatures(profile);
			this.router.navigate(['/home']);
		}
	}

	private activateSocialFeatures(profile: FbStoreUserProfile) {
		this.activities.activateActivityService(profile.uid);
	}

	private async activateDeviceAppFeatures(profile: FbStoreUserProfile): Promise<boolean> {
		this.setAppLanguage(profile);
		//await this.analytics.activateAnalyticsForUser(profile);
		if (this.deviceProvider.isWeb) return true;
		await this.push.activatePushNative();
		return true;
	}

	private setAppLanguage(profile: FbStoreUserProfile) {
		if (!profile.appLanguage) return;
		console.log(`Changing App-Language to ${profile.appLanguage} due to User Setting:`);
		this.languageService.changeAppLanguage(profile.appLanguage);
	}

	private activateAppDispatchingFeatures(profile: FbStoreUserProfile) {
		this.jobProvider.activateJobsHotListener(profile);
		this.chatService.listenToUsersChannels(profile);
		if (!this.deviceProvider.isNativeApp) return;
		this.backgroundGeoProvider.$isFullBackgroundLocationServiceAuthorized.subscribe(
			($isFullBackgroundLocationServiceAuthorized) => this.cd.detectChanges(),
		);
		this.backgroundGeoProvider.configureAndActivateBackgroundPlugin(profile);
	}

	private setRootPageLogin() {
		this.router.navigate(['/page-login']);
		this.removeSplash();
	}

	private removeSplash() {
		const splash = document.getElementById('splash-screen');
		if (splash) {
			splash.remove();
		}
		SplashScreen.hide();
	}

	private closeActivities() {
		this.activitiesIsOpen = false;
		this.activitiesOriginViewRef = null;
	}
}
