<ion-row class="listHeaderRow ion-align-items-center">
	<ion-col col-12>
		<p class="listHeaderText" translate>
			<ion-icon class="ionIconListHeader" name="person"></ion-icon>
			PROFILE
		</p>
	</ion-col>
</ion-row>

<ion-content class="ion-padding">
	<app-loading *ngIf="($loading | async) || !userProfile"></app-loading>

	<div *ngIf="($loading | async) === false && userProfile">
		<div>
			<ion-item [color]="'color64666EProfile'" lines="none">
				<ion-icon name="bookmark-outline"></ion-icon>
				<ion-label translate>PROFILE_USERNAME</ion-label>
				<ion-input [color]="'colorFFFProfileText'" [(ngModel)]="userProfile.displayName"></ion-input>
			</ion-item>

			<app-picture
				[(photo)]="userProfile.photoURL"
				[itemColor]="'color64666EProfile'"
				[title]="'PROFILE_PHOTO' | translate"
				[pictureType]="'PROFILE'"
				[uid]="userProfile.uid"
			>
			</app-picture>

			<ion-item [color]="'color64666EProfile'" lines="none">
				<ion-icon name="person-outline"></ion-icon>
				<ion-label translate>PROFILE_NICKNAME</ion-label>
				<ion-input [color]="'colorFFFProfileText'" [(ngModel)]="userProfile.displayName"></ion-input>
			</ion-item>

			<ion-item [color]="'color64666EProfile'" lines="none">
				<ion-icon name="speedometer-outline"></ion-icon>
				<ion-label translate>PROFILE_STATUS</ion-label>
				<ion-input [color]="'colorFFFProfileText'" [(ngModel)]="userProfile.status"></ion-input>
			</ion-item>

			<ion-item [color]="'color64666EProfile'" lines="none">
				<ion-icon name="chatbubbles-outline"></ion-icon>
				<ion-label translate>PROFILE_LANGUAGE</ion-label>
				<ion-select multiple="true" [(ngModel)]="userProfile.languages">
					<ion-select-option translate value="albanian">PROFILE_LANGUAGE_ALB</ion-select-option>
					<ion-select-option value="german" translate>PROFILE_LANGUAGE_GER</ion-select-option>
					<ion-select-option value="english" translate>PROFILE_LANGUAGE_ENG</ion-select-option>
					<ion-select-option translate value="polish">PROFILE_LANGUAGE_POL</ion-select-option>
					<ion-select-option translate value="romanian">PROFILE_LANGUAGE_ROM</ion-select-option>
					<ion-select-option translate value="russian">PROFILE_LANGUAGE_RUS</ion-select-option>
					<ion-select-option translate value="czech">PROFILE_LANGUAGE_CZE</ion-select-option>
				</ion-select>
			</ion-item>

			<ion-item [color]="'color64666EProfile'" lines="none">
				<ion-icon name="pricetag-outline"></ion-icon>
				<ion-label translate>LICENSEPLATE</ion-label>
				<ion-input [color]="'colorFFFProfileText'" [(ngModel)]="userProfile.licensePlate"></ion-input>
			</ion-item>

			<app-user-vehicle [(vehicleInformation)]="userProfile.vehicleInformation" [userUid]="userProfile.uid">
			</app-user-vehicle>
		</div>

		<ion-button (click)="updateDetails()" [color]="'flutaroMain'" color="success" expand="block" fill="solid" translate
			>SAVE
		</ion-button>

		<ion-label class="appSettings" translate>PROFILE_APP_SETTINGS_SECTION</ion-label>

		<ion-item [color]="'color64666EProfile'" lines="none">
			<ion-icon name="chatbubbles-outline"></ion-icon>
			<ion-label translate>APP_LANGUAGE</ion-label>
			<ion-select (ngModelChange)="changeAppLanguage($event)" [(ngModel)]="userProfile.appLanguage" multiple="false">
				<ion-select-option translate value="ger">PROFILE_LANGUAGE_GER</ion-select-option>
				<ion-select-option translate value="en">PROFILE_LANGUAGE_ENG</ion-select-option>
				<ion-select-option translate value="pol">PROFILE_LANGUAGE_POL</ion-select-option>
				<ion-select-option translate value="rom">PROFILE_LANGUAGE_ROM</ion-select-option>
				<ion-select-option translate value="rus">PROFILE_LANGUAGE_RUS</ion-select-option>
			</ion-select>
		</ion-item>

		<div *ngIf="userProfile.roles.length && deviceProvider.isNativeApp">
			<ion-item
				*ngIf="deviceProvider.isAndroid"
				[color]="'color64666EProfile'"
				lines="none"
				(click)="openAndroidBatteryOptimizationsSettings()"
			>
				<ion-icon name="battery-charging-outline"></ion-icon>
				<ion-label translate>GPS_DEVICE_BATTERY_OPTIMIZATION_DEACTIVATED</ion-label>
				<ion-toggle [checked]="deviceIsActivatedBatteryOptimization"></ion-toggle>
			</ion-item>
			<ion-item [color]="'color64666EProfile'" lines="none">
				<ion-icon name="navigate-circle-outline"></ion-icon>
				<ion-label translate>GPS_DEVICE_LOCATION_ENABLED</ion-label>
				<ion-toggle [checked]="deviceLocationServicesEnabled" disabled></ion-toggle>
			</ion-item>
		</div>
		<ion-item [color]="'color64666EProfile'" lines="none">
			<ion-icon name="key-outline"></ion-icon>
			<ion-label translate>PROFILE_PASSWORD_CHANGE</ion-label>
			<ion-checkbox [color]="'colorFFFProfileText'" [(ngModel)]="changePassword"></ion-checkbox>
		</ion-item>

		<div *ngIf="changePassword">
			<ion-item [color]="'color64666EProfile'" lines="none">
				<ion-icon name="key-outline"></ion-icon>
				<ion-label translate>PROFILE_PASSWORD_NEW</ion-label>
				<ion-input [color]="'colorFFFProfileText'" [(ngModel)]="newPassword"></ion-input>
			</ion-item>

			<ion-item [color]="'color64666EProfile'" lines="none">
				<ion-icon name="key-outline"></ion-icon>
				<ion-label translate>PROFILE_PASSWORD_ACTUAL</ion-label>
				<ion-input [color]="'colorFFFProfileText'" [(ngModel)]="password"></ion-input>
			</ion-item>

			<ion-button
				(click)="storePasswordChange()"
				[color]="'flutaroMain'"
				color="danger"
				expand="block"
				fill="solid"
				translate
			>
				PROFILE_PASSWORD_SAVE
			</ion-button>
		</div>
	</div>

	<ion-button (click)="auth.signOutAndRedirectToHome()" [color]="'google'" color="danger" expand="block" fill="solid"
		>Logout</ion-button
	>
</ion-content>
