<app-post-location [(location)]="ratingCopy.location" [readOnly]="readOnly" [isRating]="true"></app-post-location>

<div class="commentDiv">
	<ion-grid>
		<ion-row>
			<ion-item [color]="'colorTransparent'" lines="none" class="commentDivItem">
				<ion-icon name="text"></ion-icon>
				<ion-label [color]="'colorfff'" translate>COMMENT</ion-label>
			</ion-item>
		</ion-row>
		<ion-row>
			<ion-textarea
				class="locationCommentTextarea"
				[placeholder]="'RATING_COMMENT' | translate"
				[(ngModel)]="ratingCopy.content"
				[autoGrow]="true"
				[spellcheck]="true"
				[readonly]="readOnly"
				[color]="'colorCommentTextarea'"
			>
			</ion-textarea>
		</ion-row>
	</ion-grid>
</div>

<ion-item [color]="'color64666EProfile'" lines="none">
	<ion-icon name="star-outline"></ion-icon>
	<ion-label [color]="'colorfff'" translate>LOCATION_RATING_TOTAL_EVALUATION</ion-label>

	<flutaro-star-rating
		(ratingChange)="changeRating($event)"
		[(rating)]="ratingCopy.rating.rating"
		[readonly]="readOnly"
	>
	</flutaro-star-rating>
</ion-item>

<ion-item [color]="'color64666EProfile'" lines="none">
	<ion-icon name="thumbs-up-outline"></ion-icon>
	<ion-label [color]="'colorfff'" translate>LOCATION_RATING_QUALITY</ion-label>

	<flutaro-star-rating
		(ratingChange)="changeQualityRating($event)"
		[(rating)]="ratingCopy.rating.quality"
		[readonly]="readOnly"
	>
	</flutaro-star-rating>
</ion-item>

<ion-item [color]="'color64666EProfile'" lines="none">
	<ion-icon name="lock-closed-outline"></ion-icon>
	<ion-label [color]="'colorfff'" translate>LOCATION_RATING_SAFETY</ion-label>

	<flutaro-star-rating
		(ratingChange)="changeSafetyRating($event)"
		[(rating)]="ratingCopy.rating.safety"
		[readonly]="readOnly"
	>
	</flutaro-star-rating>
</ion-item>

<ion-item [color]="'color64666EProfile'" lines="none">
	<ion-icon name="water-outline"></ion-icon>
	<ion-label translate>LOCATION_RATING_SHOWER</ion-label>
	<ion-checkbox slot="end" [(ngModel)]="ratingCopy.rating.hasShowers" [disabled]="readOnly"></ion-checkbox>
</ion-item>

<ion-item [color]="'color64666EProfile'" lines="none">
	<ion-icon name="flame-outline"></ion-icon>
	<ion-label translate>LOCATION_RATING_BBQ</ion-label>
	<ion-checkbox slot="end" [(ngModel)]="ratingCopy.rating.hasBBQ" [disabled]="readOnly"></ion-checkbox>
</ion-item>
