<ion-row class="listHeaderRow ion-align-items-center">
  <ion-col col-12>
    <p class="listHeaderText">
      <ion-icon class="ionIconListHeader" name="chatbubbles"></ion-icon>
      {{'CONTACT' | translate}}
    </p>
  </ion-col>
</ion-row>
<ion-content class="ion-padding">
  <ion-list class="flutaroList">
    <ion-item (click)="setChannel(channel)"
              *ngFor="let channel of chatProvider.$channels | async"
              class="flutaroListItem">
      <ion-avatar class="flutaroListItemLogo" item-left>
        <img src="assets/imgs/Flutaro_Logo_only.svg">
      </ion-avatar>
      <h2
        class="flutaroListItemChannelName">{{channel.id === userProfileProvider.$userProfile.getValue().uid ? 'Dispo-Chat' : channel.name}}</h2>
      <ion-icon class="flutaroListItemOpenIcon" item-end name="arrow-forward" small></ion-icon>
      <span *ngIf="channel.newMessageForAppUser" class="flutaroListItemUnreadMessage"></span>
    </ion-item>
  </ion-list>
</ion-content>
