<ion-row class="listHeaderRow ion-align-items-center">
  <ion-col col-12>
    <p class="listHeaderText" translate>
      <ion-icon class="ionIconListHeader" name="people-outline"></ion-icon>
      {{'GROUPS' | translate}}
    </p>
  </ion-col>
</ion-row>
<ion-fab horizontal="end" slot="fixed" vertical="top">
  <ion-fab-button (click)="addGroup()" [disabled]="!(deviceService.$isOnline | async)" color="flutaroAccentButton">
    <ion-icon name="add"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content *ngIf="!(deviceService.$isOnline | async)">
  <div class="deviceOffline">
    <div class="deviceOfflineInner">
      <span class="deviceOfflineIcon"></span> <!-- this is the base64 icon - is offline available-->
      <p translate>DEVICE_OFFLINE_INFO</p>
    </div>
  </div>
</ion-content>

<ion-content *ngIf="deviceService.$isOnline | async" class="locationContent">
  <flutaro-autocomplete
    (activateSearchQuery)="searchUsers($event)"
    (resultChange)="groupFromSearchClicked($event)"
    [placeholder]="'SEARCH_FOR' | translate"
    [searchIdentifierAttribute]="'title'"
    [searchResultsList]="groupSearchList"
    [type]="'GROUP_SEARCH'"
    [userProfile]="userProvider.$userProfile | async">
  </flutaro-autocomplete>

  <!-- same design like contact-page -->
  <ion-list class="flutaroList">
    <ion-item (click)="openGroup(group)"
              *ngFor="let group of groupsProvider.$groups | async | appOrderBy: ['-lastModified']"
              class="flutaroListItem">

      <ion-avatar class="flutaroListItemLogo" item-left>
        <ion-img [src]="group.photoURL" class="group-photo-icon ionicon"></ion-img>
      </ion-avatar>

      <h2 class="flutaroListItemChannelName">{{group.title}}</h2>

      <ion-icon class="flutaroListItemOpenIcon" item-end name="arrow-forward" small></ion-icon>
      <!-- TODO: unread message?  -->
      <!-- <span class="flutaroListItemUnreadMessage" *ngIf="channel.newMessageForAppUser"></span> -->
    </ion-item>
  </ion-list>

</ion-content>
