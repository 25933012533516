import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AnalyticsService } from '../services/Analytics/analytics.service';
import { prepareUserProfileUpdateBeforeFirebaseStorage } from '../services/FirestoreFunctions';
import { ProfilePublicService } from '../social/profile/profile.public.service';
import { BehaviorSubject } from 'rxjs';
import { FbStoreUserProfile, FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { isChangedAndMigratedAppUserDoc } from '@flutaro/package/lib/functions/db/UserProfileFunctions';
import { User } from '@angular/fire/auth';

@Injectable({
	providedIn: 'root',
})
export class UserProfileProvider {
	$userProfile: BehaviorSubject<FbStoreUserProfile> = new BehaviorSubject<FbStoreUserProfile>(null);

	constructor(
		private db: AngularFirestore,
		private analytics: AnalyticsService,
		private profileProvider: ProfilePublicService,
	) {}

	editUserInFBDB(userProfile: FbStoreUserProfile) {
		return this.db
			.collection('users')
			.doc(userProfile.uid)
			.update(prepareUserProfileUpdateBeforeFirebaseStorage(userProfile))
			.finally(() => this.setUserProfile(userProfile));
	}

	updateFCMTokenInUserProfile(token: string) {
		const user = this.$userProfile.getValue();
		if (!user?.uid) return;

		const currentTokens = user.fcmTokens || {};
		if (currentTokens[token]) return;
		const userRef = this.db.collection('users').doc(user.uid);
		const tokens = { ...currentTokens, [token]: true };
		userRef.update({ fcmTokens: tokens });
	}

	async getAndSetUserProfile(user: User): Promise<FbStoreUserProfile> {
		const doc = await this.db.collection<FbStoreUserProfile>('users').doc(user.uid).get().toPromise();
		console.debug(`getUserFromDB, received User-Doc for User ${user.uid}`);
		const userProfile: FlutaroUserProfile = <any>doc.data();
		const isMigration = isChangedAndMigratedAppUserDoc(userProfile, user);
		// TODO: delete me after some months after Dec2021
		if (isMigration) {
			this.editUserInFBDB(userProfile);
			this.profileProvider.updatePublicProfile(userProfile);
		}
		this.setUserProfile(userProfile);
		return userProfile;
	}

	logoutUserProfile() {
		this.setUserProfile(null);
	}

	private setUserProfile(userProfile: FbStoreUserProfile) {
		this.$userProfile.next(userProfile);
	}
}
