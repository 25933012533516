import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HomePage } from './pages/home/home.page';
import { AppContactPageComponent } from './pages/contact/app-contact-page.component';
import { AppChatPageComponent } from './pages/chat/app-chat-page.component';
import { UserProfileEditComponent } from './edit-profile/user-profile-edit.component';
import { JobComponent } from './jobs/job.component';
import { LocationPage } from './social/location/location.page';
import { FeedPage } from './social/feed/feed.page';
import { AppLoginPageComponent } from './pages/login/login.page';
import { AppLoginErrorComponent } from './auth/auth-error.page';
import { ActivitiesListComponent } from './social/activities/activities-list/activities-list.component';
import { AboutPage } from './pages/about/about.component';
import { GroupsPageComponent } from './social/groups/groups-page/groups-page.component';
import { EventsPageComponent } from './social/events/events-page/events-page.component';

const routes: Routes = [
	{ path: 'login', component: AppLoginPageComponent },
	{ path: 'login-error', component: AppLoginErrorComponent },
	{
		path: '',
		component: HomePage,
	},
	{
		path: 'home',
		component: HomePage,
	},
	{
		path: 'activities',
		component: ActivitiesListComponent,
	},
	{
		path: 'page-job',
		component: JobComponent,
	},
	{
		path: 'contact',
		children: [
			{
				path: '',
				component: AppContactPageComponent,
			},
			{
				path: 'page-chat',
				component: AppChatPageComponent,
			},
		],
	},
	{ path: 'feed', component: FeedPage },
	{
		path: 'ratings',
		component: LocationPage,
	},
	{ path: 'profile', component: UserProfileEditComponent },
	{ path: 'about', component: AboutPage },
	{ path: 'logout', component: AppLoginPageComponent },
	{ path: 'groups', component: GroupsPageComponent },
	{ path: 'events', component: EventsPageComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
	providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule {}
