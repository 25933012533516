<ion-app>
	<!-- <ion-slides>

      <ion-slide>
          <img src="./../assets/imgs/slides/slide-1.png"/>
          <h2>Willkommen zur neuen <b>Community Version</b> der Flutaro App</h2>
          <p>Die <b>Flutaro Community App</b> ist ein praktischer Begleiter während deiner vielen Fahrten und will dir
              helfen
              dich besser mit deinen Kollegen austauschen oder verabreden zu können.

              Denn gemeinsam macht alles mehr Spaß! :)
          </p>
      </ion-slide>

      <ion-slide>
          <img src="./../assets/imgs/slides/slide-2.png"/>
          <h2>Warum geht es bei der Flutaro Community App?</h2>
          <p><b>Die Flutaro Community App </b> holt dich genau dort ab, wo du während deiner Fahrt bist:
              Sei es qualitative und sichere Raststätten auf deiner Route zu finden, dich mit Kollegen, Freunden oder
              neuen
              Freunden nach dem Schichtende einfach zu verabredet oder auch um einfach mal seine Meinung teilen zu
              können.</p>
      </ion-slide>

      <ion-slide>
          <img src="./../assets/imgs/slides/slide-3.png"/>
          <h2>Warum noch eine Social App?</h2>
          <p><b>Wir sind davon überzeugt</b>, dass die großen amerikanischen Hersteller enorm gute Apps für die
              Kommunikation entwickelt haben.
              Wir wollen aber nur ganz genau einem Benutzerkreis helfen: dir, dem Fahrer.
              Und darum geht es: Sinnvolle Funktionen für den Fahreralltag, die einem Nicht-Fahrer auch nichts nützen.
          </p>
      </ion-slide>

      <ion-slide>
          <img src="./../assets/imgs/slides/slide-4.png"/>
          <h2>Lust loszulegen?</h2>
          <ion-button fill="clear">Continue
              <ion-icon slot="end" name="arrow-forward"></ion-icon>
          </ion-button>
      </ion-slide>

  </ion-slides> -->

	<ion-header class="flutaroHeader">
		<ion-toolbar class="appToolbarMain">
			<ion-buttons *ngIf="userProfileProvider.$userProfile | async" class="activityButton" slot="start">
				<ion-button (click)="toggleActivities()">
					<ion-icon name="notifications-outline"></ion-icon>
					<span *ngIf="activities.unreadActivities > 0" class="notifications-badge">
						{{ activities.unreadActivities }}
					</span>
				</ion-button>
			</ion-buttons>
			<ion-buttons *ngIf="userProfileProvider.$userProfile | async" slot="primary">
				<ion-menu-button></ion-menu-button>
			</ion-buttons>

			<img
				*ngIf="userProfileProvider.$userProfile.getValue()?.company !== 'BLG_Productive'"
				alt="logo"
				class="flutaroLogoNavbar"
				height="40"
				src="assets/imgs/Flutaro_Logo_white.svg"
			/>
			<img
				*ngIf="userProfileProvider.$userProfile.getValue()?.company === 'BLG_Productive'"
				alt="logo"
				class="flutaroLogoNavbar"
				height="40"
				src="assets/imgs/Flutaro_BLG_Logo_white.svg"
			/>
		</ion-toolbar>

		<ion-content *ngIf="(auth.$userIsEnterpriseUser | async) &&
						(backgroundGeoProvider.$isFullBackgroundLocationServiceAuthorized | async) === false &&
						deviceProvider.isNativeApp"
					class="warningContentDiv">
			<div (click)="backgroundGeoProvider.requestAndUpdateLocationPermissionState()" class="GPSWarning" translate>
				<span class="offlineWarningIconGPS"></span>
				<!-- this is the base64 icon - is offline available-->
				NO_GPS_ACTIVATED
			</div>
		</ion-content>

		<ion-content *ngIf="(deviceProvider.$isOnline | async) === false" 
					class="warningContentDiv">
			<div class="offlineWarning" translate>
				<span class="offlineWarningIconConnection"></span>
				<!-- this is the base64 icon - is offline available-->
				CONNECTION_STATUS
			</div>
		</ion-content>
	</ion-header>

	<ion-menu
		*ngIf="userProfileProvider.$userProfile | async"
		contentId="mainContent"
		id="slidingMenu"
		menuId="slidingMenu"
		side="end"
	>
		<ion-header>
			<ion-toolbar class="sliderMenu">
				<img alt="logo" class="flutaroLogoSliderMenu" height="40" src="assets/imgs/Flutaro_Logo_only.svg" />
				<ion-buttons slot="primary">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="flutaroMainSliderContent">
			<ion-list class="flutaroMainSliderList">
				<ion-item
					(click)="menuClick()"
					[ngClass]="!auth.$userIsEnterpriseUser.getValue() ? 'disabledItem' : ''"
					[routerLink]="'home'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/home.svg"></ion-icon>
					<ion-label translate>HOME</ion-label>
				</ion-item>
				<ion-item
					(click)="menuClick()"
					[routerLink]="'feed'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/feed.svg"></ion-icon>
					<ion-label translate>FEED</ion-label>
				</ion-item>
				<ion-item
					(click)="menuClick()"
					[ngClass]="!auth.$userIsEnterpriseUser.getValue() ? 'disabledItem' : ''"
					[routerLink]="'page-job'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/jobs.svg"></ion-icon>
					<ion-label translate>JOBS</ion-label>
				</ion-item>
				<ion-item
					(click)="menuClick()"
					*ngIf="false"
					[routerLink]="'events'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/event.svg"></ion-icon>
					<ion-label translate>EVENTS</ion-label>
				</ion-item>
				<ion-item
					(click)="menuClick()"
					[routerLink]="'groups'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/groups.svg"></ion-icon>
					<ion-label translate>GROUPS</ion-label>
				</ion-item>
				<ion-item
					(click)="menuClick()"
					[ngClass]="!auth.$userIsEnterpriseUser.getValue() ? 'disabledItem' : ''"
					[routerLink]="'contact'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/chat.svg"></ion-icon>
					<ion-label translate>CONTACT</ion-label>
				</ion-item>
				<ion-item
					(click)="menuClick()"
					[routerLink]="'ratings'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/ratings.svg"></ion-icon>
					<ion-label translate>LOCATION_RATING</ion-label>
				</ion-item>
				<ion-item
					(click)="menuClick()"
					[routerLink]="'profile'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/profile.svg"></ion-icon>
					<ion-label translate>PROFILE</ion-label>
				</ion-item>
				<ion-item
					(click)="menuClick()"
					[routerLink]="'about'"
					color="colorFlutaroSlider"
					lines="none"
					routerDirection="forward"
				>
					<ion-icon src="assets/sidemenu/about.svg"></ion-icon>
					<ion-label translate>ABOUT</ion-label>
				</ion-item>
				<ion-item (click)="logout()" color="colorFlutaroSlider" lines="none">
					<ion-icon src="assets/sidemenu/logout.svg"></ion-icon>
					<ion-label translate>LOGOUT</ion-label>
				</ion-item>
			</ion-list>
		</ion-content>
	</ion-menu>
	<ion-router-outlet
		[ngClass]="
			(auth.$userIsEnterpriseUser | async) && (deviceProvider.$isOnline | async) === false
				? 'mainContentOfflineWarning'
				: ''
		"
		id="mainContent"
	>
	</ion-router-outlet>
</ion-app>
