import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';

@Component({
	selector: 'app-list-icon-title',
	templateUrl: './app-list-icon-title.component.html',
	styleUrls: ['./app-list-icon-title.component.scss'],
})
export class AppListIconTitleComponent implements OnInit {
	@Input() isSearch: boolean;
	@Input() type: string;
	@Input() userProfile: FlutaroUserProfile;
	@Input() searchResultsList;
	@Input() elementsNameIdentifier: string;

	@Output() resultChange: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	ngOnInit() {}

	emitSearchSelection(resultEntry) {
		this.resultChange.emit(resultEntry);
	}
}
