<div *ngIf="user && comment">
    <app-post [post]="comment"
              [user]="user"
              [parentPost]="post"
              [commentDepth]="commentDepth+1"
              [readonlyUid]="comment.authorId"
              [isComment]="true"
              (postDeleted)="deleteComment($event)"
              (lvl3CommentChange)="addNewComment($event)"
              *ngIf="!isNew">
    </app-post>

    <div *ngIf="isNew">
        <post-feed [(post)]="comment" (send)="addNewComment()" [user]="user">
        </post-feed>
    </div>
</div>
