import { FlutaroPost, SocialElementBasics } from '../social/post/post';
import { ActivityFeedEntry, ActivityTypes } from '../social/activities/ActivitiesFeed';
import { FlutaroLocationRating } from '../social/location/Location';
import { FlutaroGroup } from '../social/groups/FlutaroGroup';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import firebase from 'firebase/compat/app';
import {
	cloneClassIntoPlainObject,
	transformClassIntoPlainObject,
} from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { parseProfileBeforeFirestoreStorage } from '@flutaro/package/lib/functions/db/UserProfileFunctions';
import { parseFirestoreDateAttributes } from '@flutaro/package/lib/functions/FlutaroDataFunctions';
import DocumentData = firebase.firestore.DocumentData;
import DocumentReference = firebase.firestore.DocumentReference;
import FieldValue = firebase.firestore.FieldValue;

export function transformTypedArrayForFirestoreStorage(object) {
	return [].concat(object);
}

export function prepareUserProfileUpdateBeforeFirebaseStorage(userProfile: FbStoreUserProfile) {
	// TODO: refactor/change me for Public Profile
	let pUserProfile = prepareProfileBeforeFirebaseStorage(userProfile);

	delete pUserProfile.uid;
	delete pUserProfile.company;
	delete pUserProfile.fcmTokens;
	delete pUserProfile.fcmDeviceTokens;
	delete pUserProfile.roles;
	//TODO: activate again after check of successful migration delete pUserProfile.chatChannels;
	delete pUserProfile.email;
	delete pUserProfile.emailVerified;

	return pUserProfile;
}

export function prepareSocialDocumentBeforeFirestoreStorage(
	element: SocialElementBasics | FlutaroPost | FlutaroLocationRating | ActivityFeedEntry,
) {
	let firestoreElement = transformClassIntoPlainObject(element);
	firestoreElement.lastModified = firebase.firestore.FieldValue.serverTimestamp();
	firestoreElement.timestamp = firebase.firestore.FieldValue.serverTimestamp();
	if ((element as FlutaroLocationRating).rating) {
		(element as FlutaroLocationRating).rating = cloneClassIntoPlainObject((element as FlutaroLocationRating).rating);
	}
	if ((element as FlutaroLocationRating).location) {
		(element as FlutaroLocationRating).location = cloneClassIntoPlainObject(
			(element as FlutaroLocationRating).location,
		);
	}
	return firestoreElement;
}

export function prepareGroupBeforeFirebaseStorage(group: FlutaroGroup) {
	return cloneClassIntoPlainObject(
		prepareGroupMembersBeforeFirebaseStorage(prepareSocialDocumentBeforeFirestoreStorage(group)),
	);
}

export function prepareGroupMembersBeforeFirebaseStorage(group: FlutaroGroup) {
	group.members = group.members.map((member) => cloneClassIntoPlainObject(member));
	return group;
}

export function preparePostBeforeFirebaseStorage(feed: FlutaroPost) {
	let post = prepareSocialDocumentBeforeFirestoreStorage(feed);

	if (post.taggedFriends.length > 0) {
		post.taggedFriends = post.taggedFriends.map((friend) => cloneClassIntoPlainObject(friend));
		post.taggedFriends = transformTypedArrayForFirestoreStorage(post.taggedFriends);
	}
	return post;
}

export function prepareLocationRatingBeforeFirebaseStorage(rating: FlutaroLocationRating) {
	let post = prepareSocialDocumentBeforeFirestoreStorage(rating);
	post.rating = cloneClassIntoPlainObject(post.rating);
	post.location = cloneClassIntoPlainObject(post.location);
	return cloneClassIntoPlainObject(post);
}

export function prepareTimelineEntryForFiretore(timelineEntry: ActivityFeedEntry) {
	let post = prepareSocialDocumentBeforeFirestoreStorage(timelineEntry);
	if (post.actionType !== ActivityTypes.COMMENT) {
		delete post.commentData;
	}
	if (post.actionType === ActivityTypes.FOLLOW) {
		delete post.postId;
		delete post.postDescription;
	}

	return post;
}

export function transformTimelineData(timeline: ActivityFeedEntry[]): ActivityFeedEntry[] {
	return timeline.map((entry) => parseFirestoreDateAttributes(entry));
}

export function transformFeedForApp(docSnapshot: firebase.firestore.DocumentSnapshot<DocumentData>) {
	let feed = docSnapshot.data();
	feed.ref = docSnapshot.ref;
	feed.id = docSnapshot.id;
	return parseFirestoreDateAttributes(feed);
}

export function transformCommentForWebApp(comment: firebase.firestore.DocumentSnapshot<DocumentData>) {
	return transformFeedForApp(comment);
}

export function setDocRefAfterDocAddedInFirestore(docDataObject, docDataRef: DocumentReference) {
	docDataObject.docRef = docDataRef.path;
	docDataObject.id = docDataRef.id;
}

export function prepareProfileBeforeFirebaseStorage(profile: FbStoreUserProfile): any {
	let profileForStorage = parseProfileBeforeFirestoreStorage(profile);
	profileForStorage.lastModified = FieldValue.serverTimestamp();
	return profileForStorage;
}
