<ion-row class="listHeaderRow ion-align-items-center">
  <ion-col col-12>
    <p class="listHeaderText" translate>
      <ion-icon class="ionIconListHeader" name="star"></ion-icon>
      {{'LOCATION_RATING' | translate}}
    </p>
  </ion-col>
</ion-row>
<ion-fab horizontal="end" slot="fixed" vertical="top">
  <ion-fab-button (click)="addLocationsItem()" [disabled]="!(deviceService.$isOnline | async)"
                  color="flutaroAccentButton">
    <ion-icon name="add"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content *ngIf="!(deviceService.$isOnline | async)">
  <div class="deviceOffline">
    <div class="deviceOfflineInner">
      <span class="deviceOfflineIcon"></span> <!-- this is the base64 icon - is offline available-->
      <p translate>DEVICE_OFFLINE_INFO</p>
    </div>
  </div>
</ion-content>

<ion-content *ngIf="deviceService.$isOnline | async" class="locationContent">
  <ion-searchbar [(ngModel)]="searchText"
                 [placeholder]="'SEARCH_FOR' | translate" class="flutaro-app-searchbar"
                 color="colorSearchbar"></ion-searchbar>

  <div
    *ngFor="let location of locationRatingsProvider.locationRatings | async | filterRatings: searchText | appOrderBy: ['-lastModified']">
    <app-post [post]="location"
              [readonlyUid]="location.authorId"
              [user]="userProvider.$userProfile | async">
    </app-post>
  </div>
</ion-content>
