import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginPageModule } from './pages/login/login.module';
import { SignupPageModule } from './pages/signup/signup.module';
import { ComponentsModule } from './components/components.module';
import { ChatPageModule } from './pages/chat/chat.module';
import { JobDeclinePageModule } from './pages/job-decline/job-decline.module';
import { AuthErrorPageModule } from './auth/auth-error.module';
import { EmojiService } from './services/emoji.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { PushMessagesService } from './services/push/push-messages.service';
import { AppDeviceService } from './app-device.service';
import { GeodataBackgroundService } from './services/geodata/geodata-background.service';
import { HomePageModule } from './pages/home/home.module';
import { ProfilePageModule } from './edit-profile/edit-profile.module';
import { LocationModule } from './social/location/location.module';
import { environment } from '../environments/environment';
import { AnalyticsService } from './services/Analytics/analytics.service';
import { JobService } from './pages/jobs/job.service';
import { SocialModule } from './social/social.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FlutaroPagesModule } from './pages/pages.module';
import { FlutaroPipesModule } from 'flutaro-lib-angular/lib/pipes/flutaro.pipes.module';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { GpsConfigurationService } from './services/geodata/gps.configuration.service';
import { AppHttpRetryInterceptor } from 'flutaro-lib-angular/lib/interceptor/app-http-retry-interceptor';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { ServiceWorkerModule } from '@angular/service-worker';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>((resolve) => {
	resolvePersistenceEnabled = resolve;
});

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		FlutaroPipesModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		TranslateModule.forRoot(),
		AngularFireMessagingModule,
		AngularFirestoreModule,
		AuthErrorPageModule,
		FlutaroPagesModule,
		LoginPageModule,
		SignupPageModule,
		ComponentsModule,
		ChatPageModule,
		JobDeclinePageModule,
		HomePageModule,
		ProfilePageModule,
		LocationModule,
		AngularFireStorageModule,
		SocialModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			registrationStrategy: 'registerWhenStable:30000',
		}),
		provideAuth(() => {
			if (Capacitor.isNativePlatform()) {
				return initializeAuth(getApp(), {
					persistence: indexedDBLocalPersistence,
				});
			} else {
				return getAuth();
			}
		}),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		/**provideFirestore(() => {
			const firestore = getFirestore();
			enableMultiTabIndexedDbPersistence(firestore).then(
				() => resolvePersistenceEnabled(true),
				() => resolvePersistenceEnabled(false)
			);
			return firestore;
		}) **/
	],
	providers: [
		JobService,
		EmojiService,
		AngularFirestore,
		AngularFireMessaging,
		AngularFireAuth,
		NotificationsService,
		PushMessagesService,
		AppDeviceService,
		GeodataBackgroundService,
		GpsConfigurationService,
		AnalyticsService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppHttpRetryInterceptor,
			multi: true,
		},
		{
			provide: 'TranslateService',
			useClass: TranslateService, // 'de-DE' for Germany, 'fr-FR' for France ...
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	static injector: Injector;

	constructor(injector: Injector) {
		AppModule.injector = injector;
	}
}
