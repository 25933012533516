import { Injectable } from '@angular/core';
import { JobService } from '../../pages/jobs/job.service';
import { createPositionDataFromJobAndPosition } from './PositionDataFunctions';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../Analytics/analytics.service';
import { NotificationsService } from '../notifications/notifications.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { translateProviderState } from './BackgroundTrackingHelpers';
import { createBackgroundTrackingConfigurationForJob } from './BackgroundGeoConfiguration';
import { JobPairing } from '@flutaro/package/lib/model/Positiondata';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import BackgroundGeolocation, { Geofence, Location } from '@transistorsoft/capacitor-background-geolocation';
import { flutaroWait, to } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { GEO_FENCE_EVENT, GEO_TRACKING_EVENT } from '@flutaro/package/lib/model/AppAnalyticsEvents';
import { GpsConfigurationService } from './gps.configuration.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GeodataBackgroundService {
	$isFullBackgroundLocationServiceAuthorized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	companyGeoFences: Geofence[] = [];

	constructor(
		private jobProvider: JobService,
		private notifications: NotificationsService,
		private analytics: AnalyticsService,
		private aFS: AngularFirestore,
		private gpsConfigService: GpsConfigurationService,
	) {}

	/**
	 * Activates Background-Geo-Plugin recording and Job watch. The ONLY place where BackgroundGeolocation.ready is called!
	 * Only call once UserProfile is loaded and platform initialized.
	 * iOs Reference: https://developer.apple.com/documentation/corelocation/cllocationmanager/1620551-requestalwaysauthorization
	 */
	async configureAndActivateBackgroundPlugin(userProfile: FbStoreUserProfile) {
		console.log(`configureAndActivateBackgroundPlugin starting`);
		this.listenToGeolocationProviderChanges();
		await this.gpsConfigService.initAndConfigureBackgroundTracking(userProfile);
		this.listenToCompanyGeofences(userProfile);
		this.checkGPSStateAndActivateJobStartedWatch(userProfile);
		console.log(`configureAndActivateBackgroundPlugin finished`);
	}

	async requestAndUpdateLocationPermissionState() {
		console.debug(`requestAndUpdateLocationPermissionState called at ${new Date().toISOString()}`);
		await this.gpsConfigService.requestLocationPermission();
		return;
	}

	async endBackgroundGeoRecording() {
		const state = await BackgroundGeolocation.stop();
		console.debug(`endBackgroundGeoRecording, stopped tracking. Current Tracking-State isEnabled: ${state.enabled}`);
		return true;
	}

	private listenToCompanyGeofences(userProfile: FbStoreUserProfile) {
		this.aFS
			.collection('companyData')
			.doc(userProfile.company)
			.collection('geofences')
			.valueChanges()
			.subscribe((geofences) => {
				if (!geofences) {
					return;
				}

				console.log(geofences);
				this.companyGeoFences = <any>geofences;
			});
	}

	private addGeoFence(geofence: Geofence) {
		BackgroundGeolocation.addGeofence({
			identifier: geofence.identifier,
			radius: geofence.radius,
			latitude: geofence.latitude,
			longitude: geofence.longitude,
			notifyOnEntry: true,
			notifyOnExit: true,
		})
			.then(() => {
				console.log('[addGeofence] success');
			})
			.catch((error) => {
				console.log('[addGeofence] FAILURE: ', error);
				this.analytics.logErrorCrashAnalytics(error);
			});
	}

	private async storeLatestPositionOFFAndEndBackgroundGeoRecording() {
		console.debug(
			`storeLatestPositionOFFAndEndBackgroundGeoRecording, called - getting current position for Jobs-Off Tracking`,
		);
		await BackgroundGeolocation.setConfig({
			extras: {
				pairing: JobPairing.OFF,
				apiKeyFbBGApp: environment.apiKeyFbBGApp,
			},
		});
		const [locationError, location] = await to<Location>(
			BackgroundGeolocation.getCurrentPosition({
				timeout: 2,
				desiredAccuracy: 50,
				samples: 1,
			}),
		);
		if (locationError) {
			console.error(
				`storeLatestPositionOFFAndEndBackgroundGeoRecording, error when calling getCurrentPosition. Error: ${locationError}`,
			);
			this.analytics.logErrorCrashAnalytics(`getCurrentPosition-error`);
		}
		console.debug(`storeLatestPositionOFFAndEndBackgroundGeoRecording, stored location ${JSON.stringify(location)}`);
		await this.endBackgroundGeoRecording();
	}

	private async startStopBackgroundTrackingForCurrentStartedJob(
		currentStartedJob: JobWrapper,
		userProfile: FbStoreUserProfile,
	) {
		console.log(
			`startStopBackgroundTrackingForCurrentStartedJob, called - updating BackgroundPlugin Config for job information`,
		);
		const position = createPositionDataFromJobAndPosition(currentStartedJob);
		const pluginConfiguration = createBackgroundTrackingConfigurationForJob(
			position,
			userProfile,
			environment.production,
			environment.apiKeyFbBGApp,
			this.gpsConfigService.createBackgroundGPSPermissionRationale(),
			this.gpsConfigService.createBackgroundGPSNotificationText(),
			this.gpsConfigService.createBackgroundGPSLocationAuthorizationAlertForIOs(),
		);
		await this.gpsConfigService.updateBackgroundPluginByConfig(pluginConfiguration);
		this.addGeoFencesFromFirestore();
		const [pluginError, backgroundPluginStartState] = await to(BackgroundGeolocation.start());
		if (pluginError) {
			console.log(
				`ERROR in activateBackgroundGeolocation: Background-Plugin couldn´t be started. Plugin Error: ${pluginError}`,
			);
			this.analytics.logEvent(GEO_TRACKING_EVENT.TRACKING_PLUGIN_ERROR, pluginError);
			this.notifications.showErrorToast(
				`Error activating Background Position-Tracking Plugin, couldnt start the service`,
			);
			return false;
		}
		console.debug(
			`startStopBackgroundTrackingForCurrentStartedJob, Background-GPS-Tracking started and running (Plugin-State: ${backgroundPluginStartState})`,
		);
		this.analytics.logEvent(GEO_TRACKING_EVENT.GEO_TRACKING_ACTIVATE, currentStartedJob.backendId);
		return true;
	}

	private addGeoFencesFromFirestore() {
		this.analytics.logEvent(GEO_FENCE_EVENT.ADDED, 'geofences', this.companyGeoFences.length);
		this.companyGeoFences.forEach((geofence) => {
			this.addGeoFence(geofence);
		});
	}

	private async checkGPSStateAndActivateJobStartedWatch(userProfile: FbStoreUserProfile) {
		// We only track job positions if the background tracking service is fully activated / always-tracking-enabled
		while (!(await this.isFullBackgroundLocationServiceAuthorizedAndUpdate())) {
			console.debug(
				`checkGPSStateAndActivateJobStartedWatch, always-tracking not yet enabled - trying again in a second`,
			);
			await flutaroWait(1000);
		}
		console.log(
			`checkGPSStateAndActivateJobStartedWatch, starting subscription and checkForStartedJobAndBackgroundGeoWatch`,
		);
		this.jobProvider.currentStartedJob.subscribe((startedJob) => {
			this.updateBackgroundPluginEnabledStateOnStartedJobChange(startedJob, userProfile);
		});
	}

	private async getIsBackgroundPluginEnabled() {
		return (await BackgroundGeolocation.getState()).enabled;
	}

	private async updateBackgroundPluginEnabledStateOnStartedJobChange(
		startedJob: JobWrapper,
		userProfile: FbStoreUserProfile,
	) {
		const isBackgroundPluginStarted = await this.getIsBackgroundPluginEnabled();
		if (!startedJob && !isBackgroundPluginStarted) {
			return;
		} else if (startedJob && isBackgroundPluginStarted) {
			console.log(
				`startStopBackgroundTrackingForCurrentStartedJob, ATTENTION: NO_ACTION_CASE - this shouldnt happen anymore. Investigate and refactor`,
			);
			return;
		} else if (!startedJob && isBackgroundPluginStarted) {
			this.storeLatestPositionOFFAndEndBackgroundGeoRecording();
		} else if (startedJob && !isBackgroundPluginStarted) {
			this.startStopBackgroundTrackingForCurrentStartedJob(startedJob, userProfile);
		}
	}

	private async isFullBackgroundLocationServiceAuthorizedAndUpdate(): Promise<boolean> {
		this.$isFullBackgroundLocationServiceAuthorized.next(await this.gpsConfigService.isAlwaysTrackingEnabled());
		return this.$isFullBackgroundLocationServiceAuthorized.getValue();
	}

	private listenToGeolocationProviderChanges() {
		console.debug(`listenToLGeolocationProviderChanges, start listening to provider changes`);
		let currentGPSTrackingAuthorizationState: number;
		BackgroundGeolocation.onProviderChange(async (providerChange) => {
			const newProviderState = providerChange.status;
			console.log(
				`listenToLGeolocationProviderChanges, new provider state received at ${new Date().toISOString()}. New State from Provider: ${translateProviderState(
					newProviderState,
				)}`,
			);
			await this.isFullBackgroundLocationServiceAuthorizedAndUpdate();
			if (newProviderState === currentGPSTrackingAuthorizationState) {
				console.log(
					`listenToGeolocationProviderChanges, SAME STATE: providerChange.status is same as lastState. Stopping here`,
				);
				return;
			}
			currentGPSTrackingAuthorizationState = newProviderState;
			switch (newProviderState) {
				case BackgroundGeolocation.AUTHORIZATION_STATUS_NOT_DETERMINED:
				case BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED:
					console.debug(
						`listenToGeolocationProviderChanges, AUTHORIZATION_STATUS_NOT_DETERMINED or AUTHORIZATION_STATUS_DENIED - showing Info-Alert and requesting permission`,
					);
					await this.notifications.showInfoAlertWithOkAction(
						'GPS_ACTIVATE_REQUEST_INFO_HEADER',
						'GPS_ACTIVATE_REQUEST_INFO_MESSAGE',
					);
					await this.requestAndUpdateLocationPermissionState();
					break;
				default:
					console.debug(
						`listenToGeolocationProviderChanges, authorization granted. Background-Plugin handles further permission handling`,
					);
			}
			if (providerChange.accuracyAuthorization == BackgroundGeolocation.ACCURACY_AUTHORIZATION_REDUCED) {
				// iOs 14+ only - needs additional request for temporaryFullAccuracy (=== lifetime of this application run (until terminate)). Android and iOs 13 (and lower) always return FullAccuracy
				// Supply "Purpose" key from Info.plist as 1st argument.
				console.log(
					`listenToGeolocationProviderChanges, BackgroundGeolocation.accuracyAuthorization triggered because of ACCURACY_AUTHORIZATION_REDUCED. State: ${newProviderState}`,
				);
				BackgroundGeolocation.requestTemporaryFullAccuracy('HighAccuracyPurpose')
					.then((accuracyAuthorization) => {
						if (accuracyAuthorization == BackgroundGeolocation.ACCURACY_AUTHORIZATION_FULL) {
							console.log('[requestTemporaryFullAccuracy] GRANTED:', accuracyAuthorization);
						} else {
							console.log('[requestTemporaryFullAccuracy] DENIED:', accuracyAuthorization);
						}
					})
					.catch((error) => {
						console.log('[requestTemporaryFullAccuracy] FAILED TO SHOW DIALOG:', error);
					});
			}
		});
	}
}
