<ion-row class="listHeaderRow ion-align-items-center">
    <ion-col col-12>
        <p class="listHeaderText" translate>
            <ion-icon class="ionIconListHeader" name="notifications"></ion-icon>
            ACTIVITY_LIST_HEADER
        </p>
    </ion-col>
</ion-row>

<ion-content>
    <ion-list>
        <ion-item *ngFor="let activity of activitiesProvider.$activities | async"
                  lines="none"
                  [color]="'colorActivitiesList'"
                  (click)="openActivity(activity)"
                  [ngClass]="{'isUnread' : !activity.isRead }">
            <app-avatar [user]="userProfileProvider.$userProfile | async" [authorId]="activity.authorId"
                        [authorPhoto]="activity.authorPhoto">
            </app-avatar>
            <ion-label>
                <p class="authorName">{{activity.authorName}}</p>
                <p class="timestamp">{{activity.timestamp | relativeTime}}</p>
                <p *ngIf="activity.actionType === 'LIKE' || activity.actionType === 'COMMENT' || activity.actionType === 'INVITE'"
                   class="actionType">
                    {{activity.actionType | translateTimelineType:activity.postType | translate}}
                </p>
                <p class="text" *ngIf="activity.actionType === 'COMMENT'">{{activity.commentData}}</p>
                <p *ngIf="activity.actionType === 'LIKE' || activity.actionType === 'TAGGED' || activity.actionType === 'INVITE'"
                   class="text">
                    {{activity.postDescription}}
                </p>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>
