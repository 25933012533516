<ion-row class="listHeaderRow ion-align-items-center">
    <ion-col col-12>
        <p class="listHeaderText" translate>
            <ion-icon class="ionIconListHeader" name="information-circle-outline"></ion-icon>
            ABOUT
        </p>
    </ion-col>
</ion-row>

<ion-content class="ion-padding">

    <ion-row>
        <ion-col>
            <a href="https://www.flutaro.de/" target="_blank">
                <img class="user-img" src="assets/imgs/Flutaro_Logo_white.svg">
            </a>
        </ion-col>
    </ion-row>
    <p translate>
        FLUTARO_ABOUT_DESCRIPTION_TEXT
    </p>
    <ion-button [color]="'flutaroMain'" expand="block" fill="solid" translate>
      <ion-icon name="person-add-outline" slot="start"></ion-icon>
      FLUTARO_ABOUT_INVITE_FRIENDS
    </ion-button>
    <ul>
        <li translate>
            FLUTARO_ABOUT_TOURSCHEDULING
        </li>
        <li translate>
            FLUTARO_ABOUT_ERP
        </li>
        <li translate>
            FLUTARO_ABOUT_FLEET_MANAGEMENT
        </li>
        <li translate>
            FLUTARO_ABOUT_DRIVER_MANAGEMENT
        </li>
    </ul>

    <ion-button [color]="'color596163'" expand="block" fill="solid" href="https://www.flutaro.de/"
                target="_blank" translate>
                <ion-icon name="information-circle-outline"></ion-icon>
                MORE_INFO
    </ion-button>


    <ion-card [color]="'color64666EProfile'">
        <ion-item [color]="'color64666EProfile'" lines="none">
            <ion-icon name="mail-outline" slot="start"></ion-icon>
            <ion-label>Email</ion-label>
            <a href="mailto:info@flutaro.de?subject=Flutaro:%20Interesse/Fragen/Anregungen%20(FahrerApp)">
                <ion-button [color]="'flutaroAccentButton'" fill="solid" slot="end">Email</ion-button>
            </a>
        </ion-item>
        <ion-card-content>
            <p translate>FLUTARO_ABOUT_CONTACT</p>
        </ion-card-content>
    </ion-card>
    <p class="imprint"><a href="https://www.flutaro.de/impressum" target="_blank" translate>IMPRINT</a></p>
</ion-content>


