import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { PostProvider } from '../../post/post.provider';
import { FollowerService } from '../../follower/follower.service';
import { TranslateService } from '@ngx-translate/core';
import { FlutaroUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { TruckersVehicleInformation, UserProfilePublic } from '@flutaro/package/lib/model/UserProfile';

@Component({
	selector: 'app-user-page',
	templateUrl: './user-page.component.html',
	styleUrls: ['./user-page.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class UserPageComponent implements OnInit {
	user: FlutaroUserProfile;
	profile: UserProfilePublic;
	latestPosts;
	isFollower: boolean;
	userFollowers: number;
	userFollowing: number;
	userPostsCount: number;

	constructor(
		public params: NavParams,
		public viewCtrl: ModalController,
		public feedProvider: PostProvider,
		private followerService: FollowerService,
		private translate: TranslateService,
	) {}

	async ngOnInit() {
		this.user = this.params.get('user');
		this.profile = this.params.get('profile');
		if (!this.profile.vehicleInformation) this.profile.vehicleInformation = new TruckersVehicleInformation();
		this.latestPosts = await this.feedProvider.getLatestUserPosts(this.profile.uid).toPromise();
		this.isFollower = await this.followerService.checkIfFollowing(this.profile.uid, this.user);
		this.userFollowers = await this.followerService.getFollowers(this.profile.uid);
		this.userFollowing = await this.followerService.getFollowing(this.profile.uid);
		this.userPostsCount = await this.feedProvider.getNumberOfUserPosts(this.profile.uid);
	}

	dismiss() {
		this.viewCtrl.dismiss();
	}

	changeFollowing() {
		this.isFollower
			? this.followerService.unfollowUser(this.profile.uid, this.user)
			: this.followerService.followUser(this.profile, this.user);
		this.isFollower = !this.isFollower;
		this.userFollowers = this.isFollower ? this.userFollowers + 1 : this.userFollowers - 1;
	}
}
