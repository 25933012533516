import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RATING_COLORS } from '../ComponentClasses';

@Component({
	selector: 'flutaro-star-rating',
	templateUrl: './star-rating.component.html',
	styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
	@Input() rating: number;
	@Input() readonly: boolean;

	@Output() ratingChange: EventEmitter<number> = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	rateElement(rating: number) {
		this.rating = rating;
		this.ratingChange.emit(rating);
	}

	getColor(rating: number) {
		if (this.isAboveRating(rating)) return RATING_COLORS.GREY;
		switch (this.rating) {
			case 1:
			case 2:
				return RATING_COLORS.RED;
			case 3:
				return RATING_COLORS.YELLOW;
			case 4:
			case 5:
				return RATING_COLORS.GREEN;
		}
	}

	isAboveRating(index: number): boolean {
		// returns whether or not the selected index is above ,the current rating
		// function is called from the getColor function.
		return index > this.rating;
	}
}
