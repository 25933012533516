import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LocationRatingComponent } from './location-rating.component';
import { FilterLocationRatingsDataPipe } from './location.pipes';
import { PostLocationComponent } from './location/post-location.component';
import { AutoCompleteComponentService } from './AutoCompleteComponentService';
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from '../../share.module';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, ShareModule, ComponentsModule, TranslateModule],
	declarations: [LocationRatingComponent, FilterLocationRatingsDataPipe, PostLocationComponent],
	providers: [AutoCompleteComponentService],
	exports: [LocationRatingComponent, FilterLocationRatingsDataPipe, PostLocationComponent],
})
export class LocationModule {}
