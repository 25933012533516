import { Pipe, PipeTransform } from '@angular/core';
import { TaggedFriend } from './post';

@Pipe({
	name: 'taggedFriends',
})
export class TaggedFriendsPipe implements PipeTransform {
	transform(taggedFriends: TaggedFriend[]): string {
		let taggedFriendsAsString = taggedFriends.map((friend) => friend.nickname);
		let resultString;
		if (taggedFriends.length > 3) {
			resultString = `${taggedFriendsAsString[0]}, ${taggedFriendsAsString[1]} und ${
				taggedFriendsAsString.length - 2
			} weiteren Freunden.`;
		} else {
			resultString = taggedFriendsAsString.join(', ');
		}

		return resultString;
	}
}
