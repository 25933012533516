import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { AppLoginPageComponent } from './login.page';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
	{
		path: 'page-login',
		component: AppLoginPageComponent,
	},
];

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, IonicModule, RouterModule.forChild(routes), TranslateModule],
	declarations: [AppLoginPageComponent],
	exports: [AppLoginPageComponent],
})
export class LoginPageModule {}
