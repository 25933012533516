<ion-toolbar>
    <ion-buttons slot="start">
        <ion-button color="light" (click)="dismiss()">
            <ion-icon name="arrow-back"></ion-icon>
        </ion-button>
    </ion-buttons>

    <ion-title *ngIf="newPost.type === 'POST' || newPost.type === 'GROUP'" class="ion-text-center"
               translate>{{isEdit ? 'EDIT_POST' : 'CREATE_POST' | translate}}</ion-title>
    <ion-title *ngIf="newPost.type === 'RATING'" class="ion-text-center"
               translate>{{isEdit ? 'EDIT_LOCATION_RATING' : 'CREATE_LOCATION_RATING' | translate}}
    </ion-title>
    <ion-title *ngIf="newPost.type === 'COMMENT'" translate>EDIT_COMMENT</ion-title>

    <ion-buttons slot="end">
        <ion-button class="flutaroSend" color="light" (click)="save()">
            <ion-icon name="send"></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>

<ion-content class="ion-padding">
    <ion-item *ngIf="newPost.type !== 'COMMENT' && newPost.type !== 'GROUP'" [color]="'color64666EProfile'"
              lines="none">
        <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
        <ion-label translate>POST_TYPE</ion-label>
        <ion-select [(ngModel)]="newPost.type">
            <ion-select-option value="POST" translate>POST</ion-select-option>
            <ion-select-option value="RATING" translate>LOCATION_RATING</ion-select-option>
        </ion-select>
    </ion-item>

    <ion-item color="colorFeedTextarea" lines="none" class="input-has-focus"
              *ngIf="newPost.type === 'COMMENT'">
        <ion-textarea class="input-has-focus"
                      [(ngModel)]="newPost.content" [rows]="8"
                      [autoGrow]="false"
                      [spellcheck]="true"
                      [ngClass]="newPost.type !== 'POST' ? 'textareaDark' : ''">
        </ion-textarea>
    </ion-item>

    <post-feed *ngIf="newPost.type === 'POST' || newPost.type === 'GROUP'" [(post)]="newPost" [user]="user"></post-feed>

    <app-location-rating (ratingChange)="addRating($event)" *ngIf="newPost.type === 'RATING'" [(rating)]="newPost">
    </app-location-rating>
</ion-content>
