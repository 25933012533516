import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentService } from './comment.service';

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [CommentService],
})
export class CommentModule {}
