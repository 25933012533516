import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppAuthInterceptorService } from './app-auth-interceptor.service';
import { AppAuthService } from './app-auth.service';
import { RouterModule } from '@angular/router';
import { AppLoginErrorComponent } from './auth-error.page';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		RouterModule.forChild([
			{
				path: 'page-login-error',
				component: AppLoginErrorComponent,
			},
		]),
	],
	declarations: [AppLoginErrorComponent],
	providers: [
		AppAuthService,
		AppAuthInterceptorService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppAuthInterceptorService,
			multi: true,
		},
	],
})
export class AuthErrorPageModule {}
