import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserPageComponent } from '../profile/user-page/user-page.component';
import { ProfilePublicService } from '../profile/profile.public.service';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { UserProfilePublic } from '@flutaro/package/lib/model/UserProfile';

@Injectable({
	providedIn: 'root',
})
export class AvatarService {
	constructor(private modalCtrl: ModalController, private profileProvider: ProfilePublicService) {}

	async avatarClicked(user: FbStoreUserProfile, profileId: string, profile?: UserProfilePublic) {
		if (!profileId && !profile) {
			console.error('Not enough parameters to show Avatars User-Profile.');
			return;
		}
		if (!profile) {
			profile = <any>await this.profileProvider.getProfileById(profileId);
		}

		let modal = await this.modalCtrl.create({
			component: UserPageComponent,
			componentProps: {
				profile: profile,
				user: user,
			},
		});
		modal.present();
	}
}
