<ion-toolbar>
  <ion-buttons slot="start">
    <ion-button (click)="dismiss()" color="light">
      <ion-icon name="arrow-back"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title *ngIf="isGroupEdit" class="ion-text-center" translate>
    {{(isEdit ? 'GROUP_EDIT' : 'GROUP_CREATE') | translate}}
  </ion-title>
  <ion-title *ngIf="!isGroupEdit" class="ion-text-center">{{group.title}}
  </ion-title>

  <ion-buttons slot="end">
    <ion-button (click)="presentActionSheet()" *ngIf="!isGroupEdit" class="flutaroMoreEditComment">
      <ion-icon name="ellipsis-horizontal"></ion-icon>
    </ion-button>
    <ion-button (click)="save()" *ngIf="isGroupEdit" class="flutaroSend" color="light">
      <ion-icon name="send"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-content class="ion-padding">
  <div *ngIf="!isGroupEdit">
    <ion-img [src]="group.photoURL" class="groupPictureOnlyShow"></ion-img>
  </div>

  <div *ngIf="isGroupEdit">
    <app-picture [(photo)]="group.photoURL"
                 [pictureType]="'GROUP-IMAGE'"
                 [title]="'GROUP_PHOTO' | translate"
                 [uid]="group.authorId">
    </app-picture>
    <ion-item [color]="'color64666EProfile'" lines="none">
      <ion-icon name="bookmark-outline"></ion-icon>
      <ion-label translate>GROUP_TITLE</ion-label>
      <ion-input [(ngModel)]="group.title" [color]="'colorFFFProfileText'"></ion-input>
    </ion-item>
    <ion-item [color]="'color64666EProfile'" lines="none">
      <ion-icon *ngIf="!group.isPublic" name="lock-closed-outline"></ion-icon>
      <ion-icon *ngIf="group.isPublic" name="lock-open-outline"></ion-icon>
      <ion-label translate>GROUP_PUBLIC</ion-label>
      <ion-toggle (ngModelChange)="changeGroupPublic()" [(ngModel)]="group.isPublic" color="secondary"
                  slot="end"></ion-toggle>
    </ion-item>
    <ion-item *ngIf="!group.isPublic" [color]="'color64666EProfile'" lines="none">
      <ion-icon name="search-circle-outline"></ion-icon>
      <ion-label translate>GROUP_SEARCHABLE</ion-label>
      <ion-toggle [(ngModel)]="group.isPublicSearchable" color="secondary" slot="end"></ion-toggle>
    </ion-item>
  </div>

  <div class="commentDiv">
    <ion-grid>
      <ion-row>
        <ion-item [color]="'colorTransparent'" class="commentDivItem input-has-focus" lines="none">
          <ion-icon [color]="'colorIcon'" name="text"></ion-icon>
          <ion-label [color]="'colorfff'" translate>GROUP_DESCRIPTION</ion-label>
        </ion-item>
      </ion-row>
      <ion-row>
        <ion-textarea [(ngModel)]="group.content"
                      [autoGrow]="false"
                      [color]="'colorCommentTextarea'"
                      [placeholder]="'GROUP_DESCRIPTION' | translate"
                      [rows]="isOwner ? 8 : 2"
                      [spellcheck]="true"
                      auto-grow
                      class="input-has-focus"
                      class="locationCommentTextarea">
        </ion-textarea>
      </ion-row>
    </ion-grid>
  </div>

  <div *ngIf="isOwner" class="showAddMembers">
    <ion-grid>
      <ion-row>
        <ion-item [color]="'colorTransparent'" class="showAddMembersDivItem" lines="none">
          <ion-button (click)="toogleShowAddMembers()"
                      [color]="'flutaroMain'">
            <ion-icon name="person-add-outline"></ion-icon>
            {{'GROUP_MEMBERS_INVITE' | translate}}
          </ion-button>
        </ion-item>
      </ion-row>

      <ion-row>
        <ion-item [color]="'colorTransparent'" class="width100" lines="none">
          <app-tag-friends *ngIf="showAddMembers" [(newTaggedFriends)]="invitedMembers"
                           [(taggedFriends)]="group.members"
                           [dividerTitle]="'GROUP_CURRENT_MEMBERS'"
                           [isEdit]="true" [user]="user"
                           class="width100">
          </app-tag-friends>
        </ion-item>
      </ion-row>

    </ion-grid>
  </div>

  <div *ngIf="!isGroupEdit" name="groupViewerSection">
    <ion-grid class="likeCommentDiv">
      <ion-row>
        <p (click)="showGroupMembers()" class="groupMembers">
          <span *ngIf="group.isPublic" translate><ion-icon name="globe-outline"></ion-icon>GROUP_PUBLIC</span>
          <span *ngIf="!group.isPublic" translate><ion-icon name="lock-closed-outline"></ion-icon>GROUP_PRIVATE</span>
          • {{group.members.length}}
          <span translate>GROUP_MEMBERS</span>
        </p>
      </ion-row>
      <ion-row>
        <ion-button (click)="changeUserIsGroupMember()"
                    [disabled]="this.userIsGroupMember"
                    class="roundButton"
                    color="{{this.userIsGroupMember ? 'dark' : 'flutaroMain'}}"
                    expand="full">
          {{(this.userIsGroupMember ? 'GROUP_USER_IS_MEMBER' : 'GROUP_JOIN') | translate}}
        </ion-button>
      </ion-row>
      <ion-row>
        <ion-button (click)="addNewPostForGroup()"
                    class="roundButton"
                    color="flutaroAccentButton"
                    expand="full"
                    translate>
          <ion-icon name="add"></ion-icon>
          CREATE_POST
        </ion-button>
      </ion-row>
      <ion-row *ngFor="let post of groupPosts | appOrderBy: ['-timestamp']"
               [ngClass]="{
                     'postFeed': post.type === 'GROUP',
                     'webPagePreview': (post.type === 'POST' || post.type === 'COMMENT' || post.type === 'GROUP') && post.pagePreview
                     }">
        <app-post (postDeleted)="deleteGroupPost($event)"
                  (postEdited)="editGroupPost($event)"
                  [commentDepth]="1"
                  [group]="group"
                  [post]="post"
                  [readonlyUid]="post.authorId"
                  [user]="user">
        </app-post>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
