import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FlutaroLocationRating } from './Location';

@Component({
	selector: 'app-location-rating',
	templateUrl: './location-rating.component.html',
	styleUrls: ['./location-rating.component.scss'],
})
export class LocationRatingComponent implements OnInit, OnChanges {
	@Input() rating: FlutaroLocationRating;
	@Output() ratingChange: EventEmitter<FlutaroLocationRating> = new EventEmitter<FlutaroLocationRating>();
	@Input() readOnly: boolean;
	@Input() triggerSave: boolean;
	@Output() ratingAdded: EventEmitter<FlutaroLocationRating> = new EventEmitter<FlutaroLocationRating>();
	ratingCopy: FlutaroLocationRating = new FlutaroLocationRating();

	constructor() {}

	ngOnChanges(changes) {
		if (changes['rating']) {
			if (this.rating.rating) {
				this.ratingCopy = this.rating;
			} else {
				this.ratingCopy = new FlutaroLocationRating();
			}
		}
	}

	ngOnInit() {}

	changeRating(newRating: number) {
		this.ratingCopy.rating.rating = newRating;
		this.saveRating();
	}

	changeQualityRating(newRating: number) {
		this.ratingCopy.rating.quality = newRating;
		this.saveRating();
	}

	changeSafetyRating(newRating: number) {
		this.ratingCopy.rating.safety = newRating;
		this.saveRating();
	}

	saveRating() {
		this.ratingChange.emit(this.ratingCopy);
	}
}
