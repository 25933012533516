<ion-grid class="shiftComponent">
  <ion-card-header>
    <h6 *ngIf="shiftType === shiftTypes.today" class="homeDate">
      {{'TODAY' | translate}}, {{shiftDate | appFormatDate:'date'}}
    </h6>
    <h6 *ngIf="shiftType === shiftTypes.tomorrow" class="homeDate"
        translate>TOMORROW
    </h6>
    <h6 *ngIf="shiftType === shiftTypes.currentWeek" class="homeDate"
        translate>CURRENT_WEEK
    </h6>
    <h6 *ngIf="shiftType === shiftTypes.nextWeek" class="homeDate"
        translate>NEXT_WEEK
    </h6>
  </ion-card-header>
  <div *ngIf="shiftDivision">
    <div (click)="jobsFilter.showJobsPageWithFilterActive(null, shiftType)"
         [routerLink]="'/page-job'"
         class="dashboardWidgetMain info-box plannedJobs">
      <ion-row class="ion-align-items-center">
        <ion-col col-2>
          <div class="dashboardIconPlannedBackground">
          </div>
        </ion-col>
        <ion-col col-8>
          <div class="title">{{shiftDivision.jobs.length | translateJobsLength | translate}}</div>
        </ion-col>
        <ion-col col-2>
          <div class="count">{{shiftDivision.jobs.length}}</div>
        </ion-col>
      </ion-row>
    </div>
    <ion-row *ngIf="shiftDivision.jobs && shiftDivision.jobs.length">
      <ion-col class="ion-justify-content-center" col-6>
        <div class="dashboardWidgetMain info-box shiftStartEnd">
          <div class="dashboardIconShiftStartIcon">
          </div>
          <div class="shiftTitle">{{ 'FIRST_PICKUP' | translate}}</div>
          <div class="shiftTime">{{shiftDivision.firstJob | jobsStartDate | appFormatDate:'time' }}
          </div>
          <div class="shiftLocation">({{(shiftDivision.firstJob | jobsFirstPickUp).position.city}})</div>
        </div>
      </ion-col>
      <ion-col col-6>
        <div class="dashboardWidgetMain info-box shiftStartEnd">
          <div class="dashboardIconShiftEndIcon">
          </div>
          <div class="shiftTitle">{{'LAST_DELIVERY' | translate}}</div>
          <div
            class="shiftTime">{{shiftDivision.lastJob | jobsEndDate | appFormatDate:'time'}}
          </div>
          <div class="shiftLocation">
            ({{(shiftDivision.lastJob | jobsLastDelivery).position.city}})
          </div>
        </div>
      </ion-col>
    </ion-row>
  </div>
</ion-grid>
