import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { IonContent, IonTextarea } from '@ionic/angular';
import { ChatService } from '../../services/chat.service';
import { FbStoreUserProfile } from '@flutaro/package/lib/model/AuthClasses';
import { JobWrapper } from '@flutaro/package/lib/model/Job';
import { Router } from '@angular/router';
import { FirebasePaginationService } from '../../services/firebase-pagination.service';
import { Subscription } from 'rxjs';
import { AppDeviceService } from '../../app-device.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileProvider } from '../../user/user.profile.provider';
import {
	ChatJobRequestType,
	ChatMessage,
	ChatMessageType,
	FBChatChannel,
} from '@flutaro/package/lib/model/ChatClasses';
import { appSortBy } from '@flutaro/package/lib/functions/AppJsHelperFunctions';
import { appParseMessages } from '@flutaro/package/lib/functions/AppChatFunctions';

@Component({
	selector: 'app-chat-page',
	templateUrl: './app-chat-page.component.html',
	styleUrls: ['./app-chat-page.component.scss'],
	providers: [FirebasePaginationService],
	encapsulation: ViewEncapsulation.None,
})
export class AppChatPageComponent implements OnDestroy {
	editorMsg: string = '';
	@ViewChild('chat_input') messageInput: IonTextarea;
	showEmojiPicker: boolean = false;
	messageSub: Subscription;
	userProfile: FbStoreUserProfile;
	channel: FBChatChannel;
	type: string; // Only set to a type, if the App-User started an Chat-Action with type. Is reset automatically after next Message sent
	job: JobWrapper;
	JobRequestType = ChatJobRequestType;
	isReachedToBottom: boolean;
	messages: ChatMessage[];
	messageSend: boolean = false;
	@ViewChild('content') private content: IonContent;

	constructor(
		public chatService: ChatService,
		public router: Router,
		public fbDataPaginationService: FirebasePaginationService,
		public deviceProvider: AppDeviceService,
		public translate: TranslateService,
		public userProvider: UserProfileProvider,
	) {
		this.getChannelData();
	}

	ionViewWillEnter() {
		this.isReachedToBottom = false;
	}

	ngOnDestroy() {
		this.fbDataPaginationService.fbSub.unsubscribe();
		this.messageSub.unsubscribe();
	}

	ionViewDidEnter() {
		const user = this.userProvider.$userProfile.getValue().uid;
		let isFirstOpen = true;
		this.messageSub = this.fbDataPaginationService.data.subscribe((newMessages) => {
			this.messages = appSortBy(appParseMessages(newMessages), 'sendTime');
			const lastNoticedUserMessage = this.messages
				.slice()
				.reverse()
				.find((message) => message.senderId === user && !!message.firstReceiverId);
			if (lastNoticedUserMessage) lastNoticedUserMessage.isLastUserMessage = true;

			if (this.fbDataPaginationService.msgSlotCount === 1 || this.messageSend) {
				if (this.messageSend) {
					this.messageSend = !this.messageSend;
				}
				this.scrollToBottom();
			} else if (this.fbDataPaginationService.msgSlotCount > 1) {
				let sumOfElemetensHeight = 0;
				for (let i = 0; i < this.fbDataPaginationService.msgSlotArraySize - 1; i++) {
					sumOfElemetensHeight += document.getElementById('messDiv' + i).offsetHeight;
				}
				this.content.scrollToPoint(sumOfElemetensHeight, sumOfElemetensHeight);
			}
			if (isFirstOpen) {
				isFirstOpen = false;
				this.scrollToBottom();
			}
		});
	}

	setOnTop() {
		if (!this.isReachedToBottom) {
			return;
		}
		const content = this.content.getScrollElement();
		const messageWrap = document.getElementById('message_wrap').lastElementChild as HTMLElement;
		content.then((scroll) => {
			const scrollHeight = scroll.scrollHeight - scroll.offsetHeight - messageWrap.offsetHeight;
			if (scroll.scrollTop === 0) {
				this.fbDataPaginationService.paginate('forward');
			}
			this.chatService.onTop = scroll.scrollTop < scrollHeight;
			if (scroll.scrollTop === scroll.scrollHeight - scroll.offsetHeight) {
				this.chatService.newMessagesInOpenChannelCounter = 0;
			}
		});
	}

	getChannelData() {
		this.userProfile = this.chatService.userProfile;
		this.channel = this.chatService.channel;
		let path = 'chat/' + this.userProfile.company + '/channels/' + this.channel.id + '/messages';
		this.fbDataPaginationService.init(path, 'sendTime', {
			limit: 20,
			reverse: true,
			prepend: false,
		});
		this.chatService.type ? (this.type = this.chatService.type) : ChatMessageType;
		this.job = this.chatService.job;
	}

	onFocus() {
		this.showEmojiPicker = false;
		this.scrollToBottom();
	}

	switchEmojiPicker() {
		this.showEmojiPicker = !this.showEmojiPicker;
		if (!this.showEmojiPicker) {
			this.messageInput.setFocus();
		}
		this.scrollToBottom();
	}

	/**
	 * @name sendMsg
	 */
	sendMsg() {
		if (!this.editorMsg.trim()) {
			return;
		}
		this.chatService.sendMessage(this.editorMsg, this.channel.id, this.type, this.job);
		this.editorMsg = '';
		this.type = ChatMessageType;
		this.messageSend = true;
	}

	scrollToBottom() {
		this.content.scrollToBottom(300);
		this.isReachedToBottom = true;
	}

	scrollToBottomOnClick() {
		this.chatService.newMessagesInOpenChannelCounter = 0;
		setTimeout(() => {
			this.content.scrollToBottom(300);
		}, 50);
	}
}
