<ion-card *ngIf="cargos && cargos.length" style="background: linear-gradient(90deg, #3e4345 0, #64686a)">
	<ion-list *ngFor="let cargo of cargos">
		<ion-row>
			<ion-col col-12>
				<p class="jobModalHeaderTextLoading" translate>
					<i class="material-icons jobModalMainIcon">input</i>
					{{ 'JOB_CARGO_LOADING' | translate }}
				</p>
			</ion-col>
		</ion-row>

		<ion-item>
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">toc</i>
				<ion-label translate>CARGO_CONTENT</ion-label>
			</div>
			<div fxLayout="column" fxLayoutAlign="center end" class="width100">
				<ion-note class="color-white ion-text-end width100">{{ cargo.contents }}</ion-note>
				<ion-note class="color-white ion-text-end width100"
					>{{ cargo.amount }} x {{ cargo.loadingEquipmentType?.description }}</ion-note
				>
			</div>
		</ion-item>

		<ion-item>
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">sync_alt</i>
				<span translate>JOB_CARGO_CHANGE</span>
			</div>
			<div fxLayout="column" fxLayoutAlign="center end" class="width100">
				<ion-note class="color-white ion-text-end width100"
					>{{ 'JOB_CARGO_DELIVER' | translate }}: {{ cargo.amountLoadingEquipmentDelivered }}</ion-note
				>
				<ion-note class="color-white ion-text-end width100"
					>{{ 'JOB_CARGO_RECEIVE' | translate }}: {{ cargo.amountLoadingEquipmentTaken }}</ion-note
				>
			</div>
		</ion-item>

		<ion-item>
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">fitness_center</i>
				<span translate>JOB_CARGO_WEIGHT</span>
			</div>

			<div fxLayout="column" fxLayoutAlign="center end" class="width100">
				<ion-note class="color-white ion-text-end width100"
					>{{ 'JOB_CARGO_WEIGHT_GROSS' | translate }}: {{ cargo.grossWeight }} {{ cargo.grossWeightUnit }}</ion-note
				>
				<ion-note class="color-white ion-text-end width100"
					>{{ 'JOB_CARGO_WEIGHT_NET' | translate }}: {{ cargo.netWeight }} {{ cargo.netWeightUnit }}</ion-note
				>
			</div>
		</ion-item>

		<ion-item>
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">attachment</i>
				<span translate>REFERENCES</span>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note class="color-white ion-text-end width100" slot="end">{{ cargo.references }}</ion-note>
			</div>
		</ion-item>

		<ion-item lines="none">
			<div
				slot="start"
				fxLayout="row"
				fxLayoutAlign="start center"
				fxLayoutGap="10px"
				class="job-page-start-item-content-width"
			>
				<i class="material-icons">edit</i>
				<span translate>NOTE</span>
			</div>

			<div fxLayout="row" fxLayoutAlign="end center" class="width100">
				<ion-note class="color-white ion-text-end width100">{{ cargo.customerRemarks }}</ion-note>
			</div>
		</ion-item>
	</ion-list>
</ion-card>
