import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { AppContactPageComponent } from './app-contact-page.component';
import { EmojiPickerModule } from 'src/app/components/emoji-picker/emoji-picker.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ChatService } from 'src/app/services/chat.service';
import { EmojiService } from 'src/app/services/emoji.service';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
	{
		path: 'page-contact',
		component: AppContactPageComponent,
	},
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		EmojiPickerModule,
		PipesModule,
		RouterModule.forChild(routes),
		TranslateModule,
	],
	declarations: [AppContactPageComponent],
	exports: [AppContactPageComponent],
	providers: [ChatService, EmojiService],
})
export class ContactPageModule {}
